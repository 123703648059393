import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class SertifikatCME extends Component {
    constructor(props) {
        super(props);
        this.fileUploads = createRef();
        this.fileUploadsKc = createRef();
        this.state = {
            basic: false,
            idKc: '',
            url: null,
            urlKc: null,
            file: null,
            process: false,
            type: 'cme',
            cmeid:2022070613304299,
            sertif: '',
            sertifkc: '',
            urlnotfound: 'https://ruang-ilmiah.oss-ap-southeast-5.aliyuncs.com/template_cert_dev/download.png',
            showUploadError: false,
            uploadErrorMessage: '',
        };
    }

    componentDidMount() {
        this.getSertifikat();
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    // back = () => {
    //     this.props.history.push(`/cme-online`)
    // }
    goBack(){
        this.props.history.goBack();
    }

    getSertifikat = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let body = {
            "cmeid": routerSplitter[5]
            // "cmeid": 2022070613304299
        }

        // console.log("bodyreq cme => ", body)
        httprequester.postnobody('admin/template_sertificate_view', body, {
            success: async function (response) {
                if (response.data.message == "success") {

                    // console.log("response => ", response.data.detailMessage.url_kc);

                    if (response.data.detailMessage != 0 ) {

                            this.setState({
                                idKc:response.data.detailMessage.view[0].sertifikat_template_content,
                                code:response.data.detailMessage.view[0].sertifikat_template_code,
                                urlKc:response.data.detailMessage.url_kc,
                                url:response.data.detailMessage.url
                            })
                            
                        } else {
                            this.setState({
                                idKc:'',
                                code:'',
                                urlKc:'',
                                url:''
                            })
                        }
                            
                        
                    }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    save = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let formData = new FormData();
        formData.append("myfilefts", this.state.sertif);
        formData.append("myfileftsk", this.state.sertifkc);
        formData.append("idkc", this.state.idKc);
        formData.append("type",this.state.type);
        formData.append("cmeid", routerSplitter[5]);
        // formData.append("cmeid",this.state.cmeid);

        // console.log("isi formData => ", formData);

        this.setState({
            process: true
        })
        httprequester.postupload('admin/template_sertificate', formData, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        process: false
                    })
                    this.fileUploads.current
                    this.fileUploadsKc.current
                } else {
                  this.setState({
                    process: false,
                    showUploadError: true,
                    uploadErrorMessage: response.data.detailMessage,
                  });
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }
    handleUploadGambar = (e) => {
        // console.log("ini saya", e.target.files)
        let file = e.target.files[0];
        // console.log('file', file);
        var reader = new FileReader();
        var url1 = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            this.setState({
                url: [reader.result]
            })
        }.bind(this);

        this.setState({
            url: url1,
            sertif: file
        })
    }

     handleUploadGambarKc = (e) => {
        let file = e.target.files[0];
        // console.log('file', file);
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            this.setState({
                urlKc: [reader.result]
            })
        }.bind(this);

        this.setState({
            urlKc: url,
            sertifkc: file
        })
    }

    replaceImage = (error) => {
        //replacement of broken Image
        error.target.src = this.state.urlnotfound; 
    }


    render() {
        // console.log('isi', this.state.url)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Sertifikat</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Sertifikat CME" breadcrumbItem="Sertifikat" />
                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Sertifikat berhasil di simpan"
                                onConfirm={() => this.goBack()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.process ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        {
                          this.state.showUploadError
                            ? <SweetAlert
                                success={false}
                                title={this.state.uploadErrorMessage}
                                onConfirm={() => {
                                  this.setState({
                                    showUploadError: false,
                                    uploadErrorMessage: '',
                                  });
                                }}
                              />
                            : null
                        }

                        <AvForm>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        ID KC
                                                    </Label>
                                                    <Col sm={5}>
                                                        <Input
                                                            id="idKc"
                                                            type="input"
                                                            placeholder="Input ID KC"
                                                            onChange={(e) => this.setState({ idKc: e.target.value })}
                                                            value={this.state.idKc}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Sertifikat
                                                    </Label>
                                                    <Col sm={9}>
                                                        {
                                                            
                                                            <img src={(this.state.url)} 
                                                            style={{ width: '20%' }} 
                                                            alt="Opps! URL is broken"
                                                            onError={this.replaceImage}/> 
                                                            
                                                        }
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Upload Sertifikat <br /> (Silakan Upload Sertifikat Baru Bila Ada Perubahan)
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="sertif"
                                                            name="file"
                                                            type="file"
                                                            accept=".jpg"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadGambar}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Sertifikat KC
                                                    </Label>
                                                    <Col sm={9}>
                                                        {
                                                            
                                                            <img src={(this.state.urlKc)} 
                                                            style={{ width: '20%' }} 
                                                            alt="Opps! URL is broken"
                                                            onError={this.replaceImage}/> 
                                                           
                                                        }
                                                        
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Upload Sertifikat KC <br />(Silakan Upload Sertifikat KC Bila Baru Ada Perubahan)
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="sertifkc"   
                                                            name="file"
                                                            type="file"
                                                            accept=".jpg"
                                                            ref={this.fileUploadsKc}
                                                            onChange={this.handleUploadGambarKc}
                                                        />
                                                    </Col>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>

                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" color="primary" className="btn btn-primary w-md" 
                                    onClick={() => this.save()}
                                    >
                                        Submit Sertifikat
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default SertifikatCME;

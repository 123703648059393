import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Editor } from '@tinymce/tinymce-react';
import { logDOM } from "@testing-library/react";

class EditTanyaAhli extends Component {
    constructor(props) {
        super(props);
        this.ref = createRef();
        this.state = {
            basic: false,
            listNarasumber: [],
            listType: [
                {
                    nama: 'Artikel',
                    value: 'artikel'
                },
                {
                    nama: 'Webcast',
                    value: 'webcast'
                },
                {
                    nama: 'Tindakan Medis Spesialistik',
                    value: 'tindakan'
                },
                {
                    nama: 'Jurnal',
                    value: 'jurnal'
                },
                {
                    nama: 'Tata Laksana',
                    value: 'tata'
                }
            ],
            tanya_topik: '',
            tanya_content_id:'',
            tanya_narasumber: [],
            tanya_type: [],
            tanya_penyakit: '',
            tanya_spesialisasi:'',
            tanya_summary:'',
            tanya_thumbnail:'',
            tanya_summary:'',
            tanya_summary_edit:'',
            chspepe:false,
            tanya_type_get:'',
            chspepeN:false,
            tanya_narasumber_get:'',
            // hasilSpesialisasi:'',
            // hasilPenyakit:'',
            prosess: false,
            tanya_id:0,
            tanya_status:''
        };
    }

    componentDidMount() {
        let router = window.location.href
        let routerSplitter = router.split("/");
        this.setState({
            tanya_id: routerSplitter[5],
            tanya_status:routerSplitter[7]
        })
        localStorage.setItem('url', router)
        // console.log('dan', routerSplitter);

        this.getNarasumber()
        this.getListTanyaAhli()
    }

    getListTanyaAhli = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let body = {
            "tanya_id": routerSplitter[5]
        }

        // console.log("body =>", body)
        httprequester.postarticle('ahli/get-detail', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                if (response.data.message == "success") {
                    let type = ''
                    if (response.data.detailMessage.datarows.length > 0){
                        let data = this.state.listType.find(j => j.value == response.data.detailMessage.datarows[0][8])
                        type = data.nama
                    }
                    this.setState({
                        tanya_topik: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][15] : '',
                        tanya_content_id: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][16] : '',
                        tanya_thumbnail: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][2] : '',
                        tanya_spesialisasi: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][7] : '',
                        tanya_penyakit: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][14] : '',
                        tanya_summary_edit: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][18] : '',
                        tanya_type_get: type,
                        tanya_narasumber_get: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][12] : ''
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.setState({
            basic:false
        })
        this.getNarasumber()
        this.getListTanyaAhli()
        // this.props.history.push(`/tanya-ahli`)
    }


    clearKonten = () => {
        this.setState({
            basic: false,
            editorState: EditorState.createEmpty(),
            jurnalJudul: '',
            jurnalPenulis: [],
            jurnalKonten: '',
            jurnalGambar: '',
            jurnalSpesialis: [],
            jurnalMolekul: [],
            jurnalPenyakit: []
        });
    }


    getNarasumber = () => {
        httprequester.get('ahli/get-narasumber', {
            success: async function (response) {

                // console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    let dataNew = response.data.detailMessage.filter(a => a.status_narasumber == 'y')
                    this.setState({
                        listNarasumber: dataNew
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveTanyaAhli= () => {
        let type = ''
        if (this.state.tanya_type_get){
            type = this.state.listType.find(k => k.nama == this.state.tanya_type_get)
        } else {
            type = this.state.listType.find(k => k.nama == this.state.tanya_type[0])
           
        }
       
        let slugLower = this.state.tanya_topik.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let router = window.location.href
        let routerSplitter = router.split("/");
      
        

        let body = {
            "tanya_name": this.state.tanya_topik,
            "tanya_narasumber": this.state.tanya_narasumber.length > 0 ? this.state.tanya_narasumber[0] :  this.state.tanya_narasumber_get,
            "tanya_content_id": this.state.tanya_content_id,
            "tanya_slug": slugRep,
            "tanya_summary": this.state.tanya_summary ? this.state.tanya_summary : this.state.tanya_summary_edit,
            "tanya_type": type.value,
            "tanya_status": routerSplitter[7],
            "tanya_penyakit":this.state.tanya_penyakit,
            "tanya_spesialisasi":this.state.tanya_spesialisasi,
            "tanya_thumbnail": this.state.tanya_thumbnail,
            "tanya_id": routerSplitter[5]
        }
        // console.log("body =>", body)
        httprequester.postarticle('ahli/update-new', body, {
            success: async function (response) {
                // console.log("res => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        basic: true
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    save = () => {
        let slugLower = this.state.tanya_topik.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let formData = new FormData();
        formData.append("myfile", this.state.file);
        formData.append("jurnal_thumbnail", this.state.jurnalGambar);
        formData.append("jurnal_title", this.state.tanya_topik);
        // formData.append("jurnal_slug", slugRep);
        formData.append("jurnal_penulis", this.state.jurnalPenulis.toString());
        formData.append("jurnal_penyakit", this.state.jurnalPenyakit.toString());
        formData.append("jurnal_spesialis", this.state.jurnalSpesialis.toString());
        formData.append("jurnal_molekul", this.state.jurnalMolekul.toString());
        formData.append("jurnal_content", this.state.jurnalKonten)
        formData.append("jurnal_type", this.state.type)

        console.log("formData => ", formData);

        this.setState({
            prosess: true
        })
        httprequester.postupload('jurnal/save', formData, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        prosess: false
                    })
                    this.fileUploads.current
                    // e.target.value = null;
                    // this.fileUploads.current.value
                    // this.getFileUpload()
                }
                console.log('ini', this.fileUploads)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    handleChangeType(e) {
        this.setState({
            tanya_type: e
        })
        // console.log("INIT N", e);
    }

    handleChangeNarasumber(e) {
        this.setState({
            tanya_narasumber: e
        })
        // console.log("INIT S", e);
    }

    handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
        this.setState({
            tanya_summary: e.target.getContent()
        })
    }

    directSEO = (id, type, status) => {
        this.props.history.push(`/seoconfig/${id}/${type}`)
    }

    directback = (id) => {
        this.props.history.push(`/${id}`)
    }

    directDetail = () => {
        this.props.history.push(`/tanya-ahli/tambah-tanya-periode/${this.state.tanya_id}/artikel/${this.state.tanya_status}`)
    }


    directDetailListPercakapan = () => {
        this.props.history.push(`/tanya-ahli/list-percakapan/${this.state.tanya_id}/artikel/${this.state.tanya_status}`)
    }

    

    handleChangeContentID = (e) => {
        this.setState({
            tanya_content_id:e.target.value
        }) 
       
        if (this.state.tanya_type[0]  == 'Artikel') {
            let body = {
                "article_id": e.target.value
            }
            httprequester.postnobody('artikel/detail', body, {
                success: async function (response) {

                    // console.log("resLOV => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            tanya_spesialisasi: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][8] : '',
                            tanya_penyakit: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][11] : ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
        }  else if (this.state.tanya_type[0]  == 'Jurnal') {
            let body = {
                "jurnal_id": e.target.value
            }
            httprequester.postnobody('jurnal/detail', body, {
                success: async function (response) {

                    // console.log("resLOV => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            tanya_spesialisasi: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][10] : '',
                            tanya_penyakit: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][6] : ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
        } else if (this.state.tanya_type[0] == 'Webcast') {
            // console.log('wecast');
            let body = {
                "webinar_id": e.target.value
            }
            httprequester.postnobody('webinar/detail', body, {
                success: async function (response) {

                    // console.log("resLOV => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            tanya_spesialisasi: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][2] : '',
                            tanya_penyakit: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][8] : ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
        } else if (this.state.tanya_type[0]  == 'Tindakan Medis Spesialistik') {
            let body = {
                "tindakan_id": e.target.value
            }
            httprequester.postnobody('tindakan/detail', body, {
                success: async function (response) {

                    // console.log("resLOV => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            tanya_spesialisasi: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][2] : '',
                            tanya_penyakit: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][9] : ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
        } else if (this.state.tanya_type[0]  == 'Tata Laksana') {
            let body = {
                "jurnal_id": e.target.value
            }
            httprequester.postnobody('jurnal/detail', body, {
                success: async function (response) {

                    // console.log("resLOV => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            tanya_spesialisasi: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][10] : '',
                            tanya_penyakit: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][6] : ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
        }   
    
        
    }


    

    render() {
        const optionNarasumber = this.state.listNarasumber.map(o => o.narasumber_name)
        const optionType = this.state.listType.map(o => o.nama)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Edit & Detail Tanya Ahli</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Tanya Ahli" breadcrumbItem="Edit & Detail Tanya Ahli" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Tanya Ahli berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <div className="mb-3">
                                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO(this.state.tanya_id, "tanya_ahli") }}> <i className="fas fa-globe"></i> SEO</button>
                                                &nbsp;
                                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.directDetail() }}> <i className="fas fa-calendar" ></i> Periode</button>
                                                &nbsp;
                                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.directDetailListPercakapan() }}> <i className="fas fa-comments"></i> Percakapan</button>
                                            </div>

                                            <div className="mb-3">
                                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.directback("tanya-ahli") }}> Kembali</button>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Nama Topik</Label>
                                                <Input
                                                    id="tanyatopik"
                                                    type="input"
                                                    placeholder="Input Nama Topik"
                                                    onChange={(e) => this.setState({ tanya_topik: e.target.value })}
                                                    value={this.state.tanya_topik}
                                                />
                                            </div>

                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Label htmlFor="formrow-firstname-Input">Type</Label>
                                                            <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspepe: !this.state.chspepe })} >ubah</a>
                                                        </div>
                                                        {(!this.state.chspepe) ? 
                                                        <Input
                                                            name="judul"
                                                            disabled
                                                            type="text"
                                                            value={this.state.tanya_type_get} /> :
                                                            <Typeahead
                                                                // allowNew
                                                                id="basic-typeahead-multiple"
                                                                placeholder="Pilih Type"
                                                                labelKey="name"
                                                                options={optionType}
                                                                onChange={(e) => this.handleChangeType(e)}
                                                            />}
                                                       
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">Content ID</Label>
                                                        <Input
                                                            id="tanyaahlicintentid"
                                                            type="input"
                                                            placeholder="Input Content ID"
                                                            onChange={this.handleChangeContentID}
                                                            value={this.state.tanya_content_id}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Label htmlFor="formrow-firstname-Input">Narasumber</Label>
                                                            <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspepeN: !this.state.chspepeN })} >ubah</a>
                                                        </div>
                                                        {(!this.state.chspepeN) ? <Input
                                                            name="judul"
                                                            disabled
                                                            type="text"
                                                            value={this.state.tanya_narasumber_get} /> : 
                                                        <Typeahead
                                                            // allowNew
                                                            id="basic-typeahead-multiple"
                                                            placeholder="Pilih Narasumber"
                                                            labelKey="name"
                                                            options={optionNarasumber}
                                                            onChange={(e) => this.handleChangeNarasumber(e)}
                                                        /> }
                                                       
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">Thumbnail</Label>
                                                        <Input
                                                            id="tanyaahlithumbnail"
                                                            type="input"
                                                            placeholder="Input THumbnail"
                                                            onChange={(e) => this.setState({ tanya_thumbnail: e.target.value })}
                                                            value={this.state.tanya_thumbnail}
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">Spesialisasi</Label>
                                                        <Input
                                                            id="tanyaahlicintentid"
                                                            type="input"
                                                            placeholder="Input Spesialisasi"
                                                            disabled={true}
                                                            value={this.state.tanya_spesialisasi}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">Penyakit</Label>
                                                        <Input
                                                            id="tanyaahlicintentid"
                                                            type="input"
                                                            placeholder="Input Penyakit"
                                                            disabled={true}
                                                            value={this.state.tanya_penyakit}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" md="12">
                                <Card>
                                    <CardBody>
                                        <Label htmlFor="formrow-InputZip">Summary</Label>
                                        <Form method="post">
                                            <Editor
                                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                initialValue={this.state.tanya_summary_edit}
                                                content={this.state.tanya_summary}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent lineheight | ' +
                                                        'removeformat link',
                                                    content_style: 'body { font-family:Poppins,Arial,sans-serif; }',
                                                    line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 2',
                                                }}
                                                onChange={this.handleEditorChange}
                                            />
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" className="btn btn-primary w-md" onClick={() => this.saveTanyaAhli()}>
                                        Simpan Tanya Ahli
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default EditTanyaAhli;

import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button, Alert } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import moment from 'moment'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

//urlAPI
import UrlApi from "../../helpers/UrlAPI"

import httprequester from '../../lib/httprequester'

class verifikasistruser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      listSTRUser: [],
      alert: false,
      message: '',
      id: null
    }

  }

  getSTRUser = () => {
    let body = {
      "str_status": "W"
    }

    httprequester.postnobody('admin/list_moderating_str', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          let data = []
          response.data.detailMessage.forEach(async (v, key) => {
            await data.push({
              str_id: v.str_id,
              str_no: v.str_no,
              str_expired: moment(v.str_expired).format('DD MMM YYYY'),
              created_at: v.created_at,
              updated_at: v.updated_at,
              str_status: v.str_status,
              user_id: v.user_id,
              user_name: v.user_name
            })
          })
          this.setState({
            listSTRUser: data
          })
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  handlecancel = () => {
    this.setState({
      alert: false
    })
  }

  approve = (id) => {
    // console.log('id',id)
    let pencarian = this.state.listSTRUser.find(i => i.str_id == id)
    // console.log('pencarian',pencarian)
    let body = {
      "user_id": pencarian.user_id,
      "user_name": pencarian.user_name,
      "str_id": id,
      "action": "Approve"
    }

    httprequester.postnobody('admin/moderating_str', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true
          })
          this.getSTRUser()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  reject = (id) => {
    // console.log('id', this.state.id)

    let pencarian = this.state.listSTRUser.find(i => i.str_id == id)
    // console.log('pencarian',pencarian)
    let body = {
      "user_id": pencarian.user_id,
      "user_name": pencarian.user_name,
      "str_id": id,
      "action": "Reject"
    }

    httprequester.postnobody('admin/moderating_str', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true,
          })
          this.getSTRUser()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  componentDidMount() {
    this.getSTRUser()
  }

  render() {

    const columns = [{
      dataField: 'str_no',
      text: 'No',
      sort: true,
    }, {
      dataField: 'user_name',
      text: 'Username',
      sort: true
    }, {
      dataField: 'str_expired',
      text: 'Expired',
      sort: true
    }, {
      dataField: 'str_id',
      text: 'Action',
      sort: true,
      formatter: (row) => {
        // console.log('bener', row)
        return <div>
          <Button
            color="danger"
            className="btn-sm"
            onClick={(e) => { this.approve(row) }}
          >
            Approve
          </Button>
          &nbsp;
          <Button color="primary" className="btn-sm" onClick={(e) => this.reject(row)}>
            Reject
          </Button>
        </div>
      }
    }];

    const defaultSorted = [{
      dataField: 'user_name',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.listSTRUser.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.listSTRUser).length }];




    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Registrasi | Ruang Ilmiah</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="User" breadcrumbItem="Verifikasi Registrasi" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='user_name'
                      columns={columns}
                      data={this.state.listSTRUser}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='user_name'
                          columns={columns}
                          data={this.state.listSTRUser}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"user_name"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {this.state.alert ?
            <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
            </SweetAlert> : ''
          }
        </div>
      </React.Fragment>
    )
  }
}

export default verifikasistruser
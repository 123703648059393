import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Editor } from '@tinymce/tinymce-react';

class EditJurnalTataLaksana extends Component {
    constructor(props) {
        super(props);
        this.fileUploads = createRef();
        this.state = {
            basic: false,
            listCategory: [],
            listSpesialis: [],
            listMolekul: [],
            listPenyakit: [],
            listPenulis: [],
            jurnalJudul: '',
            jurnalPenulis: '',
            jurnalPenyakit: '',
            jurnalMolekul: '',
            jurnalSpesialis: '',
            jurnalKonten: '',
            jurnalGambar: '',
            file: null,
            penulis: '',
            chspepe: false,
            spesialisasi: '',
            chspe: false,
            molekul: '',
            chspem: false,
            penyakit: '',
            chspep: false,
            upload: '',
            chspeu: false,
            deskripsiedit: '',
            chsped: false,
            type: '',
            prosess: false
        };
    }

    componentDidMount() {
        this.getCategory();
        this.getLOV();
        this.getJurnal()
    }
    getJurnal = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let body = {
            "jurnal_id": routerSplitter[5]
        }

        // console.log("body =>", body)
        httprequester.postarticle('jurnal/detail', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)

                    // if(response.data.result.data.length)
                    response.data.settings.datarows.forEach(async (v, key) => {
                        await data.push({
                            jurnal_pdf: v[0],
                            jurnal_thumbnail: v[1],
                            created_at: v[2],
                            jurnal_id: v[3],
                            created_by: v[4],
                            jurnal_slug: v[5],
                            jurnal_penyakit: v[6],
                            jurnal_tags: v[7],
                            jurnal_type: v[8],
                            myfile: v[9],
                            jurnal_spesialis: v[10],
                            jurnal_molekul: v[11],
                            jurnal_title: v[12],
                            jurnal_category: v[13],
                            jurnal_content: v[14],
                            jurnal_status: v[15],
                            jurnal_penulis: v[16]
                        })
                    })

                    let edit = data.find(o => o.jurnal_id == routerSplitter[5])

                    this.setState({
                        jurnalJudul: edit.jurnal_title,
                        penulis: edit.jurnal_penulis,
                        jurnalGambar: edit.jurnal_thumbnail,
                        spesialisasi: edit.jurnal_spesialis,
                        molekul: edit.jurnal_molekul,
                        penyakit: edit.jurnal_penyakit,
                        upload: edit.jurnal_pdf,
                        deskripsiedit: edit.jurnal_content,
                        type: edit.jurnal_type
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.props.history.push(`/jurnaltatalaksana`)
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState: editorState,
            articleKonten: editorState.getCurrentContent().getPlainText()
        });
    };

    clearKonten = () => {
        this.setState({
            basic: false,
            editorState: EditorState.createEmpty(),
            jurnalJudul: '',
            jurnalPenulis: [],
            jurnalKonten: '',
            jurnalGambar: '',
            jurnalSpesialis: [],
            jurnalMolekul: [],
            jurnalPenyakit: []
        });
    }

    getCategory = () => {
        httprequester.get('category/getall', {
            success: async function (response) {

                console.log("resCat => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listCategory: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getLOV = () => {
        httprequester.get('artikel/lov', {
            success: async function (response) {

                console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    let datapenyakit = []
                    response.data.detailPenyakit.forEach((v, key) => {
                        // console.log('v',v);
                        datapenyakit.push({
                            penyakit_id: v.penyakit_id,
                            penyakit_name: v.penyakit_name == null ? '-' : v.penyakit_name
                        })
                    })

                    this.setState({
                        listSpesialis: response.data.detailSpecialist,
                        listMolekul: response.data.detailMolekul,
                        listPenyakit: datapenyakit,
                        listPenulis: response.data.detailPenulis
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveArticle = () => {
        let slugLower = this.state.articleJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let body = {
            "article_title": this.state.articleJudul,
            "article_slug": slugRep,
            "article_thumbnail": this.state.articleGambar,
            "article_penulis": this.state.articlePenulis,
            "article_penyakit": this.state.articlePenyakit,
            "article_molekul": this.state.articleMolekul,
            "article_spesialis": this.state.articleSpesialis,
            "article_status": "Unpublished",
            "article_content": this.state.articleKonten
        }
        console.log("body =>", body)
        httprequester.postarticle('artikel/save', body, {
            success: async function (response) {
                console.log("res => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        basic: true
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    save = () => {
        let slugLower = this.state.jurnalJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let router = window.location.href
        let routerSplitter = router.split("/");

        if (this.state.file == null) {
            let formData = new FormData();
            formData.append("jurnal_thumbnail", this.state.jurnalGambar);
            formData.append("jurnal_id", routerSplitter[5]);
            formData.append("jurnal_title", this.state.jurnalJudul);
            // formData.append("jurnal_slug", slugRep);
            formData.append("jurnal_penulis", this.state.jurnalPenulis ? this.state.jurnalPenulis.toString() : this.state.penulis.toString());
            formData.append("jurnal_penyakit", this.state.jurnalPenyakit ? this.state.jurnalPenyakit.toString() : this.state.penyakit.toString());
            formData.append("jurnal_spesialis", this.state.jurnalSpesialis ? this.state.jurnalSpesialis.toString() : this.state.spesialisasi.toString());
            formData.append("jurnal_molekul", this.state.jurnalMolekul ? this.state.jurnalMolekul.toString() : this.state.molekul.toString());
            formData.append("jurnal_content", this.state.jurnalKonten ? this.state.jurnalKonten : this.state.deskripsiedit);
            formData.append("jurnal_status", "Unpublished");
            formData.append("jurnal_type", this.state.type);


            console.log("formData => ", formData);
            this.setState({
                prosess: true
            })
            httprequester.postupload('jurnal/edit', formData, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        httprequester.postarticle('homepage/lov_destruct', {content_id: routerSplitter[5]}, {
                            success: async function (responses) {
                                console.log(responses)
                            }.bind(this),
                            error: function (err) {
                                console.log("error", err)
                            }.bind(this)
                        })
                        this.setState({
                            basic: true,
                            prosess: false
                        })
                        this.fileUploads.current
                        // e.target.value = null;
                        // this.fileUploads.current.value
                        // this.getFileUpload()
                    }
                    console.log('ini', this.fileUploads)
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        } else {
            let formData = new FormData();
            formData.append("myfile", this.state.file);
            formData.append("jurnal_thumbnail", this.state.jurnalGambar);
            formData.append("jurnal_id", routerSplitter[5]);
            formData.append("jurnal_title", this.state.jurnalJudul);
            // formData.append("jurnal_slug", slugRep);
            formData.append("jurnal_penulis", this.state.jurnalPenulis ? this.state.jurnalPenulis.toString() : this.state.penulis.toString());
            formData.append("jurnal_penyakit", this.state.jurnalPenyakit ? this.state.jurnalPenyakit.toString() : this.state.penyakit.toString());
            formData.append("jurnal_spesialis", this.state.jurnalSpesialis ? this.state.jurnalSpesialis.toString() : this.state.spesialisasi.toString());
            formData.append("jurnal_molekul", this.state.jurnalMolekul ? this.state.jurnalMolekul.toString() : this.state.molekul.toString());
            formData.append("jurnal_content", this.state.jurnalKonten ? this.state.jurnalKonten : this.state.deskripsiedit);
            formData.append("jurnal_status", "Unpublished");
            formData.append("jurnal_type", this.state.type);

            console.log("formData => ", formData);
            this.setState({
                prosess: true
            })
            httprequester.postupload('jurnal/edit', formData, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        httprequester.postarticle('homepage/lov_destruct', {content_id: routerSplitter[5]}, {
                            success: async function (responses) {
                                console.log(responses)
                            }.bind(this),
                            error: function (err) {
                                console.log("error", err)
                            }.bind(this)
                        })
                        this.setState({
                            basic: true,
                            prosess: false
                        })
                        this.fileUploads.current
                        // e.target.value = null;
                        // this.fileUploads.current.value
                        // this.getFileUpload()
                    }
                    // console.log('ini', this.fileUploads)
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        }
    }

    handleChangePenulis(e) {
        this.setState({
            jurnalPenulis: e
        })
        console.log("INIT N", e);
    }

    handleChangeSpesialis(e) {
        this.setState({
            jurnalSpesialis: e
        })
        console.log("INIT S", e);
    }

    handleChangeMolekul(e) {
        this.setState({
            jurnalMolekul: e
        })
        console.log("INIT M", e);
    }

    handleChangePenyakit(e) {
        this.setState({
            jurnalPenyakit: e
        })
        console.log("INIT P", e);
    }

    handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
        this.setState({
            jurnalKonten: e.target.getContent()
        })
    }

    handlupload = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    render() {
        const option = this.state.listSpesialis.map(o => o.specialist_name)
        const optionMolekul = this.state.listMolekul.map(o => o.molekul_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.penyakit_name)
        const optionPenulis = this.state.listPenulis.map(o => o.penulis_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Edit Jurnal & Tata Laksana</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Jurnal & Tata Laksana" breadcrumbItem="Edit Jurnal & Tata Laksana" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Jurnal & Tata Laksana berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <AvForm>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">Judul Jurnal & Tata Laksana</Label>
                                                    <Input
                                                        id="artikelJudul"
                                                        type="input"
                                                        placeholder="Input Judul Jurnal & Tata Laksana"
                                                        onChange={(e) => this.setState({ jurnalJudul: e.target.value })}
                                                        value={this.state.jurnalJudul}
                                                    />
                                                </div>

                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Label for="exampleSpesialisasi">
                                                                    Penulis
                                                                </Label>
                                                                <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspepe: !this.state.chspepe })} >ubah</a>
                                                            </div>
                                                            {/* <Label htmlFor="formrow-firstname-Input">Penulis</Label> */}
                                                            {(!this.state.chspepe) ?
                                                                <AvField
                                                                    name="judul"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.penulis} required />
                                                                : <Typeahead
                                                                    // allowNew
                                                                    id="basic-typeahead-multiple"
                                                                    multiple
                                                                    placeholder="Pilih Penulis"
                                                                    labelKey="name"
                                                                    options={optionPenulis}
                                                                    onChange={(e) => this.handleChangePenulis(e)}
                                                                    selected={this.state.jurnalPenulis}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="formrow-email-Input">URL Gambar Thumbnail</Label>
                                                            <Input
                                                                id="artikelThumbnail"
                                                                type="input"
                                                                placeholder="Input Link Thumbnail"
                                                                onChange={(e) => this.setState({ jurnalGambar: e.target.value })}
                                                                value={this.state.jurnalGambar}
                                                            />
                                                        </div>
                                                    </Col>
                                                    {/* <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">File Upload</Label>
                                                        <Input
                                                            id="exampleFile"
                                                            name="file"
                                                            type="file"
                                                            accept=".png,.jpg,.jpeg"
                                                            ref={this.fileUploads}
                                                            onChange={this.handlupload}
                                                        />
                                                    </div>
                                                </Col> */}
                                                </Row>

                                                <Row>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Label for="exampleSpesialisasi">
                                                                    Spesialisasi
                                                                </Label>
                                                                <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspe: !this.state.chspe })} >ubah</a>
                                                            </div>
                                                            {/* <Label htmlFor="formrow-InputCity">Spesialis</Label> */}
                                                            {(!this.state.chspe) ?
                                                                <AvField
                                                                    name="judul"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.spesialisasi} required />
                                                                : <Typeahead
                                                                    // allowNew
                                                                    id="basic-typeahead-multiple"
                                                                    multiple
                                                                    placeholder="Pilih Spesialis"
                                                                    labelKey="name"
                                                                    options={option}
                                                                    onChange={(e) => this.handleChangeSpesialis(e)}
                                                                    selected={this.state.jurnalSpesialis}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Label for="exampleSpesialisasi">
                                                                    Molekul
                                                                </Label>
                                                                <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspem: !this.state.chspem })} >ubah</a>
                                                            </div>
                                                            {/* <Label htmlFor="formrow-InputState">Molekul</Label> */}
                                                            {(!this.state.chspem) ?
                                                                <AvField
                                                                    name="judul"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.molekul} required />
                                                                : <Typeahead
                                                                    // allowNew

                                                                    id="basic-typeahead-multiple"
                                                                    multiple
                                                                    placeholder="Pilih Molekul"
                                                                    labelKey="name"
                                                                    options={optionMolekul}
                                                                    onChange={(e) => this.handleChangeMolekul(e)}
                                                                    selected={this.state.jurnalMolekul}
                                                                />
                                                            }

                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Label for="exampleSpesialisasi">
                                                                    Penyakit
                                                                </Label>
                                                                <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspep: !this.state.chspep })} >ubah</a>
                                                            </div>
                                                            {/* <Label htmlFor="formrow-InputZip">Penyakit</Label> */}
                                                            {(!this.state.chspep) ?
                                                                <AvField
                                                                    name="judul"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.penyakit} required />
                                                                : <Typeahead
                                                                    // allowNew
                                                                    id="basic-typeahead-multiple"
                                                                    multiple
                                                                    placeholder="Pilih Penyakit"
                                                                    labelKey="name"
                                                                    options={optionPenyakit}
                                                                    onChange={(e) => this.handleChangePenyakit(e)}
                                                                    selected={this.state.jurnalPenyakit}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Label for="exampleSpesialisasi">
                                                                    File Upload
                                                                </Label>
                                                                <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspeu: !this.state.chspeu })} >ubah</a>
                                                            </div>
                                                            {/* <Label htmlFor="formrow-InputZip">Penyakit</Label> */}
                                                            {(!this.state.chspeu) ?
                                                                <AvField
                                                                    name="judul"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.upload} required />
                                                                : <Input
                                                                    id="exampleFile"
                                                                    name="file"
                                                                    type="file"
                                                                    accept=".png,.jpg,.jpeg,.pdf"
                                                                    ref={this.fileUploads}
                                                                    onChange={this.handlupload}
                                                                />
                                                            }

                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label for="exampleSelect">
                                                                Type
                                                            </Label>
                                                            <Input
                                                                id="exampleSelect"
                                                                name="select"
                                                                type="select"
                                                                onChange={(e) => this.setState({ type: e.target.value })}
                                                                value={this.state.type}
                                                            >
                                                                <option>
                                                                    Pilih Type
                                                                </option>
                                                                <option value="jurnal">
                                                                    Jurnal
                                                                </option>
                                                                <option value="tata laksana">
                                                                    Tata Laksana
                                                                </option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>


                        <AvForm>
                            <Row style={{ paddingTop: 10 }}>
                                <Col xs="12" md="12">
                                    <Card>
                                        <CardBody>
                                            <Form method="post">
                                                <Editor
                                                    apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                    initialValue={this.state.deskripsiedit}
                                                    content={this.state.jurnalKonten}
                                                    init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar: 'undo redo | formatselect | ' +
                                                            'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent lineheight | ' +
                                                            'removeformat link',
                                                        content_style: 'body { font-family:Poppins,Arial,sans-serif; }',
                                                        line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 2',
                                                    }}
                                                    onChange={this.handleEditorChange}
                                                />
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>

                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" className="btn btn-primary w-md" onClick={() => this.save()}>
                                        Submit Jurnal & Tata Laksana
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default EditJurnalTataLaksana;

import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import moment from "moment";
import { GetIndexFromSchema } from '../../helpers/CommonHelper';

class TambahCME extends Component {
    constructor(props) {
        super(props);
        this.fileUploads = createRef();
        this.state = {
            basic: false,
            listSpesialis: [],
            cmeJudul: '',
            cmeSKP: '',
            cmeDeskripsi: '',
            cmeSKPBIDI: '',
            cmeSpesialis: [],
            cmeSpesialis1:[],
            cmeSpesialis2:[],
            cmeOrganisasi:[],
            cmeFilePDF: null,
            cmeFileGambar: null,
            cmeFileVideo: null,
            file: null,
            prosess: false,
            cmelink:'',
            cmescore:'',
            listOrganisasi:[],
            tanggal:'',
            tanggalAkhir:'',
            cmeProductName1: '',
            cmeProductUrl1: '',
            cmeProductName2: '',
            cmeProductUrl2: '',
        };
    }

    componentDidMount() {
        this.getLOV();
        this.getListCME()
        this.getOrganisasi()
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.props.history.push(`/cme-online`)
    }

    getOrganisasi = () => {
        let body = {
            "admin_type": "organisasi"
        }

        httprequester.postnobody('organisasi/getAll', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        listOrganisasi: response.data.detailMessage
                    })
                }
                // console.log(response)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    backduplicate = () => {
        this.setState({
            basicduplicate:false
        })
    }

    clearKonten = () => {
        this.setState({
            basic: false,
            basicduplicate:false,
            cmeJudul: '',
            cmeSKP: '',
            cmeDeskripsi: '',
            cmeSKPBIDI: '',
            cmeSpesialis: [],
            cmeOrganisasi:[],
            statusCME: 'Published',
            listCME:[]
        });
    }

    getListCME = () => {

        let body = {
            "spesialisasi": "",
            "search": "",
            "status": 'Published',
            "page": 1,
            "limit": 1000,
            "urutan": "created_at DESC"
        }

        // console.log("bodyreq cme => ", body)
        httprequester.postnobody('cme/get_header_adm', body, {
            success: async function (response) {
                if (response.data.message == "success") {

                    // console.log("response cme => ", response);

                    let data = []
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            cme_id: v[GetIndexFromSchema('cme_id', response.data.result.data.schema)],
                            cme_penulis: v[GetIndexFromSchema('cme_penulis', response.data.result.data.schema)],
                            cme_title: v[GetIndexFromSchema('cme_title', response.data.result.data.schema)],
                            cme_content: v[GetIndexFromSchema('cme_content', response.data.result.data.schema)],
                            created_at: v[GetIndexFromSchema('created_at', response.data.result.data.schema)],
                            cme_slug: v[GetIndexFromSchema('cme_slug', response.data.result.data.schema)],
                            cme_penyakit: v[GetIndexFromSchema('cme_penyakit', response.data.result.data.schema)],
                            cme_skp: v[GetIndexFromSchema('cme_skp', response.data.result.data.schema)],
                            cme_molekul: v[GetIndexFromSchema('cme_molekul', response.data.result.data.schema)],
                            cme_spesialis: v[GetIndexFromSchema('cme_spesialis', response.data.result.data.schema)],
                            cme_status: v[GetIndexFromSchema('cme_status', response.data.result.data.schema)],
                            cme_pdf: v[GetIndexFromSchema('cme_pdf', response.data.result.data.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.result.data.schema)],
                            cme_thumbnail: v[GetIndexFromSchema('cme_thumbnail', response.data.result.data.schema)],
                            cme_no_idi: v[GetIndexFromSchema('cme_no_idi', response.data.result.data.schema)],
                        });
                    })

                    // console.log("dataresp cme => ", data);
                    this.setState({
                        listCME: data
                    })
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    getLOV = () => {
        httprequester.get('artikel/lov', {
            success: async function (response) {

                // console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listSpesialis: response.data.detailSpecialist,
                        listMolekul: response.data.detailMolekul,
                        listPenyakit: response.data.detailPenyakit,
                        listPenulis: response.data.detailPenulis
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    save = () => {
       
        let data = this.state.listCME.find(a => a.cme_no_idi == this.state.cmeSKPBIDI)
        if(data){
            this.setState({
                basicduplicate:true
            })
        } else {
            let formData = new FormData();
            let slugLower = this.state.cmeJudul.toLowerCase();
            let slugRep = slugLower.replace(/\s/g, '-');

            formData.append("myfile", this.state.cmeFilePDF);
            formData.append("myfile2", this.state.cmeFileGambar);
            formData.append("myfile3", this.state.cmeFileVideo);
            formData.append("cme_title", this.state.cmeJudul);
            formData.append("cme_title_search", this.state.cmeJudul);
            formData.append("cme_spesialis", this.state.cmeSpesialis);
            formData.append("cme_spesialis_search", this.state.cmeSpesialis);
            formData.append("cme_no_idi", this.state.cmeSKPBIDI);
            formData.append("cme_content", this.state.cmeDeskripsi);
            formData.append("cme_skp", this.state.cmeSKP);
            formData.append("cme_youtube", this.state.cmelink);
            formData.append("cme_slug", slugRep);
            formData.append("cme_score", this.state.cmescore);
            formData.append("cme_penulis", this.state.cmeOrganisasi);

            // Spesialisasi, Tanggal Mulai & Tanggal Akhir
            formData.append("cme_molekul", this.state.cmeSpesialis1);
            formData.append("cme_penyakit", this.state.cmeSpesialis2);
            formData.append("periode_awal", moment(this.state.tanggal).format('YYYY-MM-DD'));
            formData.append("periode_akhir", moment(this.state.tanggalAkhir).format('YYYY-MM-DD'));

            formData.append("cme_product_name_1", this.state.cmeProductName1);
            formData.append("cme_product_url_1", this.state.cmeProductUrl1);
            formData.append("cme_product_name_2", this.state.cmeProductName2);
            formData.append("cme_product_url_2", this.state.cmeProductUrl2);

            this.setState({
                prosess: true
            })
            httprequester.postupload('cme/save_header', formData, {
                success: async function (response) {
                    // console.log("ini", response.data)
                    if (response.data.message == "success") {
                        this.setState({
                            basic: true,
                            prosess: false
                        })
                        this.fileUploads.current
                    }
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        }

      
        

   
    }

    handleChangeSpesialis(e) {
        // console.log('e', e);
        this.setState({
            cmeSpesialis: e
        })
        // console.log("INIT S", e);
    }

    handleChangeSpesialis1(e) {
        this.setState({
            cmeSpesialis1: e
        })
        // console.log("INIT S", e);
    }

    handleChangeSpesialis2(e) {
        this.setState({
            cmeSpesialis2: e
        })
        // console.log("INIT S", e);
    }

    handleOrganisasi(e) {
        this.setState({
            cmeOrganisasi: e
        })
        // console.log("INIT S", e);
    }

    handleUploadPDF = (e) => {
        this.setState({
            cmeFilePDF: e.target.files[0]
        })
    }

    handleUploadGambar = (e) => {
        this.setState({
            cmeFileGambar: e.target.files[0]
        })
    }

    handleUploadVideo = (e) => {
        this.setState({
            cmeFileVideo: e.target.files[0]
        })
    }

    render() {
        const option = this.state.listSpesialis.map(o => o.specialist_name)
        const optionOrganisasi = this.state.listOrganisasi.map(k => k.org_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah CME Online</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="CME Online" breadcrumbItem="Tambah CME Online" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="CME Online berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.basicduplicate ? (
                            <SweetAlert
                                danger
                                title="No SK PD IDI Sudah Terdaftar"
                                onConfirm={() => this.backduplicate()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Judul CME
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeJudul"
                                                        type="input"
                                                        placeholder="Input Judul CME"
                                                        onChange={(e) => this.setState({ cmeJudul: e.target.value })}
                                                        value={this.state.cmeJudul}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Minimum Score Kelulusan
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmescore"
                                                            type="input"
                                                            placeholder="Input CME Score"
                                                            onChange={(e) => this.setState({ cmescore: e.target.value })}
                                                            value={this.state.cmescore}
                                                        />
                                                    </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-email-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    SKP
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeSKP"
                                                        type="input"
                                                        placeholder="Input SKP"
                                                        onChange={(e) => this.setState({ cmeSKP: e.target.value })}
                                                        value={this.state.cmeSKP}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-password-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Deskripsi
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeDeskripsi"
                                                        type="textarea"
                                                        rows="3"
                                                        placeholder="Input Deskripsi"
                                                        onChange={(e) => this.setState({ cmeDeskripsi: e.target.value })}
                                                        value={this.state.cmeDeskripsi}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    No. SK PB IDI
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeSKPBIDI"
                                                        type="input"
                                                        placeholder="Input No. SK PB IDI"
                                                        onChange={(e) => this.setState({ cmeSKPBIDI: e.target.value })}
                                                        value={this.state.cmeSKPBIDI}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Organisasi
                                                </Label>
                                                <Col sm={9}>
                                                    <Typeahead
                                                        // allowNew
                                                        id="cmeOrganisasi"
                                                        placeholder="Pilih Organisasi"
                                                        labelKey="name"
                                                        options={optionOrganisasi}
                                                        onChange={(e) => this.handleOrganisasi(e)}
                                                        selected={this.state.cmeOrganisasi}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Spesialis
                                                </Label>
                                                <Col sm={9}>
                                                    <Typeahead
                                                        // allowNew
                                                        id="cmeSpesialis"
                                                        placeholder="Pilih Spesialis"
                                                        labelKey="name"
                                                        options={option}
                                                        onChange={(e) => this.handleChangeSpesialis(e)}
                                                        selected={this.state.cmeSpesialis}
                                                    />
                                                </Col>
                                            </div>

                                            {/* Spesialisasi, Tanggal Mulai & Tanggal Akhir  */}
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Spesialis Alternatif 1
                                                </Label>
                                                <Col sm={9}>
                                                    <Typeahead
                                                        // allowNew
                                                        id="cmeSpesialis"
                                                        placeholder="Pilih Spesialis"
                                                        labelKey="name"
                                                        options={option}
                                                        onChange={(e) => this.handleChangeSpesialis1(e)}
                                                        selected={this.state.cmeSpesialis1}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Spesialis Alternatif 2
                                                </Label>
                                                <Col sm={9}>
                                                    <Typeahead
                                                        // allowNew
                                                        id="cmeSpesialis"
                                                        placeholder="Pilih Spesialis"
                                                        labelKey="name"
                                                        options={option}
                                                        onChange={(e) => this.handleChangeSpesialis2(e)}
                                                        selected={this.state.cmeSpesialis2}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Tanggal Mulai
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        name="tanggal"
                                                        label="Tanggal Mulai"
                                                        type="date"
                                                        
                                                        onChange={(e) => this.setState({ tanggal: e.target.value })}
                                                    // value={this.state.tanggal}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Tanggal Akhir
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        name="tanggal"
                                                        label="Tanggal Mulai"
                                                        type="date"
                                                        min={moment(this.state.tanggal).format('YYYY-MM-DD')}
                                                        onChange={(e) => this.setState({ tanggalAkhir: e.target.value })}
                                                    // value={this.state.tanggal}
                                                    />
                                                </Col>
                                            </div>

                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Nama Produk Rekomendasi 1
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeProductName1"
                                                        type="input"
                                                        onChange={(e) => this.setState({ cmeProductName1: e.target.value })}
                                                        value={this.state.cmeProductName1}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    URL Produk Rekomendasi 1
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeProductUrl1"
                                                        type="input"
                                                        onChange={(e) => this.setState({ cmeProductUrl1: e.target.value })}
                                                        value={this.state.cmeProductUrl1}
                                                    />
                                                </Col>
                                            </div>

                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Nama Produk Rekomendasi 2
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeProductName2"
                                                        type="input"
                                                        onChange={(e) => this.setState({ cmeProductName2: e.target.value })}
                                                        value={this.state.cmeProductName2}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    URL Produk Rekomendasi 2
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeProductUrl2"
                                                        type="input"
                                                        onChange={(e) => this.setState({ cmeProductUrl2: e.target.value })}
                                                        value={this.state.cmeProductUrl2}
                                                    />
                                                </Col>
                                            </div>

                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Upload PDF
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeFilePDF"
                                                        name="file"
                                                        type="file"
                                                        accept=".pdf"
                                                        ref={this.fileUploads}
                                                        onChange={this.handleUploadPDF}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Upload Gambar
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeFileGambar"
                                                        name="file"
                                                        type="file"
                                                        accept=".png,.jpg,.jpeg"
                                                        ref={this.fileUploads}
                                                        onChange={this.handleUploadGambar}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Upload Video
                                                    <p style={{ fontSize: '11px', color: 'red' }}>*jika lebih dari 40MB gunakan input link</p>
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeFileVideo"
                                                        name="file"
                                                        type="file"
                                                        accept=".mp4,.wmv,.mov"
                                                        ref={this.fileUploads}
                                                        onChange={this.handleUploadVideo}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-password-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    {/* Deskripsi */}
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeDeskripsi"
                                                        type="text"
                                                        rows="3"
                                                        placeholder="Input Link Vide CME"
                                                        onChange={(e) => this.setState({ cmelink: e.target.value })}
                                                        value={this.state.cmelink}
                                                    />
                                                </Col>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" color="primary" className="btn btn-primary w-md" onClick={() => this.save()}>
                                        Submit CME Online
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default TambahCME;

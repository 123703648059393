import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Editor } from '@tinymce/tinymce-react';

class TambahTanyaAhli extends Component {
    constructor(props) {
        super(props);
        this.ref = createRef();
        this.state = {
            basic: false,
            listNarasumber: [],
            listType: [
                {
                    nama: 'Artikel',
                    value: 'artikel'
                },
                {
                    nama: 'Webcast',
                    value: 'webcast'
                },
                {
                    nama: 'Tindakan Medis Spesialistik',
                    value: 'tindakan'
                },
                {
                    nama: 'Jurnal',
                    value: 'jurnal'
                },
                {
                    nama: 'Tata Laksana',
                    value: 'tata'
                }
            ],
            tanya_topik: '',
            tanya_content_id:'',
            tanya_narasumber: [],
            tanya_type: [],
            tanya_penyakit: '',
            tanya_spesialisasi:'',
            tanya_summary:'',
            tanya_thumbnail:'',
            tanya_summary:'',
            // hasilSpesialisasi:'',
            // hasilPenyakit:'',
            prosess: false
        };
    }

    componentDidMount() {
        this.getNarasumber()
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.props.history.push(`/tanya-ahli`)
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState: editorState,
            articleKonten: editorState.getCurrentContent().getPlainText()
        });
    };

    clearKonten = () => {
        this.setState({
            basic: false,
            editorState: EditorState.createEmpty(),
            jurnalJudul: '',
            jurnalPenulis: [],
            jurnalKonten: '',
            jurnalGambar: '',
            jurnalSpesialis: [],
            jurnalMolekul: [],
            jurnalPenyakit: []
        });
    }


    getNarasumber = () => {
        httprequester.get('ahli/get-narasumber', {
            success: async function (response) {

                // console.log("resLOV => ", response)
               
                // console.log('data',dataNew);
                if (response.data.message == "success") {
                    let dataNew = response.data.detailMessage.filter(a => a.status_narasumber == 'y')
                    
                    this.setState({
                        listNarasumber: dataNew
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveTanyaAhli= () => {

        let type = this.state.listType.find(k => k.nama == this.state.tanya_type)
        let slugLower = this.state.tanya_topik.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');
        

        let body = {
            "tanya_name": this.state.tanya_topik,
            "tanya_narasumber": this.state.tanya_narasumber[0],
            "tanya_content_id": this.state.tanya_content_id,
            "tanya_slug": slugRep,
            "tanya_summary": this.state.tanya_summary,
            "tanya_kuota":"",
            "tanya_kuota_left": "",
            "tanya_type": type.value,
            "tanya_status": "Unpublished",
            "tanya_penyakit":this.state.tanya_penyakit,
            "tanya_spesialisasi":this.state.tanya_spesialisasi,
            "tanya_thumbnail": this.state.tanya_thumbnail,
            "tanya_molekul":"",
            "tanya_periode_start":"",
            "tanya_periode_end": "",
        }
        // console.log("body =>", body)
        httprequester.postarticle('ahli/save-new', body, {
            success: async function (response) {
                // console.log("res => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        basic: true
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    save = () => {
        let slugLower = this.state.jurnalJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let formData = new FormData();
        formData.append("myfile", this.state.file);
        formData.append("jurnal_thumbnail", this.state.jurnalGambar);
        formData.append("jurnal_title", this.state.jurnalJudul);
        formData.append("jurnal_slug", slugRep);
        formData.append("jurnal_penulis", this.state.jurnalPenulis.toString());
        formData.append("jurnal_penyakit", this.state.jurnalPenyakit.toString());
        formData.append("jurnal_spesialis", this.state.jurnalSpesialis.toString());
        formData.append("jurnal_molekul", this.state.jurnalMolekul.toString());
        formData.append("jurnal_content", this.state.jurnalKonten)
        formData.append("jurnal_type", this.state.type)

        console.log("formData => ", formData);

        this.setState({
            prosess: true
        })
        httprequester.postupload('jurnal/save', formData, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        prosess: false
                    })
                    this.fileUploads.current
                    // e.target.value = null;
                    // this.fileUploads.current.value
                    // this.getFileUpload()
                }
                console.log('ini', this.fileUploads)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    handleChangeType(e) {
        this.setState({
            tanya_type: e
        })
        // console.log("INIT N", e);
    }

    handleChangeNarasumber(e) {
        this.setState({
            tanya_narasumber: e
        })
        // console.log("INIT S", e);
    }

    handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
        this.setState({
            tanya_summary: e.target.getContent()
        })
    }

  

    handleChangeContentID = (e) => {
        this.setState({
            tanya_content_id:e.target.value
        }) 
        // console.log('typr',this.state.tanya_type);
        if (this.state.tanya_type[0] == 'Artikel') {
            let body = {
                "article_id": e.target.value
            }
            httprequester.postnobody('artikel/detail', body, {
                success: async function (response) {

                    // console.log("resLOV => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            tanya_spesialisasi: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][8] : '',
                            tanya_penyakit: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][11] : ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
        }  else if (this.state.tanya_type[0] == 'Jurnal') {
            let body = {
                "jurnal_id": e.target.value
            }
            httprequester.postnobody('jurnal/detail', body, {
                success: async function (response) {

                    // console.log("resLOV => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            tanya_spesialisasi: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][10] : '',
                            tanya_penyakit: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][6] : ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
        } else if (this.state.tanya_type[0] == 'Webcast') {
            // console.log('wecast');
            let body = {
                "ri_webinar_id": e.target.value
            }
            httprequester.postnobody('webinar/detail', body, {
                success: async function (response) {

                    // console.log("resLOV => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            tanya_spesialisasi: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][2] : '',
                            tanya_penyakit: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][8] : ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
        } else if (this.state.tanya_type[0] == 'Tindakan Medis Spesialistik') {
            let body = {
                "tindakan_id": e.target.value
            }
            httprequester.postnobody('tindakan/detail', body, {
                success: async function (response) {

                    // console.log("resLOV => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            tanya_spesialisasi: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][2] : '',
                            tanya_penyakit: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][9] : ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
        }else if (this.state.tanya_type[0] == 'Tata Laksana') {
            let body = {
                "jurnal_id": e.target.value
            }
            httprequester.postnobody('jurnal/detail', body, {
                success: async function (response) {

                    // console.log("resLOV => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            tanya_spesialisasi: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][10] : '',
                            tanya_penyakit: response.data.settings.datarows.length > 0 ? response.data.settings.datarows[0][6] : ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
        }   
    
        
    }


    

    render() {
        const optionNarasumber = this.state.listNarasumber.map(o => o.narasumber_name)
        const optionType = this.state.listType.map(o => o.nama)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Tanya Ahli</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Tanya Ahli" breadcrumbItem="Tambah Tanya Ahli" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Tanya Ahli berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col lg={12}>
                               
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Nama Topik</Label>
                                                <Input
                                                    id="tanyatopik"
                                                    type="input"
                                                    placeholder="Input Nama Topik"
                                                    onChange={(e) => this.setState({ tanya_topik: e.target.value })}
                                                    value={this.state.tanya_topik}
                                                />
                                            </div>

                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Type</Label>
                                                        <Typeahead
                                                            // allowNew
                                                            id="basic-typeahead-multiple"
                                                            placeholder="Pilih Type"
                                                            labelKey="name"
                                                            options={optionType}
                                                            onChange={(e) => this.handleChangeType(e)}
                                                            selected={this.state.tanya_type}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">Content ID</Label>
                                                        <Input
                                                            id="tanyaahlicintentid"
                                                            type="input"
                                                            placeholder="Input Content ID"
                                                            onChange={this.handleChangeContentID}
                                                            value={this.state.tanya_content_id}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputCity">Narasumber</Label>
                                                        <Typeahead
                                                            // allowNew
                                                            id="basic-typeahead-multiple"
                                                            placeholder="Pilih Narasumber"
                                                            labelKey="name"
                                                            options={optionNarasumber}
                                                            onChange={(e) => this.handleChangeNarasumber(e)}
                                                            selected={this.state.tanya_narasumber}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">Thumbnail</Label>
                                                        <Input
                                                            id="tanyaahlithumbnail"
                                                            type="input"
                                                            placeholder="Input THumbnail"
                                                            onChange={(e) => this.setState({ tanya_thumbnail: e.target.value })}
                                                            value={this.state.tanya_thumbnail}
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">Spesialisasi</Label>
                                                        <Input
                                                            
                                                            id="tanyaahlicintentid"
                                                            type="input"
                                                            placeholder="Input Spesialisasi"
                                                            disabled={true}
                                                            value={this.state.tanya_spesialisasi}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">Penyakit</Label>
                                                        <Input
                                                            id="tanyaahlicintentid"
                                                            type="input"
                                                            placeholder="Input Penyakit"
                                                            disabled={true}
                                                            value={this.state.tanya_penyakit}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" md="12">
                                <Card>
                                    <CardBody>
                                        <Label htmlFor="formrow-InputZip">Summary</Label>
                                        <Form method="post">
                                            <Editor
                                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                content={this.state.tanya_summary}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent lineheight | ' +
                                                        'removeformat link',
                                                    content_style: 'body { font-family:Poppins,Arial,sans-serif; }',
                                                    line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 2',
                                                }}
                                                onChange={this.handleEditorChange}
                                            />
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" className="btn btn-primary w-md" onClick={() => this.saveTanyaAhli()}>
                                        Simpan Tanya Ahli
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default TambahTanyaAhli;

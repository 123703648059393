/* eslint-disable */
import axios from 'axios'
import { urlAPI } from '../config';

// DEV
var apiUrl = urlAPI;
// PROD
// var apiUrl = 'http://192.168.3.117:30631/api/v1/admin/'

export default {
    get_token: function () {
        var userData = JSON.parse(localStorage.getItem('user'))
        var userToken = JSON.parse(localStorage.getItem('token'))
        var token = (userData && userToken) ? userToken : ''
        return token
    },

    postarticle: function (url, param, callback) {
        axios.post(apiUrl + url, param, {
            headers: { Authorization: 'Bearer ' + this.get_token() }
        }).then(response => {
            callback.success(response)
        })
            .catch(e => {
                // callback.error(e)
                console.log(e)
            })
    },


    get: function (url, callback) {
        axios.get(apiUrl + url, {
            headers: { Authorization: 'Bearer ' + this.get_token() }
        }).then(response => {
            callback.success(response)
        })
            .catch(e => {
                // callback.error(e)
                console.log(e)
            })
    },

    getqueryparam: function (url, param, callback) {
        axios.get(apiUrl + url, {
            headers: { Authorization: 'Bearer ' + this.get_token() },
            params: param
        }).then(response => {
            callback.success(response)
        })
            .catch(e => {
                // callback.error(e)
                console.log(e)
            })
    },
    post: function (url, param, callback) {
        axios.post(apiUrl + url, {
            body: param
        }, {
            headers: { Authorization: 'Bearer ' + this.get_token() }
        }).then(response => {
            callback.success(response)
        }).catch(e => {
            if (e.response && e.response.status && e.response.status === 401) {
                // alert('Unauthorized, Please login...')
                localStorage.setItem('unauthorized', true)
                window.location.href = '/#/login'
                window.location.reload();
                return false
            } else {
                console.log(e)
                // callback.error(e)
            }
        })
    },
    login: function (url, param, callback) {
        axios.post(apiUrl + url,
            param
        ).then(response => {
            callback.success(response)
        }).catch(e => {
            console.log(e)
            if (e.response && e.response.status && e.response.status === 401) {
                alert('Unauthorized, Please login...')
                window.location.href = '/#/login'
                window.location.reload();
                return false
            } else {
                console.log(e)
                callback.error(e)
            }
        })
    },
    postFullUrl: function (url, param, callback) {
        axios.post(url, {
            body: param
        }).then(response => {
            callback.success(response)
        }).catch(e => {
            // callback.error(e)
            console.log(e)
        })
    },
    put: function (url, param, callback) {
        axios.put(apiUrl + url, { body: param }, {
            headers: { Authorization: 'Bearer ' + this.get_token() }
        }).then(response => {
            callback.success(response)
        }).catch(e => {
            callback.error(e)
        })
    },
    postnobody: function (url, param, callback) {
        axios.post(apiUrl + url, param, {
            headers: { Authorization: 'Bearer ' + this.get_token() }
        }).then(response => {
            callback.success(response)
        }).catch(e => {
            callback.error(e)
        })
    },

    posturlnoadmin: function (url, param, callback) {
        axios.post(apiUrl + url, param, {
            headers: { Authorization: 'Bearer ' + this.get_token() }
        }).then(response => {
            callback.success(response)
        }).catch(e => {
            callback.error(e)
        })
    },

    deletenoadmin: function (url, param, callback) {
        axios.post(apiUrl + url, param, {
            headers: { Authorization: 'Bearer ' + this.get_token() }
        }).then(response => {
            callback.success(response)
        }).catch(e => {
            callback.error(e)
        })
    },

    postupload: function (url, param, callback) {
        axios.post(apiUrl + url, param, {
            headers: {
                Authorization: 'Bearer ' + this.get_token(),
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            callback.success(response)
        }).catch(e => {
            callback.error(e)
        })
    }
}

import React, { Component } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { Formik, Field, Form, ErrorMessage } from 'formik';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import httprequester from '../../lib/httprequester';

import "../Master/datatables.scss";

import styles from './style/userRewardPoint.module.scss';


class UserRewardPoint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            listUserReward: [],
            loading: false,
            userName: '',
            userEmail: ''
        },
        this.initialValues = {
            userName: this.props.location.state?.userName ?? '',
            userEmail: this.props.location.state?.userEmail ?? ''
        };
    }

    componentDidMount() {
        if(this.props.location.state?.userName || this.props.location.state?.userEmail) {
            this.getUserReward(this.props.location.state?.userName ?? '', this.props.location.state?.userEmail ?? '');
        }
    }

    getUserReward = (userName, userEmail) => {
        this.setState({
            loading: true,
        });

        let userRewardParams = {
            "userName": userName,
            "userEmail": userEmail
        }

        httprequester.getqueryparam('admin/rewardActivity/getUser', userRewardParams, {
            success: async function (response) {
                if (response.data.status) {
                    this.setState({
                        listUserReward : response.data.listUserDetail,
                        loading: false,
                        userName: userName,
                        userEmail: userEmail
                    });
                } else {
                    this.setState({
                        listUserReward : [],
                        loading: false,
                        userName: userName,
                        userEmail: userEmail
                    });
                }
            }.bind(this),
            error: function (err) {
                console.log("Error get user reward:\n", err);

                this.setState({
                    listUserReward : [],
                    loading: false,
                    userName: userName,
                    userEmail: userEmail
                });
            }.bind(this)

        })
    }

    handleDetailUser = (userId) => {
        let searchValue = {
            'userEmail': this.state.userEmail, 
            'userName': this.state.userName
        };

        this.props.history.push(`/reward-point-activity/${userId}`, searchValue);
    }

    handleSearchButton = (values, { setSubmitting }) => {
        this.getUserReward(values.userName, values.userEmail);

        setSubmitting(false);
    };

    handleResetButton = (setFieldValue) => {
        setFieldValue('userName', '');
        setFieldValue('userEmail', '');

        this.setState({
            listUserReward : [],
            loading: false,
            userName: '',
            userEmail: ''
        });
      };

    render() {
        const columns = [
            {
                dataField: 'user_name',
                text: 'User Name',
                sort: true
            },
            {
                dataField: 'user_email',
                text: 'User Email',
                sort: true
            },
            {
                dataField: 'total_reward',
                text: 'Total Reward',
                sort: false
            },
            {
                dataField: 'user_id',
                text: 'Action',
                sort: false,
                formatter: (row) => {
                    return <div>
                        <Button
                            color="success"
                            className="btn-sm"
                            onClick={(e) => this.handleDetailUser(row)}>
                            Detail
                        </Button>
                    </div>
                }
            }
        ];

        const defaultSorted = [{
            dataField: 'user_name',
            order: 'asc'
        }];
    
        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.listUserReward.length,
            custom: true,
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Reward Point Activity</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs 
                            title="Halaman Utama" 
                            breadcrumbItem="Reward Point Activity"
                        />
                    </Container>
                    <div className="container-fluid">
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Formik
                                                initialValues={this.initialValues}
                                                onSubmit={this.handleSearchButton}
                                            >
                                                {({ isSubmitting, setFieldValue }) => (
                                                <Form>
                                                    <div className={styles.formContainer}>
                                                        <div className={styles.fieldContainer}>
                                                            <label className={styles.fieldLabelStyle}>User Name: </label>
                                                            <Field type="text" id="userName" name="userName" />
                                                            <ErrorMessage name="userName" component="div" className="error" />
                                                        </div>
                                                        <div className={styles.fieldContainer}>
                                                            <label className={styles.fieldLabelStyle}>User Email: </label>
                                                            <Field type="text" id="userEmail" name="userEmail" />
                                                            <ErrorMessage name="userEmail" component="div" className="error" />
                                                        </div>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className={`btn-sm ${styles.fieldContainer}`}
                                                            disabled={isSubmitting || this.state.loading}
                                                        >
                                                            Search
                                                        </Button>
                                                        <Button
                                                            color="danger"
                                                            className="btn-sm"
                                                            disabled={isSubmitting || this.state.loading}
                                                            onClick={() => this.handleResetButton(setFieldValue)}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </div>
                                                </Form>
                                                )}
                                            </Formik>
                                        </Row>
                                        {this.state.listUserReward.length > 0 
                                        ? <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField='user_name'
                                            columns={columns}
                                            data={this.state.listUserReward}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='user_id'
                                                    columns={columns}
                                                    data={this.state.listUserReward}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment><Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            defaultSorted={defaultSorted}
                                                                            keyField={"user_name"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />

                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )
                                                    }
                                                </ToolkitProvider>
                                            )
                                            }
                                        </PaginationProvider>
                                        : <Row className={styles.emptyResultContainer}>
                                            Tidak ada user yang ditemukan
                                        </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {this.state.loading 
                        ? <SweetAlert title="Menghubungkan ke Server" showConfirm={false} onConfirm={() => {}}/>
                        : <></>
                    }
                </div>
            </React.Fragment>
        )
    }
}


UserRewardPoint.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}

export default UserRewardPoint;
import React, { Component } from "react"
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import moment from 'moment'

import { AvForm, AvField } from 'availity-reactstrap-validation';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';
import cellEditFactory from 'react-bootstrap-table2-editor';

import { Link, withRouter } from "react-router-dom"

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Master/datatables.scss";


import httprequester from '../../lib/httprequester'

class ListUserCME extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            ListUser: [],
            loading: false,
            sertifikatPopup: false,
            imageSertifikat: '',
            generateSertifikatPopUp: false,
            bodySertifikat: {},
            alert: false,
            message: ''
        }
    }

    componentDidMount() {
        this.getUser()
    }

    popUpSertifikat = (row) => {
        this.setState({
            imageSertifikat: row.cme_sertifikat,
            sertifikatPopup: true
        })
    }

    popUpGenerateSertifikat = (row) => {
        this.setState({
            generateSertifikatPopUp: true,
            bodySertifikat: { ...row }
        })
    }

    getUser = () => {
        let body = {
        }

        this.setState({
            loading: true,
        })

        httprequester.postnobody('cme/list_user_cme', body, {
            success: async function (response) {
                // if (response.data.message == "success") {
                this.setState({
                    ListUser: response.data.detailMessage,
                    loading: false,
                })
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
        console.log(body)
    }

    generateSertifikat = () => {
        this.setState({
            generateSertifikatPopUp: false,
            loading: true
        })

        let body = {
            ...this.state.bodySertifikat
        }

        httprequester.postnobody('cme/generate_sertifikat2', body, {
            success: async function (response) {
                // if (response.data.message == "success") {
                this.setState({
                    message: response.data.detailMessage,
                    loading: false,
                    alert: true
                })
                this.getUser()
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })

        // console.log(body)
    }

   

    render() {

        const columns = [
            {
                dataField: 'user_id',
                text: 'User ID',
                // sort: true,
                hidden: true
            },
            {
                dataField: 'user_name',
                text: 'Nama User',
                sort: true
            },
            {
                dataField: 'npa_name',
                text: 'Nama NPA',
                sort: true
            },
            {
                dataField: 'cme_name',
                text: 'Judul CME',
                sort: true
            },
            {
                dataField: 'cme_score',
                text: 'Score CME',
                sort: true
            },
            // {
            //     dataField: 'start_date',
            //     text: 'Start Date',
            //     sort: true,
            //     formatter: (row) => {
            //         return <div>
            //             {moment(row).format("YYYY-MM-DD HH:mm:ss")}
            //         </div>
            //     }
            // },
            // {
            //     dataField: 'end_date',
            //     text: 'End Date',
            //     sort: true,
            //     formatter: (row) => {
            //         return <div>
            //             {moment(row).format("YYYY-MM-DD HH:mm:ss")}
            //         </div>
            //     }
            // },
            {
                dataField: 'issued_certificate_date',
                text: 'Certificate Date',
                sort: true,
                formatter: (row) => {
                    if (row !== "-") {
                        return <div>
                            {moment(row).format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                    }
                    return <div>
                        {row}
                    </div>

                }
            },
            {
                dataField: 'cme_ol_id',
                editable: false,
                sort: false,
                formatter: (v, row) => {
                    console.log(row)

                    return <div>
                        {
                            (row.cme_score >= 60) ?
                                <div>
                                    <a className="btn-sm btn-success" rel="noreferrer" target={"blank"} href={row.cme_sertifikat ? row.cme_sertifikat.replace("http:", "https:") : row.cme_sertifikat}>
                                        Lihat Sertifikat
                                    </a>
                                    &nbsp;&nbsp;
                                    <Button color="danger" className="btn-sm" onClick={(e) => this.popUpGenerateSertifikat(row)}>
                                        Ulang Sertifikat
                                    </Button>
                                </div>
                                : null
                        }

                    </div>
                }
            }];

        const defaultSorted = [{
            dataField: 'cme_score',
            order: 'desc'
        }];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.ListUser.length, // replace later with size(customers),
            custom: true,
        }

        // Custom Pagination Toggle
        const sizePerPageList = [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '25', value: 25 },
            { text: 'All', value: (this.state.ListUser).length }];


        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>User CME | Ruang Ilmiah</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Halaman Utama" breadcrumbItem="User CME"
                        />
                    </Container>
                    <div className="container-fluid">
                        {this.state.sertifikatPopup ? (
                            <SweetAlert

                                title="Sertifikat CME"
                                // onConfirm={() => this.updateJawaban()}
                                onCancel={() => this.setState({ sertifikatPopup: false })}
                                showCancel={true}
                                showConfirm={false}
                                style={{ width: '70em' }}
                            >

                                {(renderProps) => (
                                    <form>
                                        <img src={this.state.imageSertifikat} width={"100%"} />
                                    </form>


                                )}
                            </SweetAlert>
                        ) : null}

                        {this.state.generateSertifikatPopUp ? (
                            <SweetAlert

                                title="Buat Ulang Sertifikat CME"
                                onConfirm={() => this.generateSertifikat()}
                                onCancel={() => this.setState({ generateSertifikatPopUp: false })}
                                showCancel={true}
                                showConfirm={true}
                            // style={{ width: '70em' }}
                            >

                                {(renderProps) => (
                                    <p>
                                        {`Apakah Anda Yakin Akan Membuat Ulang Sertifikat ${this.state.bodySertifikat.cme_name} Atas Nama ${this.state.bodySertifikat.user_name} ?`}
                                    </p>
                                )}
                            </SweetAlert>
                        ) : null}
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField='cme_ol_id'
                                            // keyField='user_id'
                                            columns={columns}
                                            data={this.state.ListUser}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='cme_ol_id'
                                                    // keyField='user_id'
                                                    columns={columns}
                                                    data={this.state.ListUser}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>

                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"cme_ol_id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />

                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )
                                                    }
                                                </ToolkitProvider>
                                            )
                                            }</PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {this.state.alert ?
                        <SweetAlert title={this.state.message} onConfirm={() => this.setState({ alert: false })}>
                        </SweetAlert> : ''
                    }
                    {this.state.loading ? (
                        <SweetAlert title="menghubungkan ke server" showConfirm={false} >
                        </SweetAlert>
                    ) : null}
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(ListUserCME);
import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import SweetAlert from "react-bootstrap-sweetalert"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios"

import { urlAPI } from "config";
class TambahArtikelUser extends Component {
    constructor(props) {
        super(props);
        this.fileUploads = createRef();
        this.state = {
            basic: false,
            listUser: [],
            selectedUserId: 0,
            selectedUserName: '',
            judulArtikel: '',
            fileLampiran: null,
            resultMessage: '',
            formValidation: true,
        };
    }

    componentDidMount() {
        this.getListUserTMT();
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.props.history.push(`/verifikasi-artikel`)
    }

    getListUserTMT = () => {
        httprequester.get('artikel/getListUserTMT', {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        listUser: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
            }.bind(this)
        });
    }

    backduplicate = () => {
        this.setState({
            basicduplicate:false
        })
    }

    save = () => {
        let userData = JSON.parse(localStorage.getItem('user'));

        if (this.state.selectedUserId === 0
            || this.state.judulArtikel === ''
            || this.state.fileLampiran === null
        ) {
            this.setState({
                formValidation: false,
                resultMessage: 'Form harus diisi lengkap.',
            });

            return;
        }

        this.setState({
            formValidation: true,
            process: true
        });

        let formData = new FormData();
        formData.append("myfile", this.state.fileLampiran);
        formData.append("artikel_title", this.state.judulArtikel);
        formData.append("artikel_penulis", this.state.selectedUserName);
        formData.append("artikel_status", 'Terkirim');
        formData.append("artikel_image_id", "");
        formData.append("created_by", userData.admin_name);
        formData.append("user_id", this.state.selectedUserId);
        
        axios.post(urlAPI + 'artikel/submitCme', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem('token').replaceAll('"', '')}`
            }
        }).then(res => {
            this.setState({
                basic: true,
                resultMessage: res.data.detailMessage,
                process: false
            });
        })
    }

    handleUser(e) {
        if (e.length == 0)
            this.setState({
                selectedUserId: 0,
                selectedUserName: '',
            });
        else
            this.setState({
                selectedUserId: e[0].user_id,
                selectedUserName: e[0].user_name,
            });
    }

    handleFileUpload = (e) => {
        this.setState({
            fileLampiran: e.target.files[0]
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Artikel User</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Artikel" breadcrumbItem="Tambah Artikel User" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title={this.state.resultMessage}
                                onConfirm={() => this.props.history.push('/verifikasi-artikel')}
                            ></SweetAlert>
                        ) : null}

                        {this.state.process ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        {!this.state.formValidation ? (
                            <SweetAlert
                                title={this.state.resultMessage}
                                showConfirm={true}
                                onConfirm={() => this.setState({ formValidation: true})}
                            ></SweetAlert>
                        ) : null}

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    User
                                                </Label>
                                                <Col sm={9}>
                                                    <Typeahead
                                                        id="artikelUser"
                                                        labelKey="user_name"
                                                        options={this.state.listUser}
                                                        onChange={(e) => this.handleUser(e)}
                                                        selected={this.state.cmeOrganisasi}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Judul Artikel
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="artikelJudul"
                                                        type="input"
                                                        placeholder=""
                                                        onChange={(e) => this.setState({ judulArtikel: e.target.value })}
                                                        value={this.state.cmeJudul}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Lampiran (DOCX/DOC)
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="artikelFileLampiran"
                                                        name="file"
                                                        type="file"
                                                        accept=".doc,.docx"
                                                        ref={this.fileUploads}
                                                        onChange={this.handleFileUpload}
                                                    />
                                                </Col>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" color="primary" className="btn btn-primary w-md" onClick={() => this.save()}>
                                        Submit
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default TambahArtikelUser;

import React, { Component } from "react"
import PropTypes from 'prop-types'
import httprequester from '../../lib/httprequester'
// var axios = require('axios')
import axios from 'axios'
// import { withRouter } from "react-router";


import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

//Import config
import { facebook, google } from "../../config"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions
// import { apiError, loginUser, socialLogin } from "../../store/actions"
import { apiError, loginUser, socialLogin, loginError } from "../../store/actions"

// import images
// import profile from "../../assets/images/profile-img.png"
import profile from "../../assets/images/ruang-ilmiah.png"
import logo from "../../assets/images/logo.svg"
import lightlogo from "../../assets/images/logo-light.svg"

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      isLoading: false,
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)

    // this.doLogin = this.doLogin.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    // console.log('hasil', event)
    this.props.loginUser(values, this.props.history)
    // this.props.loginError(values)
    // console.log('balik',this.loginError())
  }

  componentDidMount() {
    this.props.apiError("")
  }

  signIn = (res, type) => {
    const { socialLogin } = this.props
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, this.props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, this.props.history, type)
    }
  }

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, "google")
  }

  //handleTwitterLoginResponse
  twitterResponse = () => { }

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, "facebook")
  }

  handleLogin(e) {
    console.log("BISA NIH")
    // e.preventDefault();
    this.doLogin();
  }

  static propTypes = {
    history: PropTypes.object.isRequired
  }

  doLogin() {

    // console.log("username => ", this.state.username);
    // console.log("password => ", this.state.password);

    // this.setState({ isLoading: true })
    
    // CARA 1
    let param = {
      username: this.state.username,
      password: this.state.password,
    };
    // console.log(param)
    httprequester.login('admin/login', param, {
      success: async function (response) {
        // console.log("res", response)
        if (response.data.message === "success") {
          // let user = response.data.data
          // user.token = response.data.token
          localStorage.setItem('user', JSON.stringify(response.data.detailMessage))
          localStorage.setItem('token', JSON.stringify(response.data.token))
          // this.setState({ isLoading: false })
          this.props.history.push('/dashboard')
          // window.location.reload();
        } else {
          // this.setState({ isLoading: false })
        }
      }.bind(this),
      error: function (err) {
        // this.setState({ isLoading: false })
        console.log(err)
      }.bind(this)
    })

    // CARA 2
    // var data = JSON.stringify({
    //   "username": this.state.username,
    //   "password": this.state.password
    // });

    // var config = {
    //   method: 'post',
    //   url: 'http://192.168.3.117:30631/api/v1/admin/login',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   data: data
    // };
    // console.log(config)
    // axios(config)
    //   .then(function (response) {
    //     localStorage.setItem('token', response.data.token)
    //     this.props.history.push('/dashboard')
    //     // this.props.history.push('/home')
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }

  render() {
    return (
      <React.Fragment>
        {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div> */}
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-danger bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-danger p-4">
                          <h5 className="text-danger">Welcome Back !</h5>
                          <p>Sign in to continue.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                        // onValidSubmit={this.handleLogin}
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        <div className="mb-3">
                          {/* <AvField
                            name="email"
                            label="Email"
                            value="admin@themesbrand.com"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          /> */}
                          <AvField
                            name="username"
                            label="Username"
                            type="username"
                            placeholder="Enter Username"
                            // autoComplete="username"
                            className="form-control"
                            onChange={(e) => this.setState({ username: e.target.value })}
                            disabled={this.state.isLoading}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          {/* <AvField
                            name="password"
                            label="Password"
                            value="123456"
                            type="password"
                            required
                            placeholder="Enter Password"
                          /> */}
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            placeholder="Enter Password"
                            // autoComplete="current-password"
                            onChange={(e) => this.setState({ password: e.target.value })}
                            disabled={this.state.isLoading}
                            required
                          />
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-danger btn-block"
                            type="submit"
                            // onClick={this.doLogin}
                            // disabled={this.state.isLoading}
                          >
                            Log In
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                          <h5 className="font-size-14 mb-3">Sign in with</h5>

                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <FacebookLogin
                                appId={facebook.APP_ID}
                                autoLoad={false}
                                callback={this.facebookResponse}
                                render={renderProps => (
                                  <Link
                                    to={""}
                                    className="social-list-item bg-primary text-white border-primary"
                                  >
                                    <i className="mdi mdi-facebook" />
                                  </Link>
                                )}
                              />
                            </li>
                            <li className="list-inline-item">
                              <GoogleLogin
                                clientId={google.CLIENT_ID}
                                render={renderProps => (
                                  <Link
                                    to={""}
                                    className="social-list-item bg-danger text-white border-danger"
                                  >
                                    <i className="mdi mdi-google" />
                                  </Link>
                                )}
                                onSuccess={this.googleResponse}
                                onFailure={() => { }}
                              />
                            </li>
                          </ul>
                        </div> */}

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" /> Forgot your
                            password?
                          </Link>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>Don&apos;t have an account ?
                    <Link
                      to="register"
                      className="fw-medium text-primary"
                    >
                      Signup Now
                    </Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()} Ruang Ilmiah. Crafted with
                    <i className="mdi mdi-heart text-danger" /> by Dexa Medica
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  loginError: PropTypes.func,
  socialLogin: PropTypes.func
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin, loginError })(Login)
)

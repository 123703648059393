import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
    FormGroup,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Alert
} from 'reactstrap';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'

import { isEmpty, map, size } from "lodash"
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Typeahead } from 'react-bootstrap-typeahead';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class JurnalTataLaksana extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            totalPage: [],
            listJurnal: [],
            listCategory: [],
            listValue: [],
            alert: false,
            openmodal: false,
            type: 'jurnal',
            // modal: false,
            message: '',
            urlimage: '',
            id: null,
            categoriesArticle: '',
            statusJurnal: 'Published',
            searchJurnal: '',
            valueJurnal: '',
            urutkanJurnal: '',
            alertcopy: false,
            namaalert: '',
            listPenyakit: [],
            listMolekul: [],
            listPengarang: [],
            listSpesialisasi: [],
            hasilPengarang: [],
            hasilPenyakit: [],
            hasilSpesialisasi: [],
            hasilMolekul: [],
        }
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    componentDidMount() {
        this.getJurnal();
        this.getLov("jurnal")
    }

    
    getLov(type) {
        let body = {
            hp_type: type
        }

        httprequester.postarticle('homepage/lov_content', body, {
            success: async function (response) {

                let { data } = response;
                // let { detailMessage } = response.data;
                this.setState({
                    listMolekul: data.resultMolekul,
                    listPengarang: data.resultPenulis,
                    listPenyakit: data.resultPenyakit,
                    listSpesialisasi: data.resultSpecialist
                })
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handlePageClick = (e) => {
        let a = e
        this.setState({
            page: a
        })

        let penulis = this.state.hasilPengarang[0] ? this.state.hasilPengarang[0] : ''
        let molekul = this.state.hasilMolekul[0] ? this.state.hasilMolekul[0] : ''
        let spesialisasi = this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : ''
        let penyakit = this.state.hasilPenyakit[0] ? this.state.hasilPenyakit[0] : ''
        let body = {
            "spesialisasi": spesialisasi,
            "search": this.state.searchJurnal,
            "penyakit": penyakit,
            "status": this.state.statusJurnal,
            "molekul": molekul,
            "penulis": penulis,
            "page": e,
            "limit": 10,
            "urutan": "created_at DESC",
            "type": this.state.type
        }

        // console.log("body =>", body)
        httprequester.postarticle('jurnal/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            jurnal_pdf: v[0],
                            jurnal_thumbnail: v[1],
                            created_at: v[2],
                            jurnal_id: v[3],
                            created_by: v[4],
                            jurnal_slug: v[5],
                            jurnal_penyakit: v[6],
                            jurnal_tags: v[7],
                            jurnal_type: v[8],
                            myfile: v[9],
                            jurnal_spesialis: v[10],
                            jurnal_molekul: v[11],
                            jurnal_title: v[12],
                            jurnal_category: v[13],
                            jurnal_content: v[14],
                            jurnal_status: v[15],
                            jurnal_penulis: v[16]
                        })
                    })
                    this.setState({
                        listJurnal: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getJurnal = () => {
        let penulis = this.state.hasilPengarang[0] ? this.state.hasilPengarang[0] : ''
        let molekul = this.state.hasilMolekul[0] ? this.state.hasilMolekul[0] : ''
        let spesialisasi = this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : ''
        let penyakit = this.state.hasilPenyakit[0] ? this.state.hasilPenyakit[0] : ''
        let body = {
            "spesialisasi": spesialisasi,
            "search": this.state.searchJurnal,
            "penyakit": penyakit,
            "status": this.state.statusJurnal,
            "molekul": molekul,
            "penulis": penulis,
            "page": 1,
            "limit": 10,
            "urutan": "created_at DESC",
            "type": this.state.type
        }

        // console.log("body =>", body)
        httprequester.postarticle('jurnal/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                        
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            jurnal_pdf: v[0],
                            jurnal_thumbnail: v[1],
                            created_at: v[2],
                            jurnal_id: v[3],
                            created_by: v[4],
                            jurnal_slug: v[5],
                            jurnal_penyakit: v[6],
                            jurnal_tags: v[7],
                            jurnal_type: v[8],
                            myfile: v[9],
                            jurnal_spesialis: v[10],
                            jurnal_molekul: v[11],
                            jurnal_title: v[12],
                            jurnal_category: v[13],
                            jurnal_content: v[14],
                            jurnal_status: v[15],
                            jurnal_penulis: v[16]
                        })
                    })
                    this.setState({
                        listJurnal: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    reset = () => {
        let body = {
            "spesialisasi": "",
            "search": "",
            "penyakit": "",
            "status": this.state.statusJurnal,
            "molekul": "",
            "penulis": "",
            "page": 1,
            "limit": 10,
            "urutan": "created_at DESC",
            "type": this.state.type
        }

        this.setState({
            hasilSpesialisasi: [],
            hasilMolekul: [],
            hasilPenyakit: [],
            hasilPengarang: [],
            search: '',
            page: 1
        })

        // console.log("body =>", body)
        httprequester.postarticle('jurnal/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            jurnal_pdf: v[0],
                            jurnal_thumbnail: v[1],
                            created_at: v[2],
                            jurnal_id: v[3],
                            created_by: v[4],
                            jurnal_slug: v[5],
                            jurnal_penyakit: v[6],
                            jurnal_tags: v[7],
                            jurnal_type: v[8],
                            myfile: v[9],
                            jurnal_spesialis: v[10],
                            jurnal_molekul: v[11],
                            jurnal_title: v[12],
                            jurnal_category: v[13],
                            jurnal_content: v[14],
                            jurnal_status: v[15],
                            jurnal_penulis: v[16]
                        })
                    })
                    this.setState({
                        listJurnal: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    updateStatusJurnal = (value) => {
        let body = {
            "jurnal_id": value.jurnal_id,
            "jurnal_status": value.jurnal_status === 'Published' ? 'Unpublished' : 'Published'
        }

        let dx = {
            content_id: value.jurnal_id,
            content_penulis: value.jurnal_penulis,
            content_title: value.jurnal_title,
            created_at: value.created_at,
            content_slug: value.jurnal_slug,
            content_penyakit: value.jurnal_penyakit,
            content_molekul: value.jurnal_molekul,
            content_spesialis: value.jurnal_spesialis,
            hp_type: this.state.type
        }

        // console.log("bodyUpdate =>", body)
        httprequester.postarticle('jurnal/update', body, {
            success: async function (response) {
                // console.log("resUpdate => ", response)
                this.setState({
                    alert: true,
                    message: 'success'
                })

                if(body.jurnal_status === "Published"){
                    httprequester.postarticle('homepage/lov_construct', dx, {
                        success: async function (responses) {
                            // console.log(responses)
                        }.bind(this),
                        error: function (err) {
                            console.log("error", err)
                        }.bind(this)
                    })
                }else{
                    httprequester.postarticle('homepage/lov_destruct', dx, {
                        success: async function (responses) {
                            //console.log(responses)
                        }.bind(this),
                        error: function (err) {
                            console.log("error", err)
                        }.bind(this)
                    })
                }

                setTimeout(() => {
                    this.getJurnal();
                }, 2000);

                // if (response.data.message == "success") {
                //     this.setState({
                //         listArtikel: data
                //     })
                // }
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    direct = (id, status, type) => {
        // this.setState({
        //     basic: false
        // })
        this.props.history.push(`/jurnal/editjurnaltatalaksana/${id}/${status}/${type}`)
    }

    directSEO = (id, type) => {
        this.props.history.push(`/seoconfig/${id}/${type}`)
    }

    handleType(e) {
        this.setState({ type: e.target.value })
        let d = e.target.value;
        if (e.target.value === "tata laksana") {
            d = "tata-laksana"
        }
        // console.log("masuk sini")
        this.getLov(d)
    }

    handlecancel = () => {
        this.setState({
            alert: false
        })
    }

    copyurl = (value) => {
        this.setState({
            namaalert: value.jurnal_title,
            alertcopy: true
        })
        navigator.clipboard.writeText(value.jurnal_id)
        setTimeout(() => {
            this.setState({
                alertcopy: false
            })
        }, 3000);
    }

    deleteJurnal = (value) => {
        let body = {
            "jurnal_id": value.jurnal_id,
            "jurnal_status": "Deleted"
        }
        // console.log("bodyDelete =>", body)
        httprequester.postarticle('jurnal/update', body, {
            success: async function (response) {
                // console.log("resDelete => ", response)
                this.setState({
                    message: response.data.detailMessage,
                    alert: true
                })
                setTimeout(() => {
                    this.getJurnal()
                }, 2000);

                // if (response.data.message == "success") {
                //     this.setState({
                //         listArtikel: data
                //     })
                // }
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    render() {
        // console.log("pagenya", this.state.page)
        const { page, totalPage } = this.state
        const option = this.state.listSpesialisasi.map(o => o.ri_lov_content_name)
        const optionPengarang = this.state.listPengarang.map(o => o.ri_lov_content_name)
        const optionMolekul = this.state.listMolekul.map(o => o.ri_lov_content_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.ri_lov_content_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Jurnal & Tata Laksana</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Halaman Utama" breadcrumbItem="Jurnal & Tata Laksana" />

                        <Row style={{ paddingBottom: 10 }}>
                            <Col xs="12" md="4">
                                <Link to="/jurnal/tambahjurnaltatalaksana">
                                    <Button color="danger">
                                        Tambah Jurnal & Tata Laksana
                                    </Button>
                                </Link>
                            </Col>
                        </Row>

                        <div>
                            {this.state.alert ? (
                                <SweetAlert success title={this.state.message} onConfirm={() => this.setState({ alert: false })}>
                                </SweetAlert>
                            ) : null}


                            <Row>
                                <Col xs="12" md="9">
                                    {this.state.alertcopy ? <div>
                                        <Alert
                                            color="primary"
                                            isOpen={this.state.alertcopy}
                                        >
                                            sucess copy url {this.state.namaalert}
                                        </Alert>
                                    </div> : ''}
                                    <Row xs="3">
                                        {this.state.listJurnal.map(function (jurnal, index) {
                                            return <Col key={index}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="text-truncate font-size-15" tag="h5" style={{ maxHeight: '50%' }}>
                                                            {jurnal.jurnal_title}
                                                        </CardTitle>
                                                        <p className="text-muted mb-4">{jurnal.jurnal_penulis}</p>
                                                        <CardText>
                                                            {jurnal.jurnal_spesialis}
                                                        </CardText>
                                                        <CardText>
                                                            {jurnal.jurnal_penyakit}
                                                        </CardText>
                                                        <CardText>
                                                            {jurnal.jurnal_molekul}
                                                        </CardText>
                                                        <Row>
                                                            <div className="d-flex flex-wrap gap-3" style={{ paddingBottom: 5, justifyContent: "center", alignItems: "center" }}>
                                                                <div className="btn-group btn-group-lg">
                                                                    {/* <button type="button" className="btn btn-outline-danger" onClick={() => { console.log("btnPreview") }}> <i className="fas fa-eye"></i> </button> */}
                                                                    {/* <Link type="button" className="btn btn-outline-danger" to={`artikel-preview/${jurnal.jurnal_id}-${jurnal.jurnal_slug}`} target="_blank"> <i className="fas fa-eye"></i> </Link> */}
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.direct(jurnal.jurnal_id, jurnal.jurnal_status, jurnal.jurnal_type) }}> <i className="fas fa-edit"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.deleteJurnal(jurnal) }}> <i className="fas fa-trash"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.copyurl(jurnal) }}> <i className="fas fa-copy"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO(jurnal.jurnal_id, jurnal.jurnal_type)}}> <i className="fas fa-globe"></i> </button>
                                                                </div>
                                                            </div>

                                                            <div className="d-grid gap-2">
                                                                <Button color="primary" type="button" className="btn-lg" onClick={() => { this.updateStatusJurnal(jurnal) }}> {jurnal.jurnal_status === 'Published' ? 'Unpublished' : 'Published'} </Button>
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        }.bind(this))}
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                                {this.state.totalPage.map((v, key) => {
                                                    return <PaginationItem active={this.state.page === v} key={key}>
                                                        <PaginationLink
                                                            onClick={() => this.handlePageClick(v)}
                                                        >
                                                            {v}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                })}
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xl={3} lg={4}>
                                    <Card>
                                        <CardBody className="p-4">
                                            <div>

                                                <div className="search-box">
                                                    <p className="text-muted">Cari</p>
                                                    <div className="position-relative">
                                                        <input
                                                            type="search"
                                                            className="form-control rounded bg-light border-light"
                                                            placeholder="Cari..."
                                                            onChange={(e) => this.setState({ searchJurnal: e.target.value })}
                                                            value={this.state.searchJurnal}
                                                        />
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>

                                                <hr className="my-4" />
                                                <Input
                                                    // id="focusAfterClose"
                                                    onChange={(e) => this.setState({ statusJurnal: e.target.value })}
                                                    // onChange= {(e) => console.log(e.target.value) }
                                                    type="select"
                                                >
                                                    <option value="Published">
                                                        Published
                                                    </option>
                                                    <option value="Unpublished">
                                                        Unpublished
                                                    </option>
                                                </Input>
                                                <br />
                                                <p className="text-muted">Tipe Jurnal & Tata Laksana</p>
                                                <Input
                                                    // id="focusAfterClose"
                                                    onChange={(e) => this.handleType(e)}
                                                    // onChange= {(e) => console.log(e.target.value) }
                                                    type="select"
                                                >
                                                    <option value="jurnal">
                                                        Jurnal
                                                    </option>
                                                    <option value="tata laksana">
                                                        Pedoman Tata Laksana
                                                    </option>
                                                </Input>
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilSpesialisasi: e })}
                                                    options={option}
                                                    placeholder="Pilih Spesialisasi"
                                                    selected={this.state.hasilSpesialisasi}
                                                />
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilPengarang: e })}
                                                    options={optionPengarang}
                                                    placeholder="Pilih Narasumber"
                                                    selected={this.state.hasilPengarang}
                                                />
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilMolekul: e })}
                                                    options={optionMolekul}
                                                    placeholder="Pilih Molekul"
                                                    selected={this.state.hasilMolekul}
                                                />
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilPenyakit: e })}
                                                    options={optionPenyakit}
                                                    placeholder="Pilih Penyakit"
                                                    selected={this.state.hasilPenyakit}
                                                />
                                                <hr className="my-4" />

                                            </div>

                                            <hr className="my-4" />

                                            <Button color="danger" onClick={() => this.getJurnal()}>
                                                Cari
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button color="danger" onClick={() => this.reset()}>
                                                Atur Ulang
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        {this.state.alert ?
                            <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
                            </SweetAlert> : ''
                        }
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(JurnalTataLaksana);

import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import moment from 'moment'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"

//urlAPI
import UrlApi from "../../helpers/UrlAPI"

import httprequester from '../../lib/httprequester'

class moderasiJurnal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      listJurnal: [],
      alert: false,
      message: '',
      id: null
    }

  }

  getJurnal = () => {

    let body = {
      // "status": "Published",
      // "search": "",
      // "organisasi": "",
      // "lokasi": "",
      // "start": "",
      // "end": "",
      // "page": 1,
      // "limit": 10,
      // "urutan": "event_name ASC",

      "category": "",
      "search": "",
      "value": "",
      "status": "Unpublished",
      "page": 1,
      "limit": 10,
      "urutan": "jurnal_title ASC"
    }

    httprequester.postnobody('jurnal/getall', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          // console.log('event',response.data.result.data.dataRows)
          let data = []
          response.data.result.data.dataRows.forEach(async (v, key) => {
            await data.push({
              jurnal_pdf: v[0],
              jurnal_thumbnail: v[1],
              created_at: v[2],
              jurnal_id: v[3],
              created_by: v[4],
              jurnal_slug: v[5],
              jurnal_penyakit: v[6],
              jurnal_tags: v[7],
              jurnal_spesialis: v[8],
              jurnal_molekul: v[9],
              jurnal_title: v[10],
              jurnal_category: v[11],
              jurnal_content: v[12],
              jurnal_status: v[13],
              jurnal_penulis: v[14]


              // foto: v[0],
              // lokasi: v[1],
              // deskripsi: v[2],
              // spesialisasi: v[3],
              // created_at: v[4],
              // nama_organisasi: v[5],
              // user: v[6],
              // event_id: v[7],
              // d: v[8],
              // tanggal: moment(v[9]).format('DD MMM YYY hh:mm:ss'),
              // website: v[10],
              // nama_acara: v[11],
              // status: v[12]
            })
          })
          this.setState({
            listJurnal: data
          })
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  handlecancel = () => {
    this.setState({
      alert: false
    })
  }

  updateStatus = (id) => {
    // console.log('id',id)
    let pencarian = this.state.listJurnal.find(i => i.jurnal_id == id.jurnal_id)
    // console.log('pencarian',pencarian)

    if (id.jurnal_status == 'Published') {
      let body = {
        "jurnal_id": pencarian.jurnal_id,
        "jurnal_status": "Unpublished"
      }

      httprequester.postnobody('jurnal/update', body, {
        success: async function (response) {
          if (response.data.message == "success") {
            this.setState({
              message: response.data.detailMessage,
              alert: true
            })
            await this.getJurnal()
          }
          // console.log(response)
        }.bind(this),
        error: function (err) {

        }.bind(this)

      })
    } else if (id.jurnal_status == "Unpublished") {
      let body = {
        "jurnal_id": pencarian.jurnal_id,
        "jurnal_status": "Published"
      }

      httprequester.postnobody('jurnal/update', body, {
        success: async function (response) {
          if (response.data.message == "success") {
            this.setState({
              message: response.data.detailMessage,
              alert: true
            })
            await this.getJurnal()
          }
          // console.log(response)
        }.bind(this),
        error: function (err) {

        }.bind(this)

      })
    } else if (id.jurnal_status == "Rejected") {
      let body = {
        "jurnal_id": pencarian.jurnal_id,
        "jurnal_status": "Unpublished"
      }

      httprequester.postnobody('jurnal/update', body, {
        success: async function (response) {
          if (response.data.message == "success") {
            this.setState({
              message: response.data.detailMessage,
              alert: true
            })
            await this.getJurnal()
          }
          // console.log(response)
        }.bind(this),
        error: function (err) {

        }.bind(this)

      })
    }
  }

  reject = (id) => {
    // console.log('id', this.state.id)

    let pencarian = this.state.listJurnal.find(i => i.jurnal_id == id.jurnal_id)
    // console.log('pencarian',pencarian)
    let body = {
      "jurnal_id": pencarian.jurnal_id,
      "jurnal_status": "Rejected"
    }

    httprequester.postnobody('jurnal/update', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true
          })
          await this.getJurnal()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  handleselect = (e) => {

    let body = {
      "category": "",
      "search": "",
      "value": "",
      "status": e.target.value,
      "page": 1,
      "limit": 10,
      "urutan": "jurnal_title ASC"
    }

    httprequester.postnobody('jurnal/getall', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          // console.log('event',response.data.result.data.dataRows)
          let data = []
          response.data.result.data.dataRows.forEach(async (v, key) => {
            await data.push({
              jurnal_pdf: v[0],
              jurnal_thumbnail: v[1],
              created_at: v[2],
              jurnal_id: v[3],
              created_by: v[4],
              jurnal_slug: v[5],
              jurnal_penyakit: v[6],
              jurnal_tags: v[7],
              jurnal_spesialis: v[8],
              jurnal_molekul: v[9],
              jurnal_title: v[10],
              jurnal_category: v[11],
              jurnal_content: v[12],
              jurnal_status: v[13],
              jurnal_penulis: v[14]
            })
          })
          this.setState({
            listJurnal: data
          })
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  componentDidMount() {
    this.getJurnal()
  }

  render() {

    const columns = [
      // {
      //   dataField: 'foto',
      //   text: 'Foto',
      //   sort: true,
      //   formatter: (row) => {
      //     return <div>
      //       <img
      //         src={row}
      //         alt="sertifikat-img"
      //         title="sertifikat-img"
      //         className="avatar-md"
      //         onClick={() => window.open(row, "_blank")}
      //       // onClick={(e) => { this.Open(row) }}
      //       />
      //     </div>
      //   }
      // },
      {
        dataField: 'jurnal_title',
        text: 'Judul',
        sort: true
      }, {
        dataField: 'jurnal_spesialis',
        text: 'Spesialis',
        sort: true
      }, {
        dataField: 'jurnal_penyakit',
        text: 'Penyakit',
        sort: true
      }, {
        dataField: 'jurnal_molekul',
        text: 'Molekul',
        sort: true
      }, {
        dataField: 'jurnal_penulis',
        text: 'Penulis',
        sort: true
      },
      // {
      //   dataField: 'spesialisasi',
      //   text: 'Spesialisasi',
      //   sort: true
      // }, {
      //   dataField: 'status',
      //   text: 'Status',
      //   sort: true
      // }, {
      //   dataField: 'user',
      //   text: 'User Organisasi',
      //   sort: true
      // }, 
      {
        dataField: 'jurnal_status',
        text: 'Aksi',
        sort: true,
        formatter: (cell, row) => {
          console.log('bener', row.jurnal_status)
          return <div>
            <Button
              color="success"
              className="btn-sm"
              onClick={() => { this.updateStatus(row) }}
            >
              {/* {row == 'Published' ? 'Unpublish' : 'Publish'} */}
              {row.jurnal_status == 'Rejected' ? 'Unpublish' : row.jurnal_status == 'Published' ? 'Unpublish' : 'Publish'}
            </Button>
            &nbsp;
            <Button
              color="primary"
              className="btn-sm"
              onClick={(e) => console.log("isinya => ", row.jurnal_title)}
            >
              Review
            </Button>
            &nbsp;
            <Button
              hidden={row.jurnal_status == 'Rejected' ? true : false}
              color="danger"
              className="btn-sm"
              onClick={(e) => this.reject(row)}
            >
              Reject
            </Button>
          </div>
        }
      }];

    // const defaultSorted = [{
    //   dataField: 'user_name',
    //   order: 'asc'
    // }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.listJurnal.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.listJurnal).length }];




    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Jurnal & Tata Laksana | Ruang Ilmiah</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Jurnal & Tata Laksana" breadcrumbItem="Verifikasi Jurnal" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='user_name'
                      columns={columns}
                      data={this.state.listJurnal}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='user_name'
                          columns={columns}
                          data={this.state.listJurnal}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <Input
                                    // id="focusAfterClose"
                                    onChange={this.handleselect}
                                    // onChange= {(e) => console.log(e.target.value) }
                                    type="select"
                                  >
                                    <option value="Unpublished">
                                      Unpublished
                                    </option>
                                    <option value="Published">
                                      Published
                                    </option>
                                    <option value="Rejected">
                                      Rejected
                                    </option>
                                  </Input>

                                  {/* <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <i className="bx bx-search-alt search-icon" />
                                      <Input
                                        id="exampleSelect"
                                        name="select"
                                        type="select"
                                        onChange={this.handleselect}
                                      >
                                        <option value="Published">
                                          Publish
                                        </option>
                                        <option value="Unpublished">
                                          Unpublish
                                        </option>
                                      </Input>
                                    </div>
                                  </div> */}
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"user_name"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      // defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {this.state.alert ?
            <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
            </SweetAlert> : ''
          }
        </div>
      </React.Fragment>
    )
  }
}

export default moderasiJurnal
import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
// Modal, ModalHeader, ModalBody, ModalFooter
import MetaTags from 'react-meta-tags'
import moment from 'moment'
import PropTypes from 'prop-types';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';


import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import SweetAlert from 'react-bootstrap-sweetalert';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
// import PropTypes from 'prop-types';
// import { withRouter } from 'react-router'
import { Link } from "react-router-dom";


import httprequester from '../../lib/httprequester'

class masterorganizer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      listOrganisasi: [],
      hasilEdit: null,
      hasilEditId: 0,
      formUbah: false,
      formDelete: false
    }

  }

  static propTypes = {
    history: PropTypes.object.isRequired
  }

  getOrganisasi = () => {
    let body = {
      "admin_type": "organisasi"
    }

    httprequester.postnobody('organisasi/getAll', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            listOrganisasi: response.data.detailMessage
          })
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  componentDidMount() {
    this.getOrganisasi()
  }

  direct = (id) => {
    let objx = this.state.listOrganisasi.find(i => i.org_id == id)
    this.props.history.push(`/update-organisasi/${id}`, { ...objx })
  }

  deletePopUp = (id) => {
    let pencarian = this.state.listOrganisasi.find(i => i.org_id == id)
    this.setState({
      hasilEditId: id,
      hasilEdit: pencarian.org_name,
      formDelete: true
    })
  }

  deleteMolekul = () => {
    let body = {
      id: this.state.hasilEditId,
      org_name: this.state.hasilEdit
    }

    this.setState({
      loading: true,
      formDelete: false
    })

    httprequester.postnobody('organisasi/delete', body, {
      success: async function (response) {
        // if (response.data.message == "success") {
        this.setState({
          message: response.data.detailMessage,
          alert: true,
          loading: false,
          nama: '',
          validation: false
        })
        this.getOrganisasi()
        // }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
    console.log(body)
  }

  render() {

    const columns = [{
      dataField: 'org_name',
      text: 'Organisasi',
      sort: true,
    }, {
      dataField: 'org_address',
      text: 'Alamat',
      sort: true
    }, {
      dataField: 'admin_name',
      text: 'Username',
      sort: true
    }, {
      dataField: 'org_website',
      text: 'Website',
      sort: true
    }, {
      dataField: 'org_pic',
      text: 'PIC',
      sort: true
    }, {
      dataField: 'org_id',
      text: 'Aksi',
      sort: true,
      formatter: (row) => {
        // console.log('bener', row)
        return <div>
          <Button
            color="success"
            className="btn-sm"
            onClick={(e) => { this.direct(row) }}
          >
            Ubah
          </Button>
          &nbsp;
          <Button color="danger" className="btn-sm"
            onClick={(e) => this.deletePopUp(row)}
          >
            Hapus
          </Button>
        </div>
      }
    }];

    const defaultSorted = [{
      dataField: 'org_name',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.listOrganisasi.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.listOrganisasi).length }];




    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Organizer | Ruang Ilmiah</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Organisasi" breadcrumbItem="Master Organisasi" />
            {
              (this.state.formDelete) ?
                <SweetAlert
                  title={"Hapus Organisasi " + this.state.hasilEdit}
                  onConfirm={(e) => this.deleteMolekul(e)}
                  onCancel={() => this.setState({ formDelete: false })}
                  showCancel={true}
                /> :
                null
            }
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='org_name'
                      columns={columns}
                      data={this.state.listOrganisasi}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='org_name'
                          columns={columns}
                          data={this.state.listOrganisasi}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mb-2">
                                <div className="d-flex justify-content-end">
                                  <Link to="/tambah-organisasi">
                                    <Button color="danger" className="btn">Tambah Organisasi</Button>
                                  </Link>
                                </div>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"org_name"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <br />
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        {this.state.loading ? (
          <SweetAlert title="menghubungkan ke server" showConfirm={false} >
          </SweetAlert>
        ) : null}
      </React.Fragment>
    )
  }
}

export default masterorganizer
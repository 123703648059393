import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Card,
    CardTitle,
    CardBody,
    Form,
    Table,
    CardText,
    CardHeader,
    CardFooter,
    CardImg,
    CardGroup,
    CardSubtitle
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'

import "../../assets/scss/datatables.scss"
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link, withRouter } from "react-router-dom"

class DetailPertanyaan extends Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        match: PropTypes.number.isRequired
    }

    constructor(props) {
        super(props);
        this.fileUploads = createRef();
        this.state = {
            openModal: false,
            cme_id: '',
            cme_detail_uat_1_id: '',
            listCME: [],
            listDetailCME: [],
            detailPertanyaan: '',
            detailOption1: '',
            detailOption2: '',
            detailOption3: '',
            detailOption4: '',
            detailOption5: '',
            detailJawaban: '',
            option1_upload: '',
            option2_upload: '',
            option3_upload: '',
            option4_upload: '',
            option5_upload: '',
            option_jawaban: '',
            pertanyaan_upload: '',
            prosess: false,
            hasilEdit: null,
            show: false,
            message: '',
            formDelete: false,
            formPertanyaan: false,
            thumbnail_pertanyaan: '',
            text_pertanyaan: '',
            old_jawaban: '',
            file_pertanyaan: '',
            type_key: '',
            formJawaban: false,
            formJawabanThumb: false,
            formSetJawaban: false
        };
    }

    componentDidMount() {
        // console.log("ID => ", this.props.match.params.cme_id)
        // this.getAdmin(this.props.match.params.admin_id);
        console.log(this.props.match.params.id)
        this.setState({
            cme_id: this.props.match.params.id
        })
        this.getDetailPertanyaan(this.props.match.params.id);
    }

    back = () => {
        this.props.history.push(`/cme-online`)
    }

    handleUploadOption1 = async (e) => {
        this.setState({
            option1_upload: e.target.files[0]
        })
    }

    handleUploadOption2 = async (e) => {
        this.setState({
            option2_upload: e.target.files[0]
        })
    }

    handleUploadOption3 = async (e) => {
        this.setState({
            option3_upload: e.target.files[0]
        })
    }

    handleUploadOption4 = async (e) => {
        this.setState({
            option4_upload: e.target.files[0]
        })
    }

    handleUploadOption5 = async (e) => {
        this.setState({
            option5_upload: e.target.files[0]
        })
    }

    handleUploadJawaban = async (e) => {
        this.setState({
            option_jawaban: e.target.files[0]
        })
    }

    handleUploadOption = async (e) => {
        this.setState({
            pertanyaan_upload: e.target.files[0]
        })
    }

    updatePertanyaanPopUp(v) {
        this.setState({
            formPertanyaan: true,
            thumbnail_pertanyaan: v.cme_detail_uat_1_thumbnail,
            text_pertanyaan: v.cme_pertanyaan,
            fileUploads: '',
            cme_detail_uat_1_id: v.cme_detail_uat_1_id,
            type_key: 'cme_pertanyaan',
            file_pertanyaan: ''
        })
    }

    setJawabanPopUp(v) {
        this.setState({
            type_key: 'cme_jawaban',
            cme_detail_uat_1_id: v.id,
            text_pertanyaan: v.jawaban,
            old_jawaban: v.jawaban,
            formSetJawaban: true
        })
    }

    setJawaban = () => {
        let body = {
            cme_detail_uat_1_id: this.state.cme_detail_uat_1_id,
            option: this.state.text_pertanyaan,
            type: this.state.type_key,
            old_option: this.state.old_jawaban,
            cme_id: this.state.cme_id
        }

        this.setState({
            formSetJawaban: false
        })

        httprequester.postnobody('cme/update_option', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        message: "Sukses Menyimpan Jawaban",
                        show: true
                    })
                    setTimeout(
                        () => this.getDetailPertanyaan(this.state.cme_id),
                        1000
                    );

                    // }, 2000);
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    updateJawaban = () => {
        let body = {
            cme_detail_uat_1_id: this.state.cme_detail_uat_1_id,
            option: this.state.text_pertanyaan,
            type: this.state.type_key,
            old_option: this.state.old_jawaban,
            cme_id: this.state.cme_id
        }

        this.setState({
            formJawaban: false
        })

        httprequester.postnobody('cme/update_option', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        message: "Sukses Menyimpan Opsi Jawaban",
                        show: true
                    })
                    setTimeout(
                        () => this.getDetailPertanyaan(this.state.cme_id),
                        1000
                    );

                    // }, 2000);
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    handleUploadGambar = async (e) => {
        // console.log(e.target.files[0])
        this.setState({
            file_pertanyaan: e.target.files[0]
        })
    }

    updatePertanyaan = () => {
        let body = {
            type: this.state.type_key,
            cme_detail_uat_1_id: this.state.cme_detail_uat_1_id,
            fileUploads: this.state.file_pertanyaan,
            text: this.state.text_pertanyaan
        }

        this.setState({
            formPertanyaan: false
        })

        let formData = new FormData();
        formData.append("cme_detail_uat_1_id", body.cme_detail_uat_1_id);
        formData.append("cme_pertanyaan", body.text);
        formData.append("myfile", body.fileUploads);

        httprequester.postupload('cme/update_pertanyaan', formData, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        message: "Sukses Menyimpan Pertanyaan",
                        show: true
                    })
                    setTimeout(
                        () => this.getDetailPertanyaan(this.state.cme_id),
                        1000
                    );

                    // }, 2000);
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    updateJawaban = () => {
        let body = {
            cme_detail_uat_1_id: this.state.cme_detail_uat_1_id,
            option: this.state.text_pertanyaan,
            type: this.state.type_key,
            old_option: this.state.old_jawaban,
            cme_id: this.state.cme_id
        }

        this.setState({
            formJawaban: false
        })

        httprequester.postnobody('cme/update_option', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        message: "Sukses Menyimpan Opsi Jawaban",
                        show: true
                    })
                    setTimeout(
                        () => this.getDetailPertanyaan(this.state.cme_id),
                        1000
                    );

                    // }, 2000);
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    updateJawabanPopUp(v) {
        this.setState({
            formJawaban: true,
            text_pertanyaan: v.jawaban,
            cme_detail_uat_1_id: v.id,
            type_key: v.type,
            old_jawaban: v.jawaban
        })
    }

    clearKonten = () => {
        this.setState({
            openModal: false,
            detailPertanyaan: '',
            detailOption1: '',
            detailOption2: '',
            detailOption3: '',
            detailOption4: '',
            detailOption5: '',
            detailJawaban: '',
            option1_upload: '',
            option2_upload: '',
            option3_upload: '',
            option4_upload: '',
            option5_upload: '',
            option_jawaban: '',
            pertanyaan_upload: '',
        });
    }

    getDetailPertanyaan = (id) => {

        let body = {
            "cme_id": id
        }

        httprequester.postnobody('cme/get_detail', body, {
            success: async function (response) {
                if (response.data.message == "success") {

                    // console.log("response cme => ", response.data.detail.datarows);

                    let data = []
                    let dataDetail = []

                    response.data.header.datarows.forEach(async (v, key) => {
                        await data.push({
                            cme_id: v[0],
                            cme_status: v[1],
                            created_at: v[2],
                            cme_detail_uat_1_id: v[5],
                            created_by: v[4],
                            cme_detail_uat_1_id: v[5],
                            cme_detail_uat_1_thumbnail: v[6],
                            cme_jawaban: v[7],
                            cme_option_2: v[8],
                            cme_option_3: v[9],
                            cme_option_4: v[10],
                            cme_option_5: v[11],
                            cme_option_1: v[12],
                            cme_pertanyaan: v[13]
                        })
                    })

                    response.data.detail.datarows.forEach(async (v, key) => {
                        await dataDetail.push({
                            cme_id: v[0],
                            cme_status: v[1],
                            created_at: v[2],
                            cme_detail_uat_1_id: v[5],
                            created_by: v[4],
                            cme_detail_uat_1_id: v[5],
                            cme_detail_uat_1_thumbnail: v[6],
                            cme_jawaban: v[7],
                            cme_option_2: v[8],
                            cme_option_3: v[9],
                            cme_option_4: v[10],
                            cme_option_5: v[11],
                            cme_option_1: v[12],
                            cme_pertanyaan: v[13]
                        })
                    })

                    // console.log("dataresp cme => ", data);
                    this.setState({
                        // prosess:false,
                        listCME: data,
                        listDetailCME: dataDetail
                    })
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    save = () => {

        let formData = new FormData();
        formData.append("cme_id", this.state.cme_id);
        formData.append("cme_pertanyaan", this.state.detailPertanyaan);
        formData.append("myfile", this.state.pertanyaan_upload);
        formData.append("cme_option_1", this.state.detailOption1);
        formData.append("cme_option_2", this.state.detailOption2);
        formData.append("cme_option_3", this.state.detailOption3);
        formData.append("cme_option_4", this.state.detailOption4);
        formData.append("cme_option_5", this.state.detailOption5);
        formData.append("cme_jawaban", this.state.detailJawaban);
        formData.append("myfile1", this.state.option1_upload);
        formData.append("myfile2", this.state.option2_upload);
        formData.append("myfile3", this.state.option3_upload);
        formData.append("myfile4", this.state.option4_upload);
        formData.append("myfile5", this.state.option5_upload);
        formData.append("myfile6", this.state.option_jawaban);

        httprequester.postupload('cme/save_detail', formData, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        message: "Sukses Menyimpan Pertanyaan",
                        show: true,
                        detailPertanyaan:'',
                        pertanyaan_upload:'',
                        detailOption1:'',
                        detailOption2:'',
                        detailOption3:'',
                        detailOption4:'',
                        detailOption5:'',
                        detailJawaban:'',
                        option1_upload:'',
                        option2_upload:'',
                        option3_upload:'',
                        option4_upload:'',
                        option5_upload:'',
                        option_jawaban:''              
                    })
                    setTimeout(
                        () => this.getDetailPertanyaan(this.state.cme_id),
                        1000
                    );

                    // }, 2000);
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    deletePopup(cme_detail_uat_1_id, cme_id) {
        this.setState({
            cme_detail_uat_1_id: cme_detail_uat_1_id,
            cme_id: cme_id,
            formDelete: true
        })
    }

    delete = () => {
        let body = {
            cme_id: this.state.cme_id,
            cme_detail_uat_1_id: this.state.cme_detail_uat_1_id,
            cme_status: "delete"
        }
        this.setState({
            formDelete: false
        })
        httprequester.postnobody('cme/delete_pertanyaan', body, {
            success: async function (response) {
                // console.log('data', response.data.message);
                if (response.data.message == "success") {
                    this.setState({
                        message: response.data.message,
                        show: true
                    })
                    setTimeout(
                        () => this.getDetailPertanyaan(this.state.cme_id),
                        1000
                    );
                }
                // console.log(response)
            }.bind(this),
            error: function (err) {
                console.log(err)
            }.bind(this)

        })
    }

    reload = () => {
        // window.location.reload()
        this.setState({
            show: false
        })
    }

    render() {

        const products = this.state.listDetailCME

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Detail Pertanyaan CME Online</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="CME Online" breadcrumbItem="Detail Pertanyaan CME Online" />

                        {this.state.formPertanyaan ? (
                            <SweetAlert

                                title="Ubah Pertanyaan"
                                onConfirm={() => this.updatePertanyaan()}
                                onCancel={() => this.setState({ formPertanyaan: false })}
                                showCancel={true}
                            >

                                {(renderProps) => (
                                    <form>
                                        <hr />
                                        <textarea
                                            cols={10}
                                            ref={renderProps.setAutoFocusInputRef}
                                            className="form-control"
                                            value={this.state.text_pertanyaan}
                                            onChange={(e) => this.setState({ text_pertanyaan: e.target.value })}
                                            placeholder={'Pertanyaan'}
                                        />
                                        <br />
                                        {
                                            (this.state.thumbnail_pertanyaan !== "") ?
                                                <div>
                                                    <p>*Gunakan Upload Gambar Hanya Bila Ada Perubahan</p>
                                                    <img src={this.state.thumbnail_pertanyaan} width={"100%"} />
                                                </div>
                                                : null
                                        }


                                        <input
                                            type={'file'}
                                            className="form-control"
                                            // value={this.state.lastName}
                                            onChange={this.handleUploadGambar}
                                            placeholder={'Last name'}
                                        />
                                        <hr />

                                    </form>


                                )}
                            </SweetAlert>
                        ) : null}

                        {this.state.formJawaban ? (
                            <SweetAlert

                                title="Ubah Opsi Jawaban"
                                onConfirm={() => this.updateJawaban()}
                                onCancel={() => this.setState({ formJawaban: false })}
                                showCancel={true}
                            >

                                {(renderProps) => (
                                    <form>
                                        <hr />
                                        <textarea
                                            cols={10}
                                            ref={renderProps.setAutoFocusInputRef}
                                            className="form-control"
                                            value={this.state.text_pertanyaan}
                                            onChange={(e) => this.setState({ text_pertanyaan: e.target.value })}
                                            placeholder={'Ubah Option Jawaban'}
                                        />

                                    </form>


                                )}
                            </SweetAlert>
                        ) : null}

                        {
                            (this.state.formDelete) ?
                                <SweetAlert
                                    title={"Hapus Pertanyaan"}
                                    onConfirm={(e) => this.delete(e)}
                                    onCancel={() => this.setState({ formDelete: false })}
                                    showCancel={true}
                                /> :
                                null
                        }

                        {
                            (this.state.formSetJawaban) ?
                                <SweetAlert
                                    title={"Pilih Jawaban"}
                                    onConfirm={(e) => this.setJawaban()}
                                    onCancel={() => this.setState({ formSetJawaban: false })}
                                    showCancel={true}
                                >
                                    {`Pilih ${this.state.text_pertanyaan} Sebagai Jawaban`}
                                </SweetAlert> :
                                null
                        }
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Pertanyaan
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmePertanyaan"
                                                        type="input"
                                                        placeholder="Input Pertanyaan"
                                                        onChange={(e) => this.setState({ detailPertanyaan: e.target.value })}
                                                        value={this.state.detailPertanyaan}
                                                    />
                                                    <br />
                                                    <div className="mb-3">
                                                        <Input
                                                            id="cmeFileVideo"
                                                            name="file"
                                                            type="file"
                                                            accept=".jpg,.jpeg,.png"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadOption}
                                                        />
                                                    </div>
                                                </Col>
                                            </div>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Pilihan Jawaban A</Label>
                                                        <Input
                                                            id="cmeOption1"
                                                            type="input"
                                                            placeholder="Input Pilihan Jawaban A"
                                                            disabled={this.state.option1_upload}
                                                            onChange={(e) => this.setState({ detailOption1: e.target.value })}
                                                            value={this.state.detailOption1}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Input
                                                            id="cmeFileVideo"
                                                            disabled={this.state.detailOption1}
                                                            name="file"
                                                            type="file"
                                                            accept=".jpg,.jpeg,.png"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadOption1}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Pilihan Jawaban B</Label>
                                                        <Input
                                                            id="cmeOption2"
                                                            type="input"
                                                            placeholder="Input Pilihan Jawaban B"
                                                            disabled={this.state.option2_upload}
                                                            onChange={(e) => this.setState({ detailOption2: e.target.value })}
                                                            value={this.state.detailOption2}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Input
                                                            id="cmeFileVideo"
                                                            disabled={this.state.detailOption2}
                                                            name="file"
                                                            type="file"
                                                            accept=".jpg,.jpeg,.png"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadOption2}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Pilihan Jawaban C</Label>
                                                        <Input
                                                            id="cmeOption3"
                                                            type="input"
                                                            placeholder="Input Pilihan Jawaban B"
                                                            disabled={this.state.option3_upload}
                                                            onChange={(e) => this.setState({ detailOption3: e.target.value })}
                                                            value={this.state.detailOption3}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Input
                                                            id="cmeFileVideo"
                                                            name="file"
                                                            disabled={this.state.detailOption3}
                                                            type="file"
                                                            accept=".jpg,.jpeg,.png"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadOption3}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Pilihan Jawaban D</Label>
                                                        <Input
                                                            id="cmeOption4"
                                                            type="input"
                                                            placeholder="Input Pilihan Jawaban D"
                                                            disabled={this.state.option4_upload}
                                                            onChange={(e) => this.setState({ detailOption4: e.target.value })}
                                                            value={this.state.detailOption4}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Input
                                                            id="cmeFileVideo"
                                                            disabled={this.state.detailOption4}
                                                            name="file"
                                                            type="file"
                                                            accept=".jpg,.jpeg,.png"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadOption4}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Pilihan Jawaban E</Label>
                                                        <Input
                                                            id="cmeOption5"
                                                            type="input"
                                                            placeholder="Input Pilihan Jawaban E"
                                                            disabled={this.state.option5_upload}
                                                            onChange={(e) => this.setState({ detailOption5: e.target.value })}
                                                            value={this.state.detailOption5}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Input
                                                            id="cmeFileVideo"
                                                            name="file"
                                                            disabled={this.state.detailOption5}
                                                            type="file"
                                                            accept=".jpg,.jpeg,.png"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadOption5}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Jawaban
                                                </Label>
                                                <Col sm={9}>
                                                    <Input
                                                        id="cmeJawaban"
                                                        type="input"
                                                        placeholder="Input Jawaban"
                                                        disabled={this.state.option_jawaban}
                                                        onChange={(e) => this.setState({ detailJawaban: e.target.value })}
                                                        value={this.state.detailJawaban}
                                                    />
                                                    <br />
                                                    <div className="mb-3">
                                                        <Input
                                                            id="cmeFileVideo"
                                                            name="file"
                                                            disabled={this.state.detailJawaban}
                                                            type="file"
                                                            accept=".jpg,.jpeg,.png"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadJawaban}
                                                        />
                                                    </div>
                                                </Col>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" color="primary" className="btn btn-primary w-md" onClick={() => this.save()}>
                                        Submit Detail Pertanyaan CME
                                    </Button>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4">Detail Pertanyaan CME Online</CardTitle>
                                        {products && products.map((v, key) => {
                                            return <Card key={key}>
                                                <CardHeader>
                                                    {v.cme_detail_uat_1_id}
                                                </CardHeader>
                                                <CardBody>
                                                    {
                                                        (v.cme_detail_uat_1_thumbnail) ?
                                                            <CardImg
                                                                alt="Card image cap"
                                                                src={v.cme_detail_uat_1_thumbnail}
                                                                top
                                                                width="10%"
                                                                style={{ width: '30%' }}
                                                            // height={"150px"}
                                                            /> : null

                                                    }

                                                    <br />
                                                    <CardTitle tag="h5">
                                                        Pertanyaan:
                                                        <br /><br />
                                                        {v.cme_pertanyaan}
                                                    </CardTitle>

                                                    <br />
                                                    <br />
                                                    <CardGroup>

                                                        <Card>
                                                            {
                                                                (v.cme_option_1) ?
                                                                    (v.cme_option_1.includes("http")) ?
                                                                        <div>
                                                                            <CardImg
                                                                                alt="Card image cap"
                                                                                src={v.cme_option_1}
                                                                                top
                                                                                width="100%"
                                                                                style={{ width: '40%' }}
                                                                            />
                                                                            <br />
                                                                            <br />
                                                                            <Button color="info">
                                                                                Ubah Option 1
                                                                            </Button>
                                                                            <br />
                                                                        </div>
                                                                        :
                                                                        <CardBody>
                                                                            <CardTitle tag="h5">
                                                                                Option 1
                                                                            </CardTitle>
                                                                            <CardText>
                                                                                {v.cme_option_1}
                                                                            </CardText>
                                                                            <Button onClick={() => this.updateJawabanPopUp({
                                                                                jawaban: v.cme_option_1,
                                                                                id: v.cme_detail_uat_1_id,
                                                                                type: 'cme_option_1'
                                                                            })} color="info">
                                                                                Ubah Option 1
                                                                            </Button>
                                                                            <br />
                                                                            <Button onClick={() => this.setJawabanPopUp({
                                                                                jawaban: v.cme_option_1,
                                                                                id: v.cme_detail_uat_1_id,
                                                                                type: 'cme_jawaban'
                                                                            })} color="success">
                                                                                Pilih Jawaban
                                                                            </Button>
                                                                        </CardBody>
                                                                    : null
                                                            }

                                                        </Card>
                                                        <Card>
                                                            {
                                                                (v.cme_option_2) ?
                                                                    (v.cme_option_2.includes("http")) ?
                                                                        <div>
                                                                            <CardImg
                                                                                alt="Card image cap"
                                                                                src={v.cme_option_2}
                                                                                top
                                                                                width="100%"
                                                                                style={{ width: '40%' }}
                                                                            />
                                                                            <br />
                                                                            <br />
                                                                            <Button color="info">
                                                                                Ubah Option 2
                                                                            </Button>
                                                                            <br />
                                                                        </div>
                                                                        :
                                                                        <CardBody>
                                                                            <CardTitle tag="h5">
                                                                                Option 2
                                                                            </CardTitle>
                                                                            <CardText>
                                                                                {v.cme_option_2}
                                                                            </CardText>
                                                                            <Button onClick={() => this.updateJawabanPopUp({
                                                                                jawaban: v.cme_option_2,
                                                                                id: v.cme_detail_uat_1_id,
                                                                                type: 'cme_option_2'
                                                                            })} color="info">
                                                                                Ubah Option 2
                                                                            </Button>
                                                                            <br />
                                                                            <Button onClick={() => this.setJawabanPopUp({
                                                                                jawaban: v.cme_option_2,
                                                                                id: v.cme_detail_uat_1_id,
                                                                                type: 'cme_jawaban'
                                                                            })} color="success">
                                                                                Pilih Jawaban
                                                                            </Button>
                                                                        </CardBody>
                                                                    : null
                                                            }

                                                        </Card>
                                                        <Card>
                                                            {
                                                                (v.cme_option_3) ?
                                                                    (v.cme_option_3.includes("http")) ?
                                                                        <div>
                                                                            <CardImg
                                                                                alt="Card image cap"
                                                                                src={v.cme_option_3}
                                                                                top
                                                                                width="100%"
                                                                                style={{ width: '40%' }}
                                                                            />
                                                                            <br />
                                                                            <br />
                                                                            <Button color="info">
                                                                                Ubah Option 3
                                                                            </Button>
                                                                            <br />
                                                                        </div> :
                                                                        <CardBody>
                                                                            <CardTitle tag="h5">
                                                                                Option 3
                                                                            </CardTitle>
                                                                            <CardText>
                                                                                {v.cme_option_3}
                                                                            </CardText>
                                                                            <Button onClick={() => this.updateJawabanPopUp({
                                                                                jawaban: v.cme_option_3,
                                                                                id: v.cme_detail_uat_1_id,
                                                                                type: 'cme_option_3'
                                                                            })} color="info">
                                                                                Ubah Option 3
                                                                            </Button>
                                                                            <br />
                                                                            <Button onClick={() => this.setJawabanPopUp({
                                                                                jawaban: v.cme_option_3,
                                                                                id: v.cme_detail_uat_1_id,
                                                                                type: 'cme_jawaban'
                                                                            })} color="success">
                                                                                Pilih Jawaban
                                                                            </Button>
                                                                        </CardBody>
                                                                    : null
                                                            }

                                                        </Card>
                                                        <Card>
                                                            {
                                                                (v.cme_option_4) ?
                                                                    (v.cme_option_4.includes("http")) ?
                                                                        <div>
                                                                            <CardImg
                                                                                alt="Card image cap"
                                                                                src={v.cme_option_4}
                                                                                top
                                                                                width="100%"
                                                                                style={{ width: '40%' }}
                                                                            />
                                                                            <br />
                                                                            <br />
                                                                            <Button color="info">
                                                                                Ubah Option 4
                                                                            </Button>
                                                                            <br />
                                                                        </div> :
                                                                        <CardBody>
                                                                            <CardTitle tag="h5">
                                                                                Option 4
                                                                            </CardTitle>
                                                                            <CardText>
                                                                                {v.cme_option_4}
                                                                            </CardText>
                                                                            <Button onClick={() => this.updateJawabanPopUp({
                                                                                jawaban: v.cme_option_4,
                                                                                id: v.cme_detail_uat_1_id,
                                                                                type: 'cme_option_4'
                                                                            })} color="info">
                                                                                Ubah Option 4
                                                                            </Button>
                                                                            <br />
                                                                            <Button onClick={() => this.setJawabanPopUp({
                                                                                jawaban: v.cme_option_4,
                                                                                id: v.cme_detail_uat_1_id,
                                                                                type: 'cme_jawaban'
                                                                            })} color="success">
                                                                                Pilih Jawaban
                                                                            </Button>
                                                                        </CardBody>
                                                                    : null
                                                            }

                                                        </Card>
                                                        <Card>
                                                            {
                                                                (v.cme_option_5) ?
                                                                    (v.cme_option_5.includes("http")) ?
                                                                        <div>
                                                                            <CardImg
                                                                                alt="Card image cap"
                                                                                src={v.cme_option_5}
                                                                                top
                                                                                width="100%"
                                                                                style={{ width: '40%' }}
                                                                            />
                                                                            <br />
                                                                            <br />
                                                                            <Button color="info">
                                                                                Ubah Option 5
                                                                            </Button>
                                                                            <br />
                                                                        </div> :
                                                                        <CardBody>
                                                                            <CardTitle tag="h5">
                                                                                Option 5
                                                                            </CardTitle>
                                                                            <CardText>
                                                                                {v.cme_option_5}
                                                                            </CardText>
                                                                            <Button onClick={() => this.updateJawabanPopUp({
                                                                                jawaban: v.cme_option_5,
                                                                                id: v.cme_detail_uat_1_id,
                                                                                type: 'cme_option_5'
                                                                            })} color="info">
                                                                                Ubah Option 5
                                                                            </Button>
                                                                            <br />
                                                                            <Button onClick={() => this.setJawabanPopUp({
                                                                                jawaban: v.cme_option_5,
                                                                                id: v.cme_detail_uat_1_id,
                                                                                type: 'cme_jawaban'
                                                                            })} color="success">
                                                                                Pilih Jawaban
                                                                            </Button>
                                                                        </CardBody>
                                                                    : null
                                                            }

                                                        </Card>
                                                    </CardGroup>
                                                    <br /><br />
                                                    <Button color="info"
                                                        size="sm"
                                                        onClick={() => this.updatePertanyaanPopUp(v)}
                                                    // onClick={() => this.deletePopup(v.cme_detail_uat_1_id, v.cme_id)}
                                                    >
                                                        Ubah Pertanyaan
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button color="danger"
                                                        size="sm"
                                                        // onClick={() => this.update(v.cme_detail_uat_1_id, v.cme_id)}
                                                        onClick={() => this.deletePopup(v.cme_detail_uat_1_id, v.cme_id)}
                                                    >
                                                        Hapus Pertanyaan
                                                    </Button>
                                                </CardBody>
                                                <CardFooter>
                                                    <h5>
                                                        Jawaban:
                                                    </h5>
                                                    <br />
                                                    <br />

                                                    {
                                                        (v.cme_jawaban) ?
                                                            (v.cme_jawaban.includes("http")) ?
                                                                <CardImg
                                                                    alt="Card image cap"
                                                                    src={v.cme_jawaban}
                                                                    top
                                                                    width="100%"
                                                                    style={{ width: '40%' }}
                                                                />
                                                                : <h5>{v.cme_jawaban}
                                                                </h5>
                                                            : null
                                                    }

                                                </CardFooter>
                                            </Card>
                                        })}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>



                    {this.state.show ? (
                        <SweetAlert success title={this.state.message} onConfirm={() => this.reload()}>
                        </SweetAlert>
                    ) : null}
                </div>
            </React.Fragment >
        )
    }
}

DetailPertanyaan.propTypes = {
    match: PropTypes.any,
}

export default withRouter(DetailPertanyaan);
import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from 'react-router'


class addOrganisasi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basic: false,
            org_name: '',
            alamat: '',
            username: '',
            password: '',
            website: '',
            pic: '',
            message: '',
            status: false
        };
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    onCancel = () => {
        this.setState({
            basic: false
        })
    }

    direct = () => {
        this.setState({
            basic: false
        })
        this.props.history.push('/master-organizer')
    }

    saveOrganisasi = () => {
        let body = {
            "username": this.state.username,
            "password": this.state.password,
            "org_name": this.state.org_name,
            "org_website": this.state.website,
            "org_address": this.state.alamat,
            "org_pic": this.state.pic
        }
            httprequester.postnobody('organisasi/save', body, {
                success: async function (response) {
                    // console.log("res => ", response)
                    if (response.data.message == "success") {
                        this.setState({
                            message: response.data.detailMessage,
                            basic: true,
                            org_name: '',
                            alamat: '',
                            username: '',
                            password: '',
                            website: '',
                            pic: ''
                        })
                    }
                }.bind(this),
                error: function (err) {
                    console.log("error", err)
                }.bind(this)

            })
    }

    render() {
        // console.log("hacep => ", this.state.articleKonten)
        // const { editorState } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Organisasi || Ruang Ilmiah</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Organisasi" breadcrumbItem="Tambah Organisasi" />

                        {this.state.basic ? (
                            <SweetAlert success title={this.state.message} onConfirm={this.direct}>
                            </SweetAlert> 
                        ) : null}

                        <AvForm onValidSubmit={this.saveOrganisasi}>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="row mb-4">
                                                    <AvField
                                                        name="organisasi"
                                                        label="Nama Organisasi"
                                                        type="text"
                                                        onChange={(e) => this.setState({ org_name: e.target.value })}
                                                        value={this.state.org_name} required />
                                                </div>
                                                <div className="row mb-4">
                                                    <AvField
                                                        name="alamat"
                                                        label="Alamat"
                                                        type="text"
                                                        onChange={(e) => this.setState({ alamat: e.target.value })}
                                                        value={this.state.alamat}
                                                        required />
                                                </div>
                                                <div className="row mb-4">
                                                    <AvField
                                                        name="username"
                                                        label="Username"
                                                        type="text"
                                                        onChange={(e) => this.setState({ username: e.target.value })}
                                                        value={this.state.username}
                                                        required />
                                                </div>
                                                <div className="row mb-4">
                                                    <AvField
                                                        name="password"
                                                        label="Password"
                                                        type="text"
                                                        onChange={(e) => this.setState({ password: e.target.value })}
                                                        value={this.state.password}
                                                        required />
                                                </div>
                                                <div className="row mb-4">
                                                    <AvField
                                                        name="website"
                                                        label="Website"
                                                        type="text"
                                                        onChange={(e) => this.setState({ website: e.target.value })}
                                                        value={this.state.website}
                                                        required />
                                                </div>
                                                <div className="row mb-4">
                                                    <AvField
                                                        name="pic"
                                                        label="PIC"
                                                        type="text"
                                                        onChange={(e) => this.setState({ pic: e.target.value })}
                                                        value={this.state.pic}
                                                        required />
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 10 }}>
                                <Col xs="12" md="12">
                                    <Card>
                                        <Button color="danger" >
                                            Simpan Organisasi
                                        </Button>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default withRouter(addOrganisasi);

import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Popover,
    PopoverHeader,
    PopoverBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
    FormGroup,
    Input,
    Label,
    List
} from 'reactstrap';
// import { Link } from "react-router-dom";
import { withRouter } from 'react-router'
import {
    Container,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";

import httprequester from '../lib/httprequester'

import SweetAlert from 'react-bootstrap-sweetalert';
import PropTypes from 'prop-types';


class Artikel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filemanager: [],
            nama: '',
            alert: false,
            message: '',
            loading: false,
            validation:false
        };
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    getDataFileManager = () => {
        httprequester.get('filemanager/getall_folder', {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        filemanager: response.data.detailMessage,
                    })
                }
                console.log(response)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    handletext = (e) => {
        this.setState({
            nama: e.target.value,
            validation: false
        })
    }

    handlecancel = (e) => {
        this.setState({
            alert: false,
            validation: false
        })
    }

    save = () => {
        let body = {
            folder_name: this.state.nama
        }

        this.setState({
            loading: true
        })

        if(this.state.nama){
            this.setState({
                validation:false
            })
            httprequester.posturlnoadmin('filemanager/save_folder', body, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        this.setState({
                            message: response.data.detailMessage,
                            alert: true,
                            loading: false,
                            nama: ''
                        })
                        this.getDataFileManager()
                    }
                    // console.log(response)
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        } else {
            this.setState({
                validation:true,
                loading:false
            })
        }
    }

    direct = (id) => {
        this.props.history.push(`filemanager/upload?folder_id=${id}`)
    }

    componentDidMount() {
        this.getDataFileManager()
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>File Manager | Ruang Ilmiah</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="File Manajer" breadcrumbItem="File Manajer" />

                        <Row>
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label for="focusAfterClose">
                                        Nama Folder
                                    </Label>
                                    <Input
                                        className="mx-2"
                                        id="focusAfterClose"
                                        onChange={this.handletext}
                                        value={this.state.nama}
                                        type="input"
                                        placeholder="Input Nama Folder"
                                    >
                                    </Input>
                                    {this.state.validation ? <p style={{ color: 'red' }}>nama is required</p> : '' }
                                    <br />
                                    <Button color="danger" onClick={this.save}>
                                        {this.state.loading ? 'Loading...' : 'Simpan'}
                                    </Button>
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                                <Row xs="3">
                                    {/*  style={{ backgroundColor: 'yellow' }} */}
                                    {this.state.filemanager && this.state.filemanager.map((v, key) => {
                                        return <Col key={key}>
                                            <Card
                                                id="Popover1"
                                                onClick={() => {this.direct(v.folder_id)}}
                                                type="button"
                                            >
                                                <CardBody>
                                                    <CardTitle tag="h5">
                                                        <i className="bx bxs-folder" />
                                                    </CardTitle>
                                                    <CardSubtitle
                                                        className="mb-2 text-muted"
                                                        tag="h6"
                                                    >
                                                        {v.folder_name}
                                                    </CardSubtitle>
                                                    <CardText>

                                                    </CardText>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    })}
                                </Row>
                            </Col>
                        </Row>

                        {this.state.alert ?
                            <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
                            </SweetAlert> : ''
                        }

                        {/* <div> */}
                        {/* <Row>
                            <Col xs="12" md="12">
                                <Editor
                                    editorState={this.editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange}
                                />
                            </Col>
                        </Row> */}
                        {/* </div> */}
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(Artikel);

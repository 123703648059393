import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import moment from 'moment'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

//urlAPI
import UrlApi from "../../helpers/UrlAPI"

import httprequester from '../../lib/httprequester'

class listEvent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      listEvent: [],
      alert: false,
      message: '',
      id: null,
      status: "Published"
    }

  }

  getEvent = () => {
    
    let body = {
      "status": this.state.status,
      "search": "",
      "organisasi": "",
      "lokasi": "",
      "start": moment().format("YYYY-MM-DD"),
      "end": "",
      "page": 1,
      "limit": 10000,
      "urutan": "event_id DESC"
    }

    httprequester.postnobody('event/getall', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          // console.log('event',response.data.result.data.dataRows)
          let data = []
          response.data.result.data.dataRows.filter(v => v[9]===this.state.status).forEach(async (v, key) => {
            await data.push({
              event_date_end: moment(v[0]).format('DD MMM YYYY hh:mm:ss'),
              event_thumbnail: v[1],
              event_location: v[2],
              event_spesialisasi: v[3],
              event_description: v[4],
              created_at: v[5],
              event_type: v[6],
              event_date: moment(v[7]).format("DD MMM YYYY hh:mm:ss"),
              event_link: v[8],
              event_status: v[9],
              event_organization: v[10],
              created_by: v[11],
              myfile: v[12],
              event_id:v[13],
              event_name:v[14]
            })
          })
          this.setState({
            listEvent: data
          })
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  handlecancel = () => {
    this.setState({
      alert: false
    })
    this.getEvent()
  }

  approve = (row) => {
    console.log('id',row)
    let pencarian = this.state.listEvent.find(i => i.event_id == row.event_id)
    console.log('pencarian',pencarian)

    if (row.event_status == 'Published'){
      let body = {
        "event_id": pencarian.event_id,
        "event_status": "Unpublished"
      }

      httprequester.postnobody('event/update', body, {
        success: async function (response) {
          if (response.data.message == "success") {
            this.setState({
              message: response.data.detailMessage,
              alert: true
            })
            await this.getEvent()
          }
          // console.log(response)
        }.bind(this),
        error: function (err) {

        }.bind(this)

      })
    } else if (row.event_status == "Unpublished"){
      let body = {
        "event_id": pencarian.event_id,
        "event_status": "Published"
      }

      httprequester.postnobody('event/update', body, {
        success: async function (response) {
          if (response.data.message == "success") {
            this.setState({
              message: response.data.detailMessage,
              alert: true
            })
           await this.getEvent()
          }
          // console.log(response)
        }.bind(this),
        error: function (err) {

        }.bind(this)

      }) 
    }
  }

  reject = (id) => {
    // console.log('id', this.state.id)

    let pencarian = this.state.listSTRUser.find(i => i.str_id == id)
    // console.log('pencarian',pencarian)
    let body = {
      "user_id": pencarian.user_id,
      "user_name": pencarian.user_name,
      "str_id": id,
      "action": "Reject"
    }

    httprequester.postnobody('admin/moderating_str', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true,
          })
          this.getSTRUser()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  handleselect = (e) => {

    let body = {
      "status": e.target.value,
      "search": "",
      "organisasi": "",
      "lokasi": "",
      "start":  moment().format("YYYY-MM-DD"),
      "end": "",
      "page": 1,
      "limit": 10000,
      "urutan": "event_id desc"
    }

    httprequester.postnobody('event/getall', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          // console.log('event',response.data.result.data.dataRows)
          let data = []
          response.data.result.data.dataRows.filter(v => v[9]===e.target.value).forEach(async (v, key) => {
            await data.push({
              event_date_end: moment(v[0]).format('DD MMM YYYY hh:mm:ss'),
              event_thumbnail: v[1],
              event_location: v[2],
              event_spesialisasi: v[3],
              event_description: v[4],
              created_at: v[5],
              event_type: v[6],
              event_date: moment(v[7]).format('DD MMM YYYY hh:mm:ss'),
              event_link: v[8],
              event_status: v[9],
              event_organization: v[10],
              created_by: v[11],
              myfile: v[12],
              event_id: v[13],
              event_name: v[14]
            })
          })
          this.setState({
            listEvent: data,
            status: e.target.value
          })
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  componentDidMount() {
    this.getEvent()
  }

  render() {

    const columns = [{
      dataField: 'event_thumbnail',
      text: 'Foto',
      sort: true,
      formatter: (row) => {
        return <div>
          <img
            src={row}
            alt="sertifikat-img"
            title="sertifikat-img"
            className="avatar-md"
            onClick={() => window.open(row, "_blank")}
          // onClick={(e) => { this.Open(row) }}
          />
        </div>
      }
    }, {
      dataField: 'event_organization',
      text: 'Nama Organisasi',
      sort: true
    }, {
      dataField: 'event_name',
      text: 'Nama Acara',
      sort: true
    }, {
      dataField: 'event_link',
      text: 'Website',
      sort: true
    }, {
      dataField: 'event_date',
      text: 'Tanggal Mulai',
      sort: true
      }, {
      dataField: 'event_date_end',
        text: 'Tanggal Akhir',
        sort: true
      }, {
      dataField: 'event_location',
      text: 'Alamat',
      sort: true
    }, {
      dataField: 'event_spesialisasi',
      text: 'Spesialisasi',
      sort: true
    }, {
      dataField: 'event_status',
      text: 'Status',
      sort: true
    }, {
      dataField: 'created_by',
      text: 'User',
      sort: true
    }, {
      dataField: 'event_status',
      text: 'Aksi',
      sort: true,
      formatter: (cell,row) => {
        // console.log('bener', row)
        return <div>
          <Button
            color="danger"
            className="btn-sm"
            onClick={() => { this.approve(row) }}
          >
            {row.event_status == 'Published' ? 'Unpublish' : 'Publish'}
          </Button>
          &nbsp;
          {/* <Button 
            color="primary" 
            className="btn-sm" 
            // onClick={(e) => this.reject(row)}
          >
            Review
          </Button> */}
        </div>
      }
    }];

    const defaultSorted = [{
      dataField: 'event_date',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.listEvent.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.listEvent).length }];


    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Moderasi Acara | Ruang Ilmiah</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Acara" breadcrumbItem="Moderasi Acara" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='event_name'
                      columns={columns}
                      data={this.state.listEvent}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='event_name'
                          columns={columns}
                          data={this.state.listEvent}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <Input
                                    // id="focusAfterClose"
                                    onChange={this.handleselect}
                                    // onChange= {(e) => console.log(e.target.value) }
                                    type="select"
                                  >
                                    <option value="Published">
                                      Published
                                    </option>
                                    <option value="Unpublished">
                                      Unpublished
                                    </option>
                                  </Input>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"user_name"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>
                              <br />
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {this.state.alert ?
            <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
            </SweetAlert> : ''
          }
        </div>
      </React.Fragment>
    )
  }
}

export default listEvent
import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead, withItem } from 'react-bootstrap-typeahead';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactHtmlParser from 'react-html-parser';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { CSVLink, CSVDownload } from "react-csv";

import { Editor } from '@tinymce/tinymce-react';
import Kategori from "pages/KategoriTags/kategori";
import moment from "moment";

class DetailPercakapan extends Component {
    constructor(props) {
        super(props);
        this.ref = createRef();
        this.state = {
            basic: false,
            ListDetailPercakapan: [],
            tanya_topik: '',
            tanya_content_id: '',
            tanya_narasumber: '',
            tanya_type: [],
            tanya_penyakit: '',
            tanya_spesialisai: '',
            tanya_summary: '',
            tanya_thumbnail: '',
            tanya_kuota: '',
            tanya_periode_start: '',
            tanya_periode_end: '',
            periode_id: 0,
            quota: 0,
            message: '',
            tanya_summary_edit: '',
            id: 0,
            conv_id:0,
            conv_status:0,
            role: '',
            prosess: false,
            formEditQuota: false,
            formmodalstatus:false,
            dataCSV: [],
            pending:{},
            notif:''
        };
    }

    componentDidMount() {
        this.getListTanyaAhli()
        this.getDetailPercakapan()
        // this.getListTanyaAhliPeriode()
    }

    getDetailPercakapan = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        let body = {
            "tanya_id": routerSplitter[5],
            "user_id": routerSplitter[6],
            "limit": 10000
        }

        httprequester.postarticle('ahli/list-perpeserta', body, {
            success: async function (response) {

                // console.log("resLOV => ", response.data.detailMessage.data[0])
                if (response.data.message == "success") {
                    let data = response.data.detailMessage.data.filter(a => a.tanya_conv_status != '4' )
                    this.setState({
                        ListDetailPercakapan: data
                    })

                    let args=[];
                    data.map((v,i) => {
                        let obj = {
                            nama: v.tanya_name,
                            percakapan: v.tanya_conv_text,
                            role: v.tanya_conv_role
                        }
                        args.push(obj)
                    })

                    this.setState({
                        dataCSV: args
                    })
                    
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.setState({
            basic: false,
            prosess: false,
            formmodalstatus: false,
            // notif:''
        })
        this.getListTanyaAhli()
        this.getDetailPercakapan()
        // this.getListTanyaAhliPeriode()
    }




    getListTanyaAhli = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let body = {
            "tanya_id": routerSplitter[5]
        }

        // console.log("body =>", body)
        httprequester.postarticle('ahli/get-detail', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                if (response.data.message == "success") {
                    this.setState({
                        tanya_topik: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][15] : '',
                        tanya_periode_start: response.data.detailMessage.datarows.length > 0 ? moment(response.data.detailMessage.datarows[0][3]).format('DD MMM YYYY') : '',
                        tanya_kuota: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][1] : '',
                        tanya_periode_end: response.data.detailMessage.datarows.length > 0 ? moment(response.data.detailMessage.datarows[0][11]).format('DD MMM YYYY') : ''
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }


    directDetail = (row) => {
        this.props.history.push(`/tanya-ahli/list-percakapan/detail/${row.periode_id}`)

    }

    openModalStatus = (data, type, notif) => {
        // console.log('data',data);
        this.setState({
            formmodalstatus:true,
            conv_id: data.tanya_conv_id,
            conv_status: type,
            pending:data,
            notif:notif
        })
    }

    updateStatus = () => {
        // console.log('data',this.state.pending.created_at);
        let body = {
            "conv_id": this.state.conv_id,
            "conv_status": this.state.conv_status,
            "flag": "update",
            "created_at":this.state.pending.created_at,
            // "narasumber_id": this.state.pending.narasumber_id,
            "periode_id": this.state.pending.periode_id,
            "tanya_conv_attachment": this.state.pending.tanya_conv_attachment,
            "tanya_conv_role": this.state.pending.tanya_conv_role,
            "tanya_conv_text": this.state.pending.tanya_conv_text,
            "tanya_id": this.state.pending.tanya_id,
            "tanya_name": this.state.pending.tanya_name,
            "user_id": this.state.pending.user_id,
        }
        // console.log("body =>", body)
        httprequester.postarticle('ahli/update-perpeserta', body, {
            success: async function (response) {
                // console.log("res => ", response)
                this.getListTanyaAhli()
                this.getDetailPercakapan()
                this.back()
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        formmodalstatus: false,
                        message: this.state.notif ? 'Percakapan Berhasil Diperbarui dan Berhasil Mengirim Notifikasi': response.data.detailMessage,
                        notif:''
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    openModal = (data) => {
        this.setState({
            prosess: true,
            tanya_summary_edit: data.tanya_conv_text,
            id: data.tanya_conv_id,
            role: ''
        })
    }

    openModalAdmin = (role) => {
        this.setState({
            prosess: true,
            tanya_summary_edit: '',
            tanya_summary: '',
            role: role
        })
    }

    save = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        if (this.state.role == 'admin') {
            let body = {
                "periode_id": this.state.ListDetailPercakapan[0].periode_id,
                "tanya_id": routerSplitter[5],
                "user_id": routerSplitter[6],
                "pertanyaan": this.state.tanya_summary,
                "role": "admin",
                "name": "Admin"
            }

            httprequester.postnobody('ahli/insert-chat-perpeserta', body, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        this.setState({
                            message: response.data.detailMessage,
                            basic: true,
                            prosess: false,
                            role: ''
                        })
                        this.getDetailPercakapan()
                        this.getListTanyaAhli()
                    }
                    // console.log(response)
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        } else {
            let body = {
                "conv_id": this.state.id,
                "conv_text": this.state.tanya_summary,
                "flag": "intercept"
            }

            httprequester.postnobody('ahli/update-perpeserta', body, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        this.setState({
                            message: response.data.detailMessage,
                            basic: true,
                            prosess: false,
                            role: ''
                        })
                        this.getDetailPercakapan()
                        this.getListTanyaAhli()
                    }
                    // console.log(response)
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        }

    }

    directSEO = () => {
        let url = localStorage.getItem('url')
        let hasil = url.split('/')
        // console.log('hasil',hasil);
        this.props.history.push(`/${hasil[3]}/list-percakapan/${hasil[5]}/${hasil[6]}/${hasil[7]}`)
    }

    handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
        this.setState({
            tanya_summary: e.target.getContent()
        })
    }

    splitpdf = (data) =>  {
        let routerSplitter = data.split("/");
        // console.log('data', routerSplitter);
        return routerSplitter[4]

    }

    klikpdf = (url) => {
        window.open(url, '_blank', 'noreferrer')
    }

    render() {
        const { SearchBar } = Search;
        console.log('this',this.state.ListDetailPercakapan);
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Detail Percakapan</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Tanya Ahli" breadcrumbItem="Detail Percakapan" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title={this.state.message}
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.formmodalstatus ? (
                            <SweetAlert
                                warning
                                showCancel
                                confirmBtnText="Ya"
                                cancelBtnText="Tidak"
                                confirmBtnBsStyle="danger"
                                title="Apakah Anda Yakin ?"
                                onConfirm={ this.updateStatus} 
                                onCancel={() => this.back()}
                                focusCancelBtn
                            >
                            </SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert confirmBtnText="Simpan"
                                cancelBtnText="Tidak" showCancel onConfirm={() => this.save()} onCancel={() => this.back()} >
                                <Form method="post">
                                    <Editor
                                        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                        initialValue={this.state.tanya_summary_edit}
                                        content={this.state.tanya_summary}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                                'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent lineheight | ' +
                                                'removeformat link',
                                            content_style: 'body { font-family:Poppins,Arial,sans-serif; }',
                                            line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 2',
                                        }}
                                        onChange={this.handleEditorChange}
                                    />
                                </Form>
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col lg={12}>
                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO(this.state.tanya_id, "tanya_ahli") }}> Kembali</button>
                                <br />
                                <br />
                                <Card>
                                    <CardBody>
                                        <Form id="form-periode">
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button
                                                    color="primary"
                                                    className="btn-sm"
                                                    onClick={(e) => { this.openModalAdmin('admin') }}
                                                >
                                                    Admin
                                                </Button>
                                                <Button
                                                    color="success"
                                                    className="btn-sm"
                                                >
                                                    <CSVLink data={this.state.dataCSV} style={{ color: 'white' }}>Download CSV</CSVLink>
                                                </Button>
                                                
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Nama Tanya Ahli : {this.state.tanya_topik} </Label>
                                                <br />
                                                <Label htmlFor="formrow-firstname-Input">Periode : {this.state.tanya_periode_start} - {this.state.tanya_periode_end}</Label>
                                                <br />
                                                <Label htmlFor="formrow-firstname-Input">Quota : {this.state.tanya_kuota}</Label>
                                                <br />
                                                <Label htmlFor="formrow-firstname-Input">Penanya : {this.state.ListDetailPercakapan.length > 0 ? this.state.ListDetailPercakapan[0].tanya_name : ''}</Label>
                                            </div>
                                        </Form>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" md="12">
                                {this.state.ListDetailPercakapan.map((v, key) => {
                                    return <Card key={key}>
                                        <CardBody>
                                            <p><b>{v.tanya_name}</b></p>
                                            <p>{ReactHtmlParser(v.tanya_conv_text)}</p>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {v.tanya_conv_status != '3' && v.tanya_conv_role == 'peserta' ? <div>
                                                    <Button
                                                        color="warning"
                                                        className="btn-sm"
                                                        onClick={(e) => { this.openModalStatus(v, 5, 'notif')}}
                                                    >
                                                        Tidak Perlu Dijawab & Kirim Notifikasi
                                                    </Button>
                                                    &nbsp;
                                                    <Button
                                                        color="success"
                                                        className="btn-sm"
                                                        onClick={(e) => { this.openModalStatus(v, 3) }}
                                                    >
                                                        Tidak Perlu Dijawab
                                                    </Button>
                                                    &nbsp;
                                                    <Button
                                                        color="danger"
                                                        className="btn-sm"
                                                        onClick={(e) => { this.openModalStatus(v, 4) }}
                                                    >
                                                        Hapus
                                                    </Button>
                                                    &nbsp;
                                                    <Button
                                                        color="info"
                                                        className="btn-sm"
                                                        onClick={(e) => { this.openModal(v) }}
                                                    >
                                                        Ubah
                                                    </Button>
                                                   
                                                </div> : v.tanya_conv_status == '3' && v.tanya_conv_role == 'peserta' ? <div>
                                                    <Button
                                                        color="success"
                                                        className="btn-sm"
                                                            onClick={(e) => { this.openModalStatus(v, 1) }}
                                                    >
                                                        Perlu Dijawab
                                                    </Button>
                                                    &nbsp;
                                                    <Button
                                                        color="danger"
                                                        className="btn-sm"
                                                            onClick={(e) => { this.openModalStatus(v, 4) }}
                                                    >
                                                        Hapus
                                                    </Button>
                                                    &nbsp;
                                                    <Button
                                                        color="info"
                                                        className="btn-sm"
                                                        onClick={(e) => { this.openModal(v) }}
                                                    >
                                                        Ubah
                                                    </Button>

                                                    </div> : v.tanya_conv_status != '3' && v.tanya_conv_role == 'admin' ? <div>
                                                            <Button
                                                                color="danger"
                                                                className="btn-sm"
                                                                onClick={(e) => { this.openModalStatus(v, 4) }}
                                                            >
                                                                Hapus
                                                            </Button>
                                                            &nbsp;
                                                            <Button
                                                                color="info"
                                                                className="btn-sm"
                                                                onClick={(e) => { this.openModal(v) }}
                                                            >
                                                                Ubah
                                                            </Button>
                                                    </div> : <div>
                                                
                                                </div> }

                                            </div>
                                            <br />
                                            {v.tanya_conv_attachment !== null ? <div>
                                                <Button
                                                    color="dark"
                                                    className="btn-sm"
                                                    onClick={(e) => {this.klikpdf(v.tanya_conv_attachment) }}
                                                >
                                                    <i className="fas fa-file-pdf"></i>&nbsp;{this.splitpdf(decodeURI(v.tanya_conv_attachment))}
                                                </Button>                
                                            </div> : ''}
                                            
                                        </CardBody>
                                    </Card>

                                })}

                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default DetailPercakapan;

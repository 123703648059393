import React, { Component } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import MetaTags from 'react-meta-tags'
import moment from 'moment'
import PropTypes from 'prop-types'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import InserRewardModal from "./InsertRewardModal";

import "../Master/datatables.scss";
import styles from './style/rewardPointDetail.module.scss';

import httprequester from '../../lib/httprequester'

class RewardPointDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            listRewardByUser: [],
            loading: false,
            showAlertPopup: false,
            popupMessage: '',
            popupAlertType: null,
            isShowDeletePopup: false,
            rewardIdWillBeDeleted: null,
            isShowFormReward: false,
            userDetailData: null,
        }
    }

    componentDidMount() {
        this.getListReward();
    }

    getListReward = () => {
        const {
            match: { params },
        } = this.props

        this.setState({
            loading: true
        });
        
        let userRewardParams = {
            userId: params.userId,
        }

        httprequester.getqueryparam('admin/reward/getListReward', userRewardParams, {
            success: async function (response) {
                if (response.data.status) {
                    this.setState({
                        listRewardByUser : response.data.listRewardByUser,
                        loading: false,
                        userDetailData: response.data.userDetailData
                    })
                } else {
                    this.setState({
                        listRewardByUser : [],
                        loading: false,
                        userDetailData: null
                    });
                }
            }.bind(this),
            error: function (err) {
                console.log("Error get user reward:\n", err)

                this.setState({
                    listRewardByUser : [],
                    loading: false,
                    userDetailData: null
                });
            }.bind(this)

        })
    }

    handleBackButton = () => {
        let stateValue = this.props.location.state;

        if(stateValue) {
            this.props.history.push(`/reward-point`, stateValue);
        } else {
            this.props.history.goBack();
        }
    }

    handleDeleteReward = () => {
        this.setState({
            loading: true,
            isShowDeletePopup: false
        });

        let deleteRewardObject = {
            id: this.state.rewardIdWillBeDeleted,
        }

        httprequester.post('admin/reward/deleteReward', deleteRewardObject, {
            success: async function (response) {
                if (response.data.status) {
                    this.getListReward();

                    this.setState({
                        rewardIdWillBeDeleted: null
                    })
                } else {
                    this.setState({
                        loading: false,
                        rewardIdWillBeDeleted: null
                    });
                }
            }.bind(this),
            error: function (err) {
                console.log("Error when delete specific reward:\n", err);

                this.setState({
                    loading: false,
                    rewardIdWillBeDeleted: null
                });
            }.bind(this)

        })
    }

    handleClickButtonAddRedeem = () => {
        if((this.state?.userDetailData?.total_points ?? 0) <= 0) {
            this.setState({
                showAlertPopup: true,
                popupMessage: `A new redemption can't be created because the user doesn't have any points`,
                popupAlertType: 'error'
            });
        } else {
            this.setState({
                isShowFormReward: true
            });
        }
        
    }

    formatDate(dateString) {
        const formattedDate = moment(dateString).format('DD MMMM YYYY HH:mm');

        if (!moment(dateString).isValid()) {
            return 'Invalid Date';
        }

        return formattedDate;
    }

    handleCloseFormCreate = () => {
        this.setState({
            isShowFormReward: false
        })
    }

    postNewReward = (formField) => {
        let newRewardObject = {
            userId: this.props.match?.params?.userId ?? null,
            pointInfo: formField.pointInfo ?? '',
            point: formField.point ?? 0
        }

        httprequester.post('admin/reward/insertReward', newRewardObject, {
            success: async function (response) {
                if (response.data.status) {
                    this.getListReward();
                                
                    this.setState({
                        showAlertPopup: true,
                        popupMessage: 'Successfully created new redeem',
                        popupAlertType: 'success'
                    })
                } else {
                    this.getListReward();
                                
                    this.setState({
                        loading: false,
                        showAlertPopup: true,
                        popupMessage: response.data.message ?? 'Failed to create new redeem',
                        popupAlertType: 'error'
                    });
                }
            }.bind(this),
            error: function (err) {
                console.log("Error when create new redeem:\n", err);

                this.getListReward();
                                
                this.setState({
                    loading: false,
                    showAlertPopup: true,
                    popupMessage: 'Failed to create new redeem',
                    popupAlertType: 'error'
                });
            }.bind(this)

        })
    }

    render() {
        const columns = [
            {
                dataField: 'point_info',
                text: 'Point Info',
                sort: true
            },
            {
                dataField: 'point',
                text: 'Point',
                sort: false
            },
            {
                dataField: 'created_by',
                text: 'Created By',
                sort: true
            },
            {
                dataField: 'created_time',
                text: 'Created Time',
                sort: false,
                formatter: (row) => {
                    let dateValue = this.formatDate(row);

                    return <div>
                        {dateValue}
                    </div>
                }
            },
            {
                dataField: 'id',
                text: 'Action',
                sort: false,
                formatter: (cell, row) => {
                    let { able_to_delete } = row;
                    return <div>
                        {able_to_delete 
                            ? <Button
                                color="danger"
                                className="btn-sm"
                                onClick={(e) => {
                                    this.setState({
                                        rewardIdWillBeDeleted: cell,
                                        isShowDeletePopup: true
                                    })
                                }}>
                                Delete
                            </Button>
                            : <></>
                        }
                    </div>
                }
            }
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.listRewardByUser.length,
            custom: true,
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>User Reward Point</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Halaman Utama" breadcrumbItem="User Reward Point"
                        />
                    </Container>
                    <div className="container-fluid">
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        {this.state.userDetailData && 
                                            <Row className={styles.userDetailContainer}>
                                                <Col md="9">
                                                    <div className={styles.userName}>{this.state.userDetailData.user_name}</div>
                                                    <div className={styles.userPoint}>Total Point: {this.state.userDetailData.total_points ?? 0}</div>
                                                </Col>
                                                {this.state.userDetailData && 
                                                    <Col 
                                                        md="3"
                                                        className={styles.addRedeemContainer}
                                                    >
                                                        <Button 
                                                            color="btn-sm btn btn-primary"
                                                            onClick={() => this.handleClickButtonAddRedeem()}
                                                            style={{
                                                                height: 'fit-content'
                                                            }}
                                                        >
                                                            Tambah Redeem
                                                        </Button>
                                                    </Col>
                                                }
                                            </Row>
                                        }
                                        {
                                            this.state.listRewardByUser.length > 0
                                            ? <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField='id'
                                                columns={columns}
                                                data={this.state.listRewardByUser}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField='id'
                                                        columns={columns}
                                                        data={this.state.listRewardByUser}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row>
                                                                    <Col xl="12" style={{marginBottom: '10px'}}>
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={"id"}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                            />

                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )
                                                        }
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                            : <Row className={styles.emptyResultContainer}>
                                                User tidak memiliki point
                                            </Row>
                                        }
                                        <Row>
                                            <Col md="2">
                                                <Button 
                                                    color="btn-sm btn btn-danger"
                                                    onClick={() => this.handleBackButton()}
                                                >
                                                    Kembali
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {this.state.showAlertPopup 
                        ? <SweetAlert 
                            type={this.state.popupAlertType}
                            title={this.state.popupMessage} 
                            onConfirm={() => {
                                this.getListReward();
                                this.setState({ 
                                    showAlertPopup: false,
                                    popupMessage: '',
                                    popupAlertType: null
                                })
                            }}
                        />
                        : <></>
                    }
                    {this.state.loading 
                        ? <SweetAlert title="Menghubungkan ke Server" showConfirm={false} onConfirm={() => {}}/>
                        : <></>
                    }
                    {this.state.isShowDeletePopup
                        ? <SweetAlert
                            title="Apakah anda yakin akan menghapus data ini?"
                            warning
                            showCancel
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="success"
                            confirmBtnText="Hapus"
                            cancelBtnText="Batal"
                            onConfirm={() =>
                                this.handleDeleteReward()
                            }
                            onCancel={() =>
                                this.setState({
                                    isShowDeletePopup: false,
                                    rewardIdWillBeDeleted: null
                                })
                            }
                        />
                        : null
                    }
                    {this.state.userDetailData && <InserRewardModal 
                        isOpen={this.state.isShowFormReward} 
                        userId={this.props.match?.params?.userId ?? ''}
                        userDetailData={this.state.userDetailData}
                        closeForm={() => this.handleCloseFormCreate()} 
                        postNewReward={(newRequestObject) => this.postNewReward(newRequestObject)}
                    />}
                </div>
            </React.Fragment>
        )
    }
}

RewardPointDetail.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}

export default RewardPointDetail;
import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import moment from 'moment'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"


import httprequester from '../../lib/httprequester'

class Tags extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      listKategori: [],
      listTags:[],
      alert: false,
      loading:false,
      message: '',
      namekategori:'',
      kategori_id:null,
      openmodal:false,
      id: null,
      nama:'',
      validation:false
    }

  }

  getKategoriTags = () => {
    httprequester.get('category/getall', {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            listKategori: response.data.detailMessage
          })
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  handlecancel = () => {
    this.setState({
      alert: false,
      validation:false
    })
  }

  save = () => {
  
    this.setState({
      loading:true
    })

    if(this.state.nama && this.state.kategori_id){
      this.setState({
        validation:false
      })
      let pencarian = this.state.listKategori.find(i => i.category_id == this.state.kategori_id)
      // console.log('ini',pencarian)

      let body = {
        "category_name": pencarian.category_name,
      }

      let bodytags = {
        "tags_name": this.state.nama,
        "category_id": this.state.kategori_id
      }

      httprequester.postnobody('category/save', body, {
        success: async function (response) {
          if (response.data.message == "success") {
            this.setState({
              message: response.data.detailMessage,
              alert: true,
              loading: false,
              nama: '',
              kategori_id: ''
            })
            this.getKategoriTags()
          }
          // console.log(response)
        }.bind(this),
        error: function (err) {

        }.bind(this)

      })

      httprequester.postnobody('category/save', bodytags, {
        success: async function (response) {
          if (response.data.message == "success") {
            // this.setState({
            //   message: response.data.detailMessage,
            //   alert: true,
            // })
            this.getKategoriTags()
          }
          // console.log(response)
        }.bind(this),
        error: function (err) {

        }.bind(this)

      })
    } else {
      this.setState({
        validation:true,
        loading:false
      })
    }
  }

  viewtags = (id) => {
    // console.log('id',id)
    let pencarian = this.state.listKategori.find(i => i.category_id == id)
    this.setState({
      openmodal:true,
      namekategori:pencarian.category_name
    })
    // console.log('pencarian',pencarian)
    let body = {
      category_id:id
    }

    httprequester.getqueryparam('tags/getall', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            listTags: response.data.detailMessage
          })
          // this.getSTRUser()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  reject = (id) => {
    // console.log('id', this.state.id)

    // let pencarian = this.state.listSTRUser.find(i => i.str_id == id)
    // console.log('pencarian',pencarian)
    let body = {
      "category_id": id,
      "category_status": "D"
    }

    httprequester.postnobody('category/update', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true,
          })
          this.getKategoriTags()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  componentDidMount() {
    this.getKategoriTags()
  }

  cancel = () => {
    this.setState({
      openmodal:false
    })
  }

  handleselect = (e) => {
    this.setState({
      kategori_id: e.target.value,
      validation:false
    })
    console.log('this', this.state.kategori_id)
  }

  handletext = (e) => {
    this.setState({
      nama: e.target.value,
      validation:false
    })
  }

  render() {

    const columnsTags = [{
      dataField: 'tags_name',
      text: 'Nama Tags',
      sort: true
    }]

    const columns = [{
      dataField: 'category_name',
      text: 'Nama Kategori',
      sort: true
    }, {
      dataField: 'category_id',
      text: 'Action',
      sort: true,
      formatter: (row) => {
        // console.log('bener', row)
        return <div>
          <Button
            color="primary"
            className="btn-sm"
            onClick={(e) => { this.viewtags(row) }}
          >
            View Tags
          </Button>
          &nbsp;
          <Button color="secondary" className="btn-sm" onClick={(e) => this.reject(row)}>
            Hapus
          </Button>
        </div>
      }
    }];

    const defaultSortedTags = [{
      dataField: 'tags_name',
      order: 'asc'
    }];

    const pageOptionsTags = {
      sizePerPage: 10,
      totalSize: this.state.listTags.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageListTags = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.listTags).length }];

    const defaultSorted = [{
      dataField: 'category_name',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.listKategori.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.listKategori).length }];




    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Tags | Ruang Ilmiah</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Tags" breadcrumbItem="Tags" />

            <Row>
              <Col xs="12" md="4">
                <Form>
                  <FormGroup>
                    <Label for="exampleSelect">
                      Kategori
                    </Label>
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={this.handleselect}
                      value={this.state.kategori_id}
                    >
                      <option>Choose</option>
                      {this.state.listKategori && this.state.listKategori.map((v,key) => {
                        return <option key={key} value={v.category_id}>
                          {v.category_name}
                        </option>
                      })}
                    </Input>
                  </FormGroup>
                  {this.state.validation ? <p style={{ color: 'red' }}>kategori is required</p> : ''}
                  <br />
                  <FormGroup>
                    <Label for="exampleText">
                      Nama Tags
                    </Label>
                    <Input
                      id="exampleText"
                      name="text"
                      onChange={this.handletext}
                      value={this.state.nama}
                      type="text"
                    />
                  </FormGroup>
                  {this.state.validation ? <p style={{ color: 'red' }}>nama tags file is required</p> : ''}
                  <br />
                  <Button onClick={this.save}>
                    {this.state.loading ? 'Loading...' : 'Submit'}
                  </Button>
                </Form>
              </Col>
            </Row>
            <br />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='category_name'
                      columns={columns}
                      data={this.state.listKategori}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='category_name'
                          columns={columns}
                          data={this.state.listKategori}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"user_name"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {this.state.alert ?
            <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
            </SweetAlert> : ''
          }

          <Modal
            isOpen={this.state.openmodal}
          >
            <ModalHeader isOpen={this.state.openmodal}>
              Katergori {this.state.namekategori}
            </ModalHeader>
            <ModalBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptionsTags)}
                keyField='tags_name'
                columns={columnsTags}
                data={this.state.listTags}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField='tags_name'
                    columns={columnsTags}
                    data={this.state.listTags}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>

                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"user_name"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSortedTags}
                                classes={
                                  "table align-middle table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />

                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>
                )
                }</PaginationProvider>
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.cancel}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default Tags
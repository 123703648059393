import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Editor } from '@tinymce/tinymce-react';
import Kategori from "pages/KategoriTags/kategori";
import moment from "moment";

class TambahTanyaAhliPeriode extends Component {
    constructor(props) {
        super(props);
        this.ref = createRef();
        this.state = {
            basic: false,
            listNarasumber: [],
            listPeriode: [],
            listType: [
                {
                    nama: 'Artikel',
                    value: 'artikel'
                },
                {
                    nama: 'Webcast',
                    value: 'webcast'
                },
                {
                    nama: 'Tindakan Medis Spesialistik',
                    value: 'tindakan'
                },
                {
                    nama: 'Jurnal',
                    value: 'jurnal'
                },
                {
                    nama: 'Tata Laksana',
                    value: 'tata'
                }
                // {
                //     nama: 'CME Online',
                //     value: 'cme'
                // },
                // {
                //     nama: 'Acara Ilmiah',
                //     value: 'event'
                // }
            ],
            tanya_topik: '',
            tanya_content_id: '',
            tanya_narasumber: '',
            tanya_type: [],
            tanya_penyakit: '',
            tanya_spesialisai: '',
            tanya_summary: '',
            tanya_thumbnail: '',
            tanya_summary: '',
            tanya_kuota: '',
            tanya_periode_start: '',
            tanya_periode_end: '',
            periode_id: 0,
            quota: 0,
            narasumber_id: 0,
            tanya_id: 0,
            dataEnd:'',
            date:moment().format('YYYY-MM-DD'),
            // hasilSpesialisasi:'',
            // hasilPenyakit:'',
            prosess: false,
            formEditQuota: false
        };
    }

    async componentDidMount() {
        await this.getListTanyaAhli(),
        await this.getListTanyaAhliPeriode()
        await this.getNarasumber()      
        // await this.cekperiode()
    }

    // cekperiodeakhir = () => {
    //     let router = window.location.href
    //     let routerSplitter = router.split("/");
    //     // routerSplitter[5]

    //     let body = {
    //         tanya_id: routerSplitter[5]
    //     }

    //     let periodeEnd

    //     httprequester.postarticle('ahli/get-periode', body, {
    //         success: async function (response) {

    //             // let cek = response.data.result.data
    //             if (response.data.message == "success") {
    //                 // let dateexist = moment(response.data.detailMessage[0].periode_end).format('YYYY-MM-DD')
    //                 // let datenow = moment().format('YYYY-MM-DD')
    //                 // if (datenow > dateexist) {
    //                 //     return 'y'
    //                 // } else {
    //                 //     return 'n'
    //                 // }

    //                 periodeEnd = response.data.detailMessage.length < 0 ?  response.data.detailMessage[0].periode_end : 0


    //             }
    //         }.bind(this),
    //         error: function (err) {
    //             console.log("error", err)
    //         }.bind(this)

    //     })

    //     console.log('ini', periodeEnd);
    // }

    getNarasumber = () => {
        httprequester.get('ahli/get-narasumber', {
            success: async function (response) {

                // console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listNarasumber: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.setState({
            basic: false,
            tanya_kuota: '',
            narasumber_id: 0,
            quota: 0,
            tanya_periode_start: '',
            tanya_periode_end: ''
        })
        this.getListTanyaAhli()
        this.getListTanyaAhliPeriode()
    }



    clearKonten = () => {
        this.setState({
            basic: false,
            editorState: EditorState.createEmpty(),
            jurnalJudul: '',
            jurnalPenulis: [],
            jurnalKonten: '',
            jurnalGambar: '',
            jurnalSpesialis: [],
            jurnalMolekul: [],
            jurnalPenyakit: []
        });
    }

    getListTanyaAhliPeriode = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        let body = {
            tanya_id: routerSplitter[5]
        }

        httprequester.postarticle('ahli/get-periode', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                if (response.data.message == "success") {
                    let data = []
                    response.data.detailMessage.forEach(async (v, key) => {
                        await data.push({
                            periode_id: v.periode_id,
                            periode_start: moment(v.periode_start).format('DD MMM YYYY'),
                            periode_end: moment(v.periode_end).format('DD MMM YYYY'),
                            quota: v.quota,
                            quota_left: v.quota_left,
                            quota_sisa: parseInt(v.quota) - parseInt(v.quota_left),
                            narasumber_id: v.narasumber_id
                        })
                    })
                    this.setState({
                        listPeriode: data,
                        dataEnd: moment(response.data.detailMessage[0].periode_end).format('YYYY-MM-DD')
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }


    getListTanyaAhli = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let body = {
            "tanya_id": routerSplitter[5]
        }

        // console.log("body =>", body)
        httprequester.postarticle('ahli/get-detail', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                if (response.data.message == "success") {
                    this.setState({
                        tanya_topik: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][15] : '',
                        tanya_narasumber: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][12] : '',
                        // tanya_periode_start: response.data.detailMessage.datarows.length > 0 ? moment(response.data.detailMessage.datarows[0][3]).format('YYYY-MM-DD') : '',
                        // tanya_periode_end: response.data.detailMessage.datarows.length > 0 ? moment(response.data.detailMessage.datarows[0][11]).format('YYYY-MM-DD') : ''
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveTanyaPeriode = () => {

        let router = window.location.href
        let routerSplitter = router.split("/");

        // console.log('start', this.state.tanya_periode_start);
        // console.log('end', this.state.tanya_periode_end);
        let hasil = this.state.listNarasumber.find(k => k.narasumber_name == this.state.tanya_narasumber)
        // console.log('id', hasil.narasumber_id);


        let body = {
            tanya_id: routerSplitter[5],
            tanya_periode_start: moment(this.state.tanya_periode_start).format('YYYY-MM-DD'),
            tanya_periode_end: moment(this.state.tanya_periode_end).format('YYYY-MM-DD'),
            tanya_kuota: this.state.tanya_kuota,
            tanya_status: routerSplitter[7],
            tanya_type: routerSplitter[6],
            narasumber_id: hasil.narasumber_id,
            periode_id: this.state.periode_id
        }
        // console.log("body =>", body)
        httprequester.postarticle('ahli/save-detail', body, {
            success: async function (response) {
                // console.log("res => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        basic: true
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    directSEO = () => {
        let url = localStorage.getItem('url')
        let hasil = url.split('/')
        // console.log('hasil',hasil);
        this.props.history.push(`/${hasil[3]}/${hasil[4]}/${hasil[5]}/${hasil[6]}/${hasil[7]}`)
    }

    save = () => {
        let slugLower = this.state.jurnalJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let formData = new FormData();
        formData.append("myfile", this.state.file);
        formData.append("jurnal_thumbnail", this.state.jurnalGambar);
        formData.append("jurnal_title", this.state.jurnalJudul);
        formData.append("jurnal_slug", slugRep);
        formData.append("jurnal_penulis", this.state.jurnalPenulis.toString());
        formData.append("jurnal_penyakit", this.state.jurnalPenyakit.toString());
        formData.append("jurnal_spesialis", this.state.jurnalSpesialis.toString());
        formData.append("jurnal_molekul", this.state.jurnalMolekul.toString());
        formData.append("jurnal_content", this.state.jurnalKonten)
        formData.append("jurnal_type", this.state.type)

        // console.log("formData => ", formData);

        this.setState({
            prosess: true
        })
        httprequester.postupload('jurnal/save', formData, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        prosess: false
                    })
                    this.fileUploads.current
                    // e.target.value = null;
                    // this.fileUploads.current.value
                    // this.getFileUpload()
                }
                // console.log('ini', this.fileUploads)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    editPeriode = (row) => {
        // console.log('row',row);
        let router = window.location.href
        let routerSplitter = router.split("/");
        this.setState({
            formEditQuota: true,
            periode_id: row.periode_id,
            narasumber_id: row.narasumber_id,
            tanya_periode_start: moment(row.periode_start).format('YYYY-MM-DD'),
            quota: row.quota,
            tanya_id: routerSplitter[5],
            tanya_periode_end: moment(row.periode_end).format('YYYY-MM-DD')
        })

    }

    // submitNPA = () => {
    //     let body = {
    //         npa_id,
    //         npa_name,
    //         npa_no,
    //         user_id
    //     }

    //     // console.log(body)
    //     this.setState({
    //         loading: true,
    //         formEditQuota: false
    //     })

    //     httprequester.postnobody('admin/npa', body, {
    //         success: async function (response) {
    //             if (response.data.message == "success") {
    //                 this.setState({
    //                     message: response.data.detailMessage,
    //                     alert: true,
    //                     loading: false,
    //                 })
    //                 // this.getUser()
    //             }
    //             // console.log(response)
    //         }.bind(this),
    //         error: function (err) {

    //         }.bind(this)

    //     })
    // }

    editQuota = () => {
        let body = {
            tanya_periode_end: this.state.tanya_periode_end,
            tanya_periode_start: this.state.tanya_periode_start,
            periode_id: this.state.periode_id,
            tanya_id: this.state.tanya_id,
            tanya_kuota: this.state.quota,
            narasumber_id: this.state.narasumber_id
        }

        // console.log(body)
        this.setState({
            loading: true,
            formEditQuota: false
        })

        httprequester.postnobody('ahli/save-detail', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        message: response.data.detailMessage,
                        basic: true,
                        loading: false,
                    })
                    // this.getUser()
                }
                // console.log(response)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    // cekperiode = () => {
    //     // console.log('data', this.state.dataEnd);
    //     return this.state.dataEnd
    // }

    render() {
        // console.log('periode', this.state.listPeriode);
        const columns = [{
            dataField: 'periode_start',
            text: 'Periode Mulai',
            sort: true
        }, {
            dataField: 'periode_end',
            text: 'Periode Akhir',
            sort: true
        }, {
            dataField: 'quota',
            text: 'Kuota',
            sort: true
        }, {
            dataField: 'quota_left',
            text: 'Kuota yang digunakan',
            sort: true
        },
        {
            dataField: 'periode_id',
            text: 'Action',
            sort: true,
            formatter: (cell, row, index) => {
                // console.log(cell, row, index)
                // this.setState({
                //     dateend:row.periode_end
                // })
                // let data = this.state.listPeriode.find(j => j.periode_id == row)
                return <div>
                    <Button
                        color="success"
                        className="btn-sm"
                        onClick={(e) => { this.editPeriode(row) }}>
                        Edit
                    </Button>
                </div>
            }
        }
        ];

        const defaultSorted = [{
            dataField: 'periode_id',
            order: 'asc'
        }];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.listPeriode.length, // replace later with size(customers),
            custom: true,
        }

        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Tanya Ahli Periode</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Tanya Ahli" breadcrumbItem="Tambah Tanya Ahli Periode" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Tanya Ahli Periode berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        {
                            (this.state.formEditQuota) ?
                                <SweetAlert
                                    title={"Form Edit Quota"}
                                    onConfirm={() => this.editQuota()}
                                    onCancel={() => this.setState({ formEditQuota: false })}
                                    showCancel={true}
                                    type={'controlled'}
                                // dependencies={[this.state.npa_name, this.state.npa_no]}
                                >
                                    {(renderProps) => (
                                        <form>
                                            <input
                                                type={'text'}
                                                ref={renderProps.setAutoFocusInputRef}
                                                className="form-control"
                                                value={this.state.quota}
                                                onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                onChange={(e) => this.setState({ quota: e.target.value })}
                                                placeholder={'Quota'}
                                            />
                                            <br />
                                            <div className="mb-3">
                                                <Input
                                                    id="end"
                                                    type="date"
                                                    placeholder="Periode Akhir"
                                                    onChange={(e) => this.setState({ tanya_periode_end: e.target.value })}
                                                    value={this.state.tanya_periode_end}
                                                />
                                            </div>
                                        </form>
                                    )}
                                </SweetAlert> : null
                        }

                        <Row>
                            <Col lg={12}>
                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO() }}> Kembali</button>
                                <br />
                                <br />
                                <Card>
                                    <CardBody>
                                        <Form id="form-periode">
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Nama Topik : {this.state.tanya_topik} </Label>
                                                <br />
                                                <Label htmlFor="formrow-firstname-Input">Narasumber : {this.state.tanya_narasumber}</Label>
                                            </div>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Periode Mulai</Label>
                                                        <Input
                                                            id="start"
                                                            type="date"
                                                            placeholder=""
                                                            onChange={(e) => this.setState({ tanya_periode_start: e.target.value })}
                                                        // value={this.state.tanya_periode_start}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">Periode Akhir</Label>
                                                        <Input
                                                            id="end"
                                                            type="date"
                                                            placeholder=""
                                                            onChange={(e) => this.setState({ tanya_periode_end: e.target.value })}
                                                        // value={this.state.tanya_periode_end}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputCity">Kuota</Label>
                                                        <Input
                                                            id="kuota"
                                                            type="input"
                                                            placeholder=""
                                                            onChange={(e) => this.setState({ tanya_kuota: e.target.value })}
                                                        // value={this.state.tanya_kuota}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </CardBody>
                                </Card>
                                <Row style={{ paddingTop: 10 }}>
                                    <Col xs="12" md="12">
                                        <Card>
                                            <Button type="submit" disabled={this.state.date > this.state.dataEnd ? false : true} className="btn btn-primary w-md" onClick={() => this.saveTanyaPeriode()}>
                                                Simpan
                                            </Button>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" md="12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField='periode_id'
                                            columns={columns}
                                            data={this.state.listPeriode}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='periode_id'
                                                    columns={columns}
                                                    data={this.state.listPeriode}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"certificate_name"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )
                                                    }
                                                </ToolkitProvider>
                                            )
                                            }</PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default TambahTanyaAhliPeriode;

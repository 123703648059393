import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
    FormGroup,
    Input,
    Label,
    Alert
} from 'reactstrap';
import {
    Container,
} from "reactstrap"
import queryString from 'query-string';

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';

import httprequester from '../lib/httprequester'

class Fileupload extends Component {
    constructor(props) {
        super(props)
        this.fileUploads = createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listFileUpload: [],
            nama:'',
            namaalert:'',
            file:null,
            alert:false,
            alertcopy: false,
            loading: false,
            message: '',
            afterupload:'',
            id: null,
            validation:false
        }

    }

    getFileUpload = () => {
        let search = window.location.search;
        let params = queryString.parse(search)

        let param = {
            "folder_id": params.folder_id
        }


        httprequester.getqueryparam('filemanager/getall_file', param, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        listFileUpload: response.data.detailMessage
                    })
                }
                // console.log(response)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    handletext = (e) => {
        this.setState({
            nama:e.target.value,
            validation:false
        })
    }

    handlecancel = () => {
        this.setState({
            alert: false,
            validation:false
        })
    }

    handlupload = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    save = () => {
        let search = window.location.search;
        let params = queryString.parse(search)

        let formData = new FormData();
        formData.append("myfile", this.state.file);
        formData.append("folder_id", params.folder_id);
        formData.append("file_name", this.state.nama);

        this.setState({
            loading:true
        })

        if (this.state.nama ){
            this.setState({
                validation:false
            })
            httprequester.postupload('filemanager/upload', formData, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        this.setState({
                            message: response.data.detailMessage,
                            alert: true,
                            loading: false,
                            nama: ''
                        })
                        this.fileUploads.current 
                        // e.target.value = null;
                        // this.fileUploads.current.value
                        this.getFileUpload()
                    }
                    console.log('ini', this.fileUploads)
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        } else {
            this.setState({
                loading:false,
                validation:true
            })
        }
    }

    delete = (data) => {
        console.log('data', data)

        let body = {
            "file_id": data.file_id,
            "file_name": data.file_name,
            "file_status": data.file_status == 'A' ? 'D' : 'A'
        }

        this.setState({
            loading:true
        })

        httprequester.deletenoadmin('filemanager/delete_file', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        message: response.data.detailMessage,
                        alert: true,
                        loading:false
                    })
                    this.getFileUpload()
                }
                // console.log(response)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    alertshow = (value,name) => {
        this.setState({
            namaalert:name,
            alertcopy:true
        })
        navigator.clipboard.writeText(value)
        // alert('sucess copy!')
        setTimeout(() => { 
            this.setState({
                alertcopy:false
            })
        }, 3000);
    }

    componentDidMount() {
        this.getFileUpload()
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>File Upload | Ruang Ilmiah</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="File Manajer" breadcrumbItem="File Upload" />

                        <Row>
                            <Col xs="12" md="4">
                                <Form>
                                    <FormGroup>
                                        <Label for="exampleText">
                                            Nama File
                                        </Label>
                                        <Input
                                            id="exampleText"
                                            name="text"
                                            onChange={this.handletext}
                                            value={this.state.nama}
                                            type="text"
                                        />
                                    </FormGroup>
                                    {this.state.validation ? <p style={{ color: 'red' }}>nama file is required</p> : ''}
                                    <br />
                                    <FormGroup>
                                        <Label for="exampleFile">
                                            File
                                        </Label>
                                        <br />
                                        <Input
                                            id="exampleFile"
                                            name="file"
                                            type="file"
                                            accept=".png,.jpg,.jpeg" 
                                            ref={this.fileUploads}
                                            onChange={this.handlupload}
                                        />
                                    </FormGroup>
                                    <p style={{fontSize:'11px'}}>Resolusi Gambar Harus 848 x 565</p> 
                                    {this.state.validation ? <p style={{ color: 'red' }}>file is required</p> :''}
                                    <br />                                
                                    <Button color='danger' onClick={this.save}>
                                        {this.state.loading ? 'Loading...' : 'Submit'}
                                    </Button>
                                </Form>

                            </Col>
                        </Row>
                        <br/>
                        {this.state.alertcopy ? <div>
                            <Alert
                                color="primary"
                                isOpen={this.state.alertcopy}
                            >
                                sucess copy url {this.state.namaalert}
                            </Alert>
                        </div> : ''}
                        <div>
                            <Row>
                                <Col xs="12" md="9">
                                    {/*  style={{ backgroundColor: 'red' }} */}
                                    <Row xs="3">
                                        {/*  style={{ backgroundColor: 'yellow' }} */}
                                        {this.state.listFileUpload && this.state.listFileUpload.map((v,key) => {
                                            return <Col key={key}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle tag="h5">
                                                            {v.file_name}
                                                        </CardTitle>
                                                        <CardImg
                                                            alt="Card image cap"
                                                            src={v.file_url}
                                                            top
                                                            width="100%"
                                                        />
                                                        <CardText>
                                                            
                                                        </CardText>
                                                        <Button color="primary" onClick={() => { this.alertshow(v.file_url, v.file_name)}}>
                                                            Copy Link
                                                        </Button>
                                                        &nbsp;&nbsp;
                                                        <Button color="danger" onClick={(e) => {this.delete(v)}}>
                                                            {this.state.loading ? 'Loading...' : 'Hapus'}
                                                        </Button>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                            {this.state.alert ?
                                <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
                                </SweetAlert> : ''
                            }
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Fileupload;

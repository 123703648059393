import React, { Component } from "react"
import { Alert, Row, Col, Card, CardBody, Button, Input } from "reactstrap"
// Modal, ModalHeader, ModalBody, ModalFooter
import MetaTags from 'react-meta-tags'
import PropTypes from 'prop-types';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Typeahead } from 'react-bootstrap-typeahead';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Datatable
import "../../assets/scss/datatables.scss"

import httprequester from '../../lib/httprequester'
import { Link, withRouter } from "react-router-dom"

import { GetIndexFromSchema } from '../../helpers/CommonHelper';

class ListCME extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            listAdmin: [],
            listCME: [],
            alert: false,
            openmodal: false,
            // modal: false,
            message: '',
            urlimage: '',
            id: null,
            confirm_both: false,
            statusCME: 'Published',
            alertcopy: false,
            namaalert: '',
            message: '',
            listSpesialisasi:[],
            hasilSpesialisasi:[]
        }
    }

    componentDidMount() {
        this.getListCME()
        this.getLov()
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    cari = () => {
        this.getListCME()
    }

    getListCME = () => {

        let body = {
            "spesialisasi": this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : '',
            "search": "",
            "status": this.state.statusCME,
            "page": 1,
            "limit": 10,
            "urutan": "created_at DESC"
        }

        // console.log("bodyreq cme => ", body)
        httprequester.postnobody('cme/get_header_adm', body, {
            success: async function (response) {
                if (response.data.message == "success") {

                    // console.log("response cme => ", response);

                    let data = []
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            cme_id: v[GetIndexFromSchema('cme_id', response.data.result.data.schema)],
                            cme_penulis: v[GetIndexFromSchema('cme_penulis', response.data.result.data.schema)],
                            cme_title: v[GetIndexFromSchema('cme_title', response.data.result.data.schema)],
                            cme_content: v[GetIndexFromSchema('cme_content', response.data.result.data.schema)],
                            created_at: v[GetIndexFromSchema('created_at', response.data.result.data.schema)],
                            cme_slug: v[GetIndexFromSchema('cme_slug', response.data.result.data.schema)],
                            cme_penyakit: v[GetIndexFromSchema('cme_penyakit', response.data.result.data.schema)],
                            cme_skp: v[GetIndexFromSchema('cme_skp', response.data.result.data.schema)],
                            cme_molekul: v[GetIndexFromSchema('cme_molekul', response.data.result.data.schema)],
                            cme_spesialis: v[GetIndexFromSchema('cme_spesialis', response.data.result.data.schema)],
                            cme_status: v[GetIndexFromSchema('cme_status', response.data.result.data.schema)],
                            cme_pdf: v[GetIndexFromSchema('cme_pdf', response.data.result.data.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.result.data.schema)],
                            cme_thumbnail: v[GetIndexFromSchema('cme_thumbnail', response.data.result.data.schema)],
                            cme_no_idi: v[GetIndexFromSchema('cme_no_idi', response.data.result.data.schema)],
                        });
                    })

                    // console.log("dataresp cme => ", data);
                    this.setState({
                        listCME: data
                    })
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    handleselect = (e) => {

        this.setState({ statusCME: e.target.value })

        let body = {
            "spesialisasi": "",
            "search": "",
            "status": e.target.value,
            "page": 1,
            "limit": 10,
            "urutan": "created_at DESC"
        }

        // console.log("bodyreq cme => ", body)
        httprequester.postnobody('cme/get_header_adm', body, {
            success: async function (response) {
                if (response.data.message == "success") {

                    // console.log("response cme => ", response);

                    let data = []
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            cme_id: v[GetIndexFromSchema('cme_id', response.data.result.data.schema)],
                            cme_penulis: v[GetIndexFromSchema('cme_penulis', response.data.result.data.schema)],
                            cme_title: v[GetIndexFromSchema('cme_title', response.data.result.data.schema)],
                            cme_content: v[GetIndexFromSchema('cme_content', response.data.result.data.schema)],
                            created_at: v[GetIndexFromSchema('created_at', response.data.result.data.schema)],
                            cme_slug: v[GetIndexFromSchema('cme_slug', response.data.result.data.schema)],
                            cme_penyakit: v[GetIndexFromSchema('cme_penyakit', response.data.result.data.schema)],
                            cme_skp: v[GetIndexFromSchema('cme_skp', response.data.result.data.schema)],
                            cme_molekul: v[GetIndexFromSchema('cme_molekul', response.data.result.data.schema)],
                            cme_spesialis: v[GetIndexFromSchema('cme_spesialis', response.data.result.data.schema)],
                            cme_status: v[GetIndexFromSchema('cme_status', response.data.result.data.schema)],
                            cme_pdf: v[GetIndexFromSchema('cme_pdf', response.data.result.data.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.result.data.schema)],
                            cme_thumbnail: v[GetIndexFromSchema('cme_thumbnail', response.data.result.data.schema)],
                            cme_no_idi: v[GetIndexFromSchema('cme_no_idi', response.data.result.data.schema)],
                        });
                    })

                    // console.log("dataresp cme => ", data);
                    this.setState({
                        listCME: data
                    })
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    detail = (cme_id) => {
        // console.log('ID yang akan di Edit', admin_id)
        this.props.history.push(`/cme-online/detail-pertanyaan/${cme_id}`)
    }

    directSEO = (cme_id, type) => {
        this.props.history.push(`/seoconfig/${cme_id}/${type}`)
    }

    edit = (cme_id) => {
        // console.log('ID yang akan di Edit', admin_id)
        this.props.history.push(`/cme-online/edit-cme/${cme_id}`)
    }

    directSertifikat = (cme_id) => {
        this.props.history.push(`/cme-online/sertifikat/${cme_id}`)
    }

    update = (row) => {

        // console.log("row => ", row);
        // console.log("row 1 => ", row.cme_status);
        // console.log("row 2 => ", row.cme_id);

        let formData = new FormData();
        formData.append("cme_status", row.cme_status === 'Published' ? 'Unpublished' : 'Published');
        formData.append("cme_id", row.cme_id);

        // console.log("formData => ", formData);

        // this.setState({
        //     prosess: true
        // })
        httprequester.postupload('cme/update_header', formData, {
            success: async function (response) {
                this.setState({
                    message: response.data.message,
                    alert: true
                })
                setTimeout(() => {
                    this.getListCME()
                }, 2000);


                // console.log("respon update => ", response)
                // if (response.data.message == "success") {
                //     this.setState({
                //         message: response.data.detailMessage,
                //         alert: true
                //     })

                //     this.getListCME();
                // }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    delete = (row) => {

        // console.log("row => ", row);
        // console.log("row 1 => ", row.cme_status);
        // console.log("row 2 => ", row.cme_id);

        let formData = new FormData();
        formData.append("cme_status", 'Delete');
        formData.append("cme_id", row.cme_id);

        // console.log("formData => ", formData);

        // this.setState({
        //     prosess: true
        // })
        httprequester.postupload('cme/update_header', formData, {
            success: async function (response) {


                // console.log("respon update => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        message: response.data.message,
                        confirm_both: true
                    })
                    setTimeout(() => {
                        this.getListCME();
                    }, 2000);
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    reset = () => {
        this.setState({
            hasilSpesialisasi:[]
        })
        let body = {
            "spesialisasi": '',
            "search": "",
            "status": this.state.statusCME,
            "page": 1,
            "limit": 10,
            "urutan": "cme_title ASC"
        }

        // console.log("bodyreq cme => ", body)
        httprequester.postnobody('cme/get_header_adm', body, {
            success: async function (response) {
                if (response.data.message == "success") {

                    // console.log("response cme => ", response);

                    let data = []
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            cme_id: v[GetIndexFromSchema('cme_id', response.data.result.data.schema)],
                            cme_penulis: v[GetIndexFromSchema('cme_penulis', response.data.result.data.schema)],
                            cme_title: v[GetIndexFromSchema('cme_title', response.data.result.data.schema)],
                            cme_content: v[GetIndexFromSchema('cme_content', response.data.result.data.schema)],
                            created_at: v[GetIndexFromSchema('created_at', response.data.result.data.schema)],
                            cme_slug: v[GetIndexFromSchema('cme_slug', response.data.result.data.schema)],
                            cme_penyakit: v[GetIndexFromSchema('cme_penyakit', response.data.result.data.schema)],
                            cme_skp: v[GetIndexFromSchema('cme_skp', response.data.result.data.schema)],
                            cme_molekul: v[GetIndexFromSchema('cme_molekul', response.data.result.data.schema)],
                            cme_spesialis: v[GetIndexFromSchema('cme_spesialis', response.data.result.data.schema)],
                            cme_status: v[GetIndexFromSchema('cme_status', response.data.result.data.schema)],
                            cme_pdf: v[GetIndexFromSchema('cme_pdf', response.data.result.data.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.result.data.schema)],
                            cme_thumbnail: v[GetIndexFromSchema('cme_thumbnail', response.data.result.data.schema)],
                            cme_no_idi: v[GetIndexFromSchema('cme_no_idi', response.data.result.data.schema)],
                        });
                    })

                    // console.log("dataresp cme => ", data);
                    this.setState({
                        listCME: data
                    })
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    copyurl = (value) => {
        this.setState({
            namaalert: value,
            alertcopy: true
        })
        navigator.clipboard.writeText(value)
        setTimeout(() => {
            this.setState({
                alertcopy: false
            })
        }, 3000);
    }

    getLov() {
        let body = {
            hp_type: "cme"
        }

        httprequester.postarticle('homepage/lov_content', body, {
            success: async function (response) {

                let { data } = response;
                // let { detailMessage } = response.data;
                this.setState({
                    listMolekul: data.resultMolekul,
                    listPengarang: data.resultPenulis,
                    listPenyakit: data.resultPenyakit,
                    listSpesialisasi: data.resultSpecialist
                })
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    render() {
        const option = this.state.listSpesialisasi.map(o => o.ri_lov_content_name)

        const columns = [
            // {
            //     dataField: 'cme_id',
            //     text: 'ID CME',
            //     sort: true
            // },
            {
                dataField: 'cme_title',
                text: 'Judul CME',
                sort: true
            },
            {
                dataField: 'cme_skp',
                text: 'SKP',
                sort: true
            },
            {
                dataField: 'cme_spesialis',
                text: 'Spesialisasi CME',
                sort: true
            },
            {
                dataField: 'cme_no_idi',
                text: 'No. SK PB IDI',
                sort: true
            },
            // {
            //     dataField: 'cme_spesialis',
            //     text: 'Spesialis',
            //     sort: true
            // },
            // {
            //     dataField: 'cme_pdf',
            //     text: 'PDF',
            //     sort: true
            // },
            // {
            //     dataField: 'cme_thumbnail',
            //     text: 'Foto',
            //     sort: true,
            //     formatter: (row) => {
            //         return <div>
            //             <img
            //                 src={row}
            //                 alt="cme-img"
            //                 title="cme-img"
            //                 className="avatar-md"
            //                 onClick={() => window.open(row, "_blank")}
            //             // onClick={(e) => { this.Open(row) }}
            //             />
            //         </div>
            //     }
            // },
            {
                dataField: 'cme_id',
                text: 'Action',
                sort: true,
                formatter: (cell, row) => {
                    // console.log('bener', row)
                    return <div>
                        <Button
                            color="success"
                            className="btn-sm"
                            onClick={(e) => { this.detail(row.cme_id) }}>
                            Detail
                        </Button>
                        &nbsp;
                        <Button
                            color="primary"
                            className="btn-sm"
                            onClick={(e) => { this.edit(row.cme_id) }}>
                            Edit
                        </Button>
                        &nbsp;
                        <Button
                            color="danger"
                            className="btn-sm"
                            onClick={(e) => { this.delete(row) }}>
                            Delete
                        </Button>
                        &nbsp;
                        <Button
                            color="danger"
                            className="btn-sm"
                            onClick={(e) => { this.update(row) }}>
                            {row.cme_status === 'Published' ? 'Unpublished' : 'Published'}
                        </Button>
                        &nbsp;
                        <Button
                            color="success"
                            className="btn-sm"
                            onClick={(e) => { this.copyurl(row.cme_id) }}>
                            Copy
                        </Button>
                        &nbsp;
                        <Button
                            color="success"
                            className="btn-sm"
                            onClick={(e) => { this.directSEO(row.cme_id, "cme-online") }}>
                            SEO
                        </Button>
                        &nbsp;
                        <Button
                            color="primary"
                            className="btn-sm"
                            onClick={(e) => { this.directSertifikat(row.cme_id) }}>
                            Sertifikat
                        </Button>
                        &nbsp;
                    </div>
                }
            }];

        const defaultSorted = [{
            dataField: 'cme_id',
            order: 'asc'
        }];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.listCME.length, // replace later with size(customers),
            custom: true,
        }

        // Custom Pagination Toggle
        const sizePerPageList = [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '25', value: 25 },
            { text: 'All', value: (this.state.listCME).length }];

        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    {/* <MetaTags>
                        <title>Data Tables | Skote - React Admin & Dashboard Template</title>
                    </MetaTags> */}
                    <div className="container-fluid">
                        <Breadcrumbs title="CME Online" breadcrumbItem="List CME" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <Row style={{display:'flex', justifyContent:'flex-end'}}>
                                            <Col md="2">
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilSpesialisasi: e })}
                                                    options={option}
                                                    placeholder="Pilih Spesialisasi"
                                                selected={this.state.hasilSpesialisasi}
                                                />
                                            </Col>
                                            <Col md="2">
                                                <Button color="btn-sm btn btn-info" onClick={this.cari}>
                                                        Cari
                                                </Button>
                                                <Button color="btn-sm btn btn-danger" onClick={this.reset}>
                                                    Atur Ulang
                                                </Button>
                                            </Col>
                                        </Row>
                                        <br />
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField='cme_id'
                                            columns={columns}
                                            data={this.state.listCME}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='cme_id'
                                                    columns={columns}
                                                    data={this.state.listCME}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <Input
                                                                        // id="focusAfterClose"
                                                                        onChange={this.handleselect}
                                                                        // onChange= {(e) => console.log(e.target.value) }
                                                                        type="select"
                                                                    >
                                                                        <option value="Published">
                                                                            Published
                                                                        </option>
                                                                        <option value="Unpublished">
                                                                            Unpublished
                                                                        </option>
                                                                    </Input>
                                                                </Col>
                                                                <Col md="2">
                                                                    <Link to="/cme-online/tambah-cme">
                                                                        <Button color="btn-sm btn btn-primary">
                                                                            Tambah CME
                                                                        </Button>
                                                                    </Link>
                                                                </Col>
                                                            </Row>
                                                            {this.state.alertcopy ? <div>
                                                                <Alert
                                                                    color="primary"
                                                                    isOpen={this.state.alertcopy}
                                                                >
                                                                    sucess copy ID CME {this.state.namaalert}
                                                                </Alert>
                                                            </div> : ''}
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"cme_id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )
                                                    }
                                                </ToolkitProvider>
                                            )
                                            }</PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {this.state.alert ? (
                            <SweetAlert success title={this.state.message} onConfirm={() => this.setState({ alert: false })}>
                            </SweetAlert>
                        ) : null}

                        {this.state.confirm_both ? (
                            <SweetAlert success title={this.state.message} onConfirm={() => this.setState({ confirm_both: false })}>
                            </SweetAlert>
                        ) : null}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(ListCME)